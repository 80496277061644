import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function PromptModal({type, show, handleClose, handleDelete}) {
  return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Want to delete the notification.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => handleDelete()}>Delete</Button>
            <Button variant="secondary" onClick={() => handleClose()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default PromptModal