import React from "react";
import CategorySection from "../../components/CategorySection";

const Category = () => {
  return (
    <div className="mt-3">
      <CategorySection />
    </div>
  );
};

export default Category;
