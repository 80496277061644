import React, { useState } from "react";
import { logout, setCurrentUser } from "../../redux/user/user.action";
import CustomModal from "./CustomModal";
import useZustandStore from "../../zustandStore/useZustandStore";

const SessionExpiredModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const openSessionExpiredModal = useZustandStore(
    (val) => val?.openSessionExpiredModal
  );

  const handleLogout = () => {
    logout(setCurrentUser, () => {}, setIsLoading, "");
  };

  return (
    <CustomModal
      show={openSessionExpiredModal}
      title="Session expired"
      handleConfirmationBtnText="Log In Again"
      isLoading={isLoading}
      handleConfirmation={handleLogout}
    >
      Your session has expired due to inactivity. Please log in again to
      continue.
    </CustomModal>
  );
};

export default SessionExpiredModal;
