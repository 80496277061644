import React from 'react'
import { withRouter } from 'react-router-dom';

function CreatorDashboard({match, history}) {
//   const history = useHistory();
    const createrId = match?.params?.creator_id

    return (
        <iframe
            title="Embedded Website"
            src={`https://analytics.seekho.in/public/dashboards/0nOIjNjMTgeyJERP8aTuGQKm7aT9UvqadVrUbewp?org_slug=default&p_Creator%20id=${createrId}&refresh=300&fullscreen`}
            width='100%'
            style={{ 
                height: "98vh",
            }}
            frameBorder="0"
        />
    )
}

export default withRouter(CreatorDashboard);