import React from "react";
import {
  handleDownloadThumbnail,
  handleFormatDateWithSlash,
} from "../../utils/utils";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  handleFormatMetrics,
  handleFormatPayout,
  handleFormatViews,
} from "../../utils/constants/Dashboard";
import VideoPlayOnSeriesClick from "../Common/VideoPlayOnSeriesClick";

const SeriesStats = ({ data, setMessage }) => {
  const stats = data?.stats;

  return (
    <div>
      <div className="!bg-white rounded-xl p-3">
        {/* Image, Title, Date, Views, Status & Ratings */}
        <div className="flex gap-x-3">
          <div className="relative">
            <VideoPlayOnSeriesClick
              thumbnail={data?.image}
              slug={data?.slug}
              title={data?.title}
              n_units={data?.n_units || 1}
              thumbnailClassName="rounded-md"
              thumbnailWrapperClassName="w-[127px] h-[180px] cursor-pointer"
              playIconClassName="text-5xl"
              setMessage={setMessage}
            />

            {data?.image && (
              <FontAwesomeIcon
                className="absolute cursor-pointer p-1 !w-[25px] !h-[25px] bg-black/70 shadow-md rounded hover:bg-black/90 top-1 right-1"
                icon={faDownload}
                color="#ffff"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownloadThumbnail({
                    imageUrl: data?.image,
                    title: data?.title,
                  });
                }}
              />
            )}
          </div>

          <div>
            <h6 className="text-[16px] font-medium max-w-[210px] line-clamp-3 leading-5">
              {data?.title}
            </h6>
            <div className="text-[12px] text-gray-500 flex gap-x-1 mt-2">
              {data?.approved_on ? (
                <p>
                  {handleFormatDateWithSlash({
                    dateString: data?.approved_on,
                  })}
                </p>
              ) : (
                "-"
              )}{" "}
              •{data?.duration_m ? <p>{data?.duration_m} mins</p> : " -"}
              {stats?.rating && (
                <p>
                  • <span className="!text-[#F4B513]">★ {stats?.rating}</span>
                </p>
              )}
            </div>

            {stats?.status && (
              <div
                className={` text-[13px] capitalize rounded-full w-fit px-2 py-1 mt-3 ${handleSeriesStatusColor(
                  { status: stats?.status }
                )} `}
              >
                {stats?.status}
              </div>
            )}
          </div>
        </div>

        {/* Views, WatchTime & Completion rate */}
        <div className="text-[12px] mt-3">
          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Views</p>
            {stats?.n_views ? (
              <p>{handleFormatViews({ views: stats?.n_views })}</p>
            ) : (
              "-"
            )}
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Watch Time</p>
            {stats?.watch_hours ? (
              <p>{`${handleFormatMetrics({
                num: stats?.watch_hours || 0,
                isGreaterThen10K: true,
              })} hours`}</p>
            ) : (
              "-"
            )}
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Completion Rate</p>
            <p>
              {stats?.completion_rate ? (
                <span className="font-semibold">{stats?.completion_rate}%</span>
              ) : (
                ""
              )}
              <span className="text-gray-500">
                {stats?.target_completion_rate
                  ? `(${stats?.target_completion_rate}%)`
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <hr className="mt-3" />

        {/* Fixed, Variable & TotalPayout  */}
        <div className="text-[12px] mt-3">
          {/* <div className="flex justify-between mt-[2px] text-gray-500">
          <p className="font-medium">Fixed</p>
          {stats?.fixed ? <p>₹{stats?.fixed}</p> : "-"}
        </div>

        <div className="flex justify-between mt-[2px] text-gray-500">
          <p className="font-medium">Variable</p>
          {stats?.variables ? <p>₹{stats?.variables}</p> : "-"}
        </div> */}

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Total Payout</p>
            {stats?.payout ? (
              <p className="text-[16px] font-medium">{`₹${handleFormatPayout({
                totalPayout: stats?.payout || 0,
              })}`}</p>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesStats;
