const feedbackStatsDefaultState = {
  feedbacks: [],
  series: {},
  nPages: 0,
  seriesPerformanceList: [],
};

const feedbackStatsLoadingState = {
  feedbacks: true,
  series: true,
};

export { feedbackStatsDefaultState, feedbackStatsLoadingState };
