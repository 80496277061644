import React from "react";
import {
  handleDefaultProfile,
  handleFormatDateTimeAgo,
} from "../../utils/utils";

const FeedbackWithProfile = ({
  index,
  review,
  avatar,
  userName,
  updatedOn,
  expandedFeedback,
  handleToggleReadMore,
}) => {
  return (
    <div>
      <div className="flex gap-x-3">
        <img
          src={
            avatar ||
            handleDefaultProfile({
              userName: userName || "Seekho User",
            })
          }
          alt={userName}
          className="rounded-full w-[36px] h-[36px]"
        />
        <div className="flex flex-col">
          <div className="text-[12px] text-gray-400">
            <p className="break-words">
              {userName || "Seekho User"} •{" "}
              <span>
                {handleFormatDateTimeAgo({
                  date: updatedOn,
                })}
              </span>
            </p>
          </div>
          <p className="text-[14px] mt-1 break-words text-[#333333] leading-[18px] tracking-wide break-all overflow-hidden max-h-full">
            {expandedFeedback[index] ? review : review?.slice(0, 300)}{" "}
            {/* Display the "Read More" or "Read Less" button */}
            {review?.length > 300 && (
              <button
                onClick={handleToggleReadMore}
                className="text-baseBlueColor !cursor-pointer font-semibold !text-[11px]"
              >
                {expandedFeedback[index] ? "...Read Less" : "...Read More"}
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackWithProfile;
