import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import Loader from "../Loader";
import "./update-video.styles.css";
import { useDispatch } from "react-redux";
import { updateVideo } from "../../redux/video/video.action";
import { apiGateway } from "../../utils/config";
import { Button, CircularProgress } from "@mui/material";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import VideoPlayerCustom from "../VideoPlayer/VideoPlayerCustom";
import { UNIT_V1 } from "../../utils/constants";
// import VideoPlayer from "../VideoPlayer";

export default function UpdateVideo({ videoSlug, setShowUpdate, reload }) {
  let url = `${apiGateway}${UNIT_V1}${videoSlug}/edit-unit/`;
  const [video, setVideo] = useState(null);
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [categories, setCategories] = useState(null);
  const [creator, setCreator] = useState(null);
  const [isFrameLoading, setIsFrameLoading] = useState(false);
  const [icon, setIcon] = useState(null);
  const [isExtracting, setIsExtracting] = useState(false);
  const [showVideoFrame, setShowVideoFrame] = useState(false);
  const dispatch = useDispatch();
  const videoRef =  useRef();
  const axiosPrivate = useAxiosPrivate();

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const creatorId = creator?.id;
    const data = new FormData();
    data.append("title", title);
    data.append("category_id", categoryId);
    data.append("creator_id", creatorId);
    if (icon) data.append("icon", icon);
      axiosPrivate.post(url, data)
      .then(({data, status}) => {
        if(status === 200) {
          setErrorMsg({
            type: "success",
            error: "Video Updated successfully",
          });
          dispatch(updateVideo(data));
          reload();
        }
        setIsUpdating(false);
        setShowUpdate(false);
      }).catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"})
      })
  };

  useEffect(() => {
    setVideoDetails();
  }, []);

  const setVideoDetails = async () => {
    axiosPrivate.get(url)
      .then(({data, status}) => {
        if(status === 200){
          setVideo(data?.content_unit);
          setTitle(data?.content_unit?.title);
          setCategoryId(data?.content_unit?.category?.id);
          setCategories(data?.categories);
          setCreator(data?.content_unit?.creator);
        }
      })
      .catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Oops! Failed to save video", type: "failed"})
      })
  };

  const onCaptureFrame = () => {
    try {
    const video = videoRef.current;
    const canvas = document?.createElement('canvas');
    const context = canvas.getContext('2d');
    if(video){
      canvas.width = video.videoWidth ? video.videoWidth : 466;
      canvas.height = video.videoHeight ? video.videoWidth : 828;
      video.currentTime = video.currentTime; // Specify the desired time in seconds
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
    }

    const frameDataUrl = canvas.toDataURL('image/jpeg');

    const parts = frameDataUrl.match(/data:([^;]+);base64,(.*)/);
    if (!parts || parts.length !== 3) {
      setErrorMsg({ error: 'Image cannot be generated invalid DataURl', type: "failed" });
      setIsExtracting(false);
    } else {
      const mimeType = parts[1];
      const base64Data = parts[2];
      const binaryData = atob(base64Data);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: mimeType });
      const imageFile = new File([blob], 'image.jpg', { type: mimeType });
      // Now you can use `imageFile` as needed
      if(imageFile){
        setShowVideoFrame(false);
        setIcon(imageFile);
        setIsExtracting(false);
      } else{
        setErrorMsg({ error: 'Image cannot be generated', type: "failed" });
        setIsExtracting(false);
      }
    }
    } catch (error) {
      setErrorMsg({ error: 'Image broke down!', type: "failed" });
      setIsExtracting(false);
    }
    setIsFrameLoading(false);
  };

  return (
    <div>
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />
      {video && categories && !isUpdating ? (
        <Container>
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the title of video"
                required
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Video Thumbnail</Form.Label>
              <div className="left-button-flex">
                <Form.Control
                  style={{height: 30, width: 100}}
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={(e) => {
                    setIcon(e.target.files[0]);
                  }}
                />
                <span className="ml-2 mr-2">Or</span>
                <Button onClick={() => {
                  setShowVideoFrame(true)
                }}>Video frame</Button>
              </div>
            </Form.Group>
            <div className="center">
              {isFrameLoading ? <CircularProgress /> : icon && <img
                src={URL.createObjectURL(icon)}
                width="100px"
                height="100px"
                style={{ border: "1px solid lightgrey" }}
                alt="thumbnail"
              />}
            </div>
            <div className="center mb-2">
              <Button
                type="submit"
                variant="outline-primary"
                // active
                onClick={(e) => !isUpdating && handleUpdate(e)}
                className="cta-update-series"
              >
                {!isUpdating ? 'Update' : 'Updating...'}
              </Button>
            </div>
          </Form>
        </Container>
      ) : (
        <div className="center">
          <Loader />
        </div>
      )}
      <Modal show={showVideoFrame} centered>
          <Modal.Header closeButton onHide={() => setShowVideoFrame(false)}>
            <div className="modal-video-title">Extract Frame</div>
          </Modal.Header>
          <Modal.Body className="modal-body-frame-picker">
            <div className="modal-body-frame-center">
                <VideoPlayerCustom
                  src={
                    video?.status?.toLowerCase() === "live"
                    ? video.content.h264_media_url
                      ? video.content.h264_media_url
                      : video.content.url
                    : ''
                  }
                  videoRef={videoRef}
                  ref={videoRef}
                  muted={false}
                  controls={true}
                  autoplay={true}
                />
                <Button className="cta-update-series mt-2" onClick={() => onCaptureFrame()}>{isExtracting ? "Loading.." : "Extract Thumbnail"}</Button>
              </div>
          </Modal.Body>
      </Modal>
    </div>
  );
}
