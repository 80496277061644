import userReducer from "./user/user.reducer";
import VideoReducer from "./video/video.reducer";
import CreatorReducer from "./creator/creator.reducer";
import SeriesReducer from "./series/series.reducer";
import ConfigReducer from "./config/config.reducer";

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CommonDataReducer from "./common/common.reducer";
import ExperimentReducer from "./experiments/experiments.reducer"
import seriesNotificationReducer from "./seriesDetails/seriesDetails.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "notification", "config", "common"],
};

const rootReducer = combineReducers({
  user: userReducer,
  video: VideoReducer,
  series: SeriesReducer,
  creator: CreatorReducer,
  config: ConfigReducer,
  common: CommonDataReducer,
  experiments: ExperimentReducer,
  notification : seriesNotificationReducer,
});

export default persistReducer(persistConfig, rootReducer);
