import CreatorActionTypes from "./creator.types";

const INITIAL_STATE = {
  creators: [],
  currentPageNumber: 1,
  lastPageFetched: 0,
  filters: new Map([
    ["categoryFilter", []],
    ["contentManagerFilter", []],
  ]),
  searchedName: "",
  hasMore: true,
  isFetching: false,
  isDeleting: false,
  errorMessage: undefined,
  sortKey: "",
  sortReverse: "",

};

const creatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreatorActionTypes.CREATOR_INITIALIZE:
      return INITIAL_STATE;
    case CreatorActionTypes.FETCH_CREATOR_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreatorActionTypes.FETCH_CREATOR_SUCCESS: {
      const pageNumber = action.payload.pageNumber;
      const fetchedCreators = [...action.payload.creatorsFetched];
      return {
        ...state,
        creators: [...fetchedCreators],
        hasMore: action.payload.hasMore,
        pageData: action.payload.table_data,
        lastPageFetched: pageNumber,
        currentPageNumber: pageNumber,
        searchedName: action.payload.searchedName,
        sortKey: action.payload.sortKey,
        sortReverse: action.payload.sortReverse,
        isFetching: false,
        errorMessage: undefined,

      };
    }
    case CreatorActionTypes.SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };
    case CreatorActionTypes.FETCH_CREATOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CreatorActionTypes.CHANGE_QUALITY_APPROVED_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreatorActionTypes.CHANGE_QUALITY_APPROVED_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case CreatorActionTypes.CHANGE_QUALITY_APPROVED_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CreatorActionTypes.CHANGE_PROMOTION_SCORE_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreatorActionTypes.CHANGE_PROMOTION_SCORE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case CreatorActionTypes.CHANGE_PROMOTION_SCORE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default creatorReducer;
