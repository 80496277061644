import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { axiosPrivate } from "../api/axiosCustom";
import Cookies from 'js-cookie';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            async config => {
                let access_token_expiry = parseInt(Cookies.get('access_token_expiry_seekho'));
                let renew_access_token_before = parseInt(Cookies.get('renew_access_token_before_seekho'));
                let newEpochTime =  parseInt(new Date().getTime() / 1000);
                if(process?.env?.NODE_ENV === 'development'){
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `jwt ${Cookies.get('access_token')}`;
                    }
                }else{
                    config.withCredentials = true;
                }
                if(access_token_expiry - newEpochTime < renew_access_token_before){
                    await refresh();
                    return {
                        ...config,
                    };
                }else{
                    return config;
                }
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    await refresh();
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;