import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./update-content-manager.styles.css";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { GET_CONFIG, UPDATE_MANAGER_V1 } from "../../utils/constants";

export default function UpdateContentManager({ creator, close, setErrorMsg }) {
  const [contentManagers, setContentManagers] = useState([
    { id: null, name: "None" },
  ]);
  const [currentContentManager, setCurrentContentManager] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchContentMangers();
  }, []);

  const fetchContentMangers = async () => {
    const contentManager = creator?.content_manager?.name
      ? creator?.content_manager
      : contentManagers[0];
    setCurrentContentManager(contentManager);

    const url = `${apiGateway}${GET_CONFIG}`;
    axiosPrivate.get(url)
    .then(({data, status}) => {
      if(status === 200)
        setContentManagers((prev) => [...prev, ...data.content_manager]);
    })
    .catch(({response}) => {
      setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"});
    })

  }

  const changeContentManager = async () => {
    const url = `${apiGateway}${UPDATE_MANAGER_V1}`;
    const body = {
      content_manager_id: currentContentManager,
      creator_id: creator.id,
    };
    axiosPrivate.post(url, body)
    .then(({data, status}) => {
      if(status === 200)
        setErrorMsg({error: "Assigned successfully", type: "success"});
        close();
    })
    .catch(({response}) => {
      setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"});
      close();
    })
  };

  return (
    <div className="content-manager-modal">
      <div>
        <p className="ptag-modal-header">Change Content Manager</p>
        <select
          className="managers"
          onChange={(e) => setCurrentContentManager(e.target.value)}
        >
          {currentContentManager &&
            contentManagers?.map((contentManager) => {
              return (
                <option
                  key={contentManager.id}
                  selected={contentManager.id === currentContentManager.id}
                  value={contentManager.id}
                >
                  {contentManager.name}
                </option>
              );
            })}
        </select>
      </div>
      <div className="content-manager-modal-btn">
        <Button className="close-btn" onClick={close}>
          Close
        </Button>
        <Button className="change-btn" onClick={changeContentManager}>
          Change
        </Button>
      </div>
      <div></div>
    </div>
  );
}
