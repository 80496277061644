import React from "react";
import {
  handleFormatMetrics,
  handleFormatPayout,
} from "../../utils/constants/Dashboard";

const Metrics = ({ data }) => {
  const formatData = [
    {
      id: 1,
      title: "Total Payout",
      value: `₹${handleFormatPayout({
        totalPayout: data?.total_payout || 0,
      })}`,
    },
    {
      id: 2,
      title: "Total Videos",
      value: handleFormatMetrics({ num: data?.n_series || 0 }),
    },
    {
      id: 3,
      title: "Total Duration",
      value: `${handleFormatMetrics({
        num: data?.total_duration_m || 0,
        isGreaterThen10K: true,
      })} mins`,
    },
    {
      id: 4,
      title: "Watch Time",
      value: `${handleFormatMetrics({
        num: data?.n_watch_hrs || 0,
        isGreaterThen10K: true,
      })} hours`,
    },
  ];

  return (
    <div className="flex gap-x-2 !flex-wrap gap-y-2 lg:gap-x-5 lg:!flex-nowrap">
      {formatData?.map((item) => {
        return (
          <div
            key={item?.id}
            className="!w-[30%] !px-2 !py-2 !border !border-[#E7E7E7] rounded-md !bg-white shadow-sm lg:!w-[25%] lg:!px-7 lg:!py-6 lg:rounded-xl"
          >
            <h6 className="text-[10px] font-normal text-gray-500 lg:!text-[16px]">
              {item?.title}
            </h6>
            <h6 className="text-[12px] font-semibold text-[#474747] lg:!text-[24px] lg:mt-1">
              {item?.value}
            </h6>
          </div>
        );
      })}
    </div>
  );
};

export default Metrics;
