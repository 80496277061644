const categoryTabs = [
  {
    id: "ALL",
    key: "SHOWS",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "ACTIVE",
    key: "ACTIVE",
    value: "active",
    index: 1,
    show: true,
  },
  {
    id: "INACTIVE",
    key: "INACTIVE",
    value: "in active",
    index: 2,
    show: true,
  },
];

const createCatDefaultState = {
  title: "",
  metaTitle: "",
  description: "",
  image: null,
  slug: "", // only used while edit
  websiteCategoryImage: null,
};

// function which checks the missing fields while creating category
const handleGetMissingFields = ({ categoryDetails }) => {
  const requiredFields = [
    { key: "title", value: "Title" },
    { key: "metaTitle", value: "Meta title" },
    { key: "image", value: "Image" },
    { key: "description", value: "Description" },
  ];
  return requiredFields.filter((field) => !categoryDetails?.[field.key]);
};

export { categoryTabs, createCatDefaultState, handleGetMissingFields };
