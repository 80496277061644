import React from "react";

const AppStoreLogo = ({ className }) => {
  return (
    <div>
      <svg
        width="180"
        height="52"
        viewBox="0 0 180 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M179 46.003C179 48.7742 176.709 51.0189 173.874 51.0189H6.13243C3.2995 51.0189 1 48.7742 1 46.003V6.0035C1 3.23367 3.2995 0.981133 6.13243 0.981133H173.873C176.709 0.981133 178.999 3.23367 178.999 6.0035L179 46.003Z"
          fill="black"
        />
        <path
          d="M173.333 1.04162C176.42 1.04162 178.932 3.4905 178.932 6.5V45.5C178.932 48.5095 176.42 50.9584 173.333 50.9584H6.66667C3.58 50.9584 1.06833 48.5095 1.06833 45.5V6.5C1.06833 3.4905 3.58 1.04162 6.66667 1.04162H173.333ZM173.333 2.74817e-06H6.66667C3.00167 2.74817e-06 0 2.92663 0 6.5V45.5C0 49.0734 3.00167 52 6.66667 52H173.333C176.998 52 180 49.0734 180 45.5V6.5C180 2.92663 176.998 2.74817e-06 173.333 2.74817e-06Z"
          fill="#A6A6A6"
        />
        <path
          d="M40.1707 25.7192C40.132 21.5293 43.6893 19.4909 43.852 19.396C41.8373 16.5321 38.7147 16.1408 37.6173 16.1096C34.9947 15.8405 32.4507 17.6397 31.1147 17.6397C29.752 17.6397 27.6947 16.1356 25.4773 16.1798C22.624 16.2227 19.9547 17.8334 18.4907 20.3346C15.4693 25.4345 17.7227 32.929 20.6173 37.0513C22.0653 39.0702 23.7573 41.3244 25.972 41.2451C28.1387 41.158 28.948 39.8983 31.5627 39.8983C34.1533 39.8983 34.9133 41.2451 37.172 41.1944C39.4973 41.158 40.9613 39.1664 42.3587 37.1293C44.032 34.8153 44.704 32.5364 44.7307 32.4194C44.676 32.4012 40.2147 30.7411 40.1707 25.7192Z"
          fill="white"
        />
        <path
          d="M35.904 13.3978C37.0693 11.9769 37.8667 10.0438 37.6453 8.08208C35.9587 8.15488 33.8493 9.21958 32.6347 10.6093C31.56 11.8339 30.6 13.8411 30.848 15.7287C32.7427 15.8665 34.688 14.7966 35.904 13.3978Z"
          fill="white"
        />
        <path
          d="M71.5267 40.9552H68.4987L66.84 35.8735H61.0747L59.4947 40.9552H56.5467L62.2587 23.6548H65.7867L71.5267 40.9552ZM66.34 33.7415L64.84 29.224C64.6813 28.7625 64.384 27.6757 63.9453 25.9649H63.892C63.7173 26.7007 63.436 27.7875 63.0493 29.224L61.576 33.7415H66.34Z"
          fill="white"
        />
        <path
          d="M86.216 34.5644C86.216 36.686 85.628 38.363 84.452 39.5941C83.3987 40.69 82.0907 41.2373 80.5293 41.2373C78.844 41.2373 77.6333 40.6471 76.896 39.4667H76.8427V46.0382H74V32.5871C74 31.2533 73.964 29.8844 73.8947 28.4804H76.3947L76.5533 30.4577H76.6067C77.5547 28.9679 78.9933 28.2243 80.924 28.2243C82.4333 28.2243 83.6933 28.8054 84.7013 29.9689C85.712 31.1337 86.216 32.6651 86.216 34.5644ZM83.32 34.6658C83.32 33.4516 83.04 32.4506 82.4773 31.6628C81.8627 30.8412 81.0373 30.4304 80.0027 30.4304C79.3013 30.4304 78.664 30.6592 78.0947 31.1103C77.524 31.5653 77.1506 32.1594 76.976 32.8952C76.888 33.2384 76.844 33.5192 76.844 33.7402V35.8202C76.844 36.7276 77.1293 37.4933 77.7 38.1186C78.2707 38.7439 79.012 39.0559 79.924 39.0559C80.9947 39.0559 81.828 38.6529 82.424 37.8495C83.0213 37.0448 83.32 35.984 83.32 34.6658Z"
          fill="white"
        />
        <path
          d="M100.932 34.5644C100.932 36.686 100.344 38.363 99.1667 39.5941C98.1147 40.69 96.8067 41.2373 95.2453 41.2373C93.56 41.2373 92.3493 40.6471 91.6133 39.4667H91.56V46.0382H88.7173V32.5871C88.7173 31.2533 88.6813 29.8844 88.612 28.4804H91.112L91.2707 30.4577H91.324C92.2707 28.9679 93.7093 28.2243 95.6413 28.2243C97.1493 28.2243 98.4093 28.8054 99.42 29.9689C100.427 31.1337 100.932 32.6651 100.932 34.5644ZM98.036 34.6658C98.036 33.4516 97.7547 32.4506 97.192 31.6628C96.5773 30.8412 95.7547 30.4304 94.7187 30.4304C94.016 30.4304 93.38 30.6592 92.8093 31.1103C92.2387 31.5653 91.8667 32.1594 91.692 32.8952C91.6053 33.2384 91.56 33.5192 91.56 33.7402V35.8202C91.56 36.7276 91.8453 37.4933 92.4133 38.1186C92.984 38.7426 93.7253 39.0559 94.64 39.0559C95.7107 39.0559 96.544 38.6529 97.14 37.8495C97.7373 37.0448 98.036 35.984 98.036 34.6658Z"
          fill="white"
        />
        <path
          d="M117.385 36.1036C117.385 37.5752 116.861 38.7725 115.809 39.6968C114.653 40.7069 113.044 41.2113 110.976 41.2113C109.067 41.2113 107.536 40.8525 106.377 40.1336L107.036 37.8235C108.284 38.5593 109.653 38.9285 111.145 38.9285C112.216 38.9285 113.049 38.6919 113.648 38.2213C114.244 37.7507 114.541 37.1189 114.541 36.3311C114.541 35.6291 114.296 35.0376 113.804 34.5579C113.315 34.0782 112.497 33.6323 111.356 33.2202C108.249 32.0905 106.697 30.4356 106.697 28.2594C106.697 26.8372 107.241 25.6711 108.331 24.7637C109.416 23.855 110.864 23.4013 112.675 23.4013C114.289 23.4013 115.631 23.6756 116.701 24.2229L115.991 26.4823C114.991 25.9519 113.86 25.6867 112.595 25.6867C111.595 25.6867 110.813 25.9272 110.253 26.4056C109.78 26.8333 109.543 27.3546 109.543 27.9721C109.543 28.6559 109.813 29.2214 110.357 29.666C110.831 30.0768 111.691 30.5214 112.939 31.0011C114.465 31.6004 115.587 32.3011 116.308 33.1045C117.027 33.9053 117.385 34.9076 117.385 36.1036Z"
          fill="white"
        />
        <path
          d="M126.784 30.5604H123.651V36.6171C123.651 38.1576 124.203 38.9272 125.309 38.9272C125.817 38.9272 126.239 38.8843 126.572 38.7985L126.651 40.9032C126.091 41.1073 125.353 41.21 124.44 41.21C123.317 41.21 122.44 40.8759 121.807 40.209C121.176 39.5408 120.859 38.4202 120.859 36.8459V30.5578H118.992V28.4778H120.859V26.1937L123.651 25.3721V28.4778H126.784V30.5604Z"
          fill="white"
        />
        <path
          d="M140.921 34.6151C140.921 36.5326 140.359 38.1069 139.236 39.338C138.059 40.6055 136.496 41.2373 134.548 41.2373C132.671 41.2373 131.176 40.6302 130.061 39.416C128.947 38.2018 128.389 36.6691 128.389 34.8218C128.389 32.8887 128.963 31.3053 130.113 30.0742C131.261 28.8418 132.811 28.2256 134.759 28.2256C136.636 28.2256 138.147 28.8327 139.287 30.0482C140.377 31.2273 140.921 32.7496 140.921 34.6151ZM137.972 34.7048C137.972 33.5543 137.72 32.5676 137.209 31.7447C136.613 30.7489 135.761 30.2523 134.657 30.2523C133.515 30.2523 132.647 30.7502 132.051 31.7447C131.54 32.5689 131.288 33.5712 131.288 34.7568C131.288 35.9073 131.54 36.894 132.051 37.7156C132.665 38.7114 133.524 39.208 134.632 39.208C135.717 39.208 136.569 38.701 137.184 37.6896C137.708 36.8511 137.972 35.854 137.972 34.7048Z"
          fill="white"
        />
        <path
          d="M150.161 30.9179C149.88 30.8672 149.58 30.8412 149.265 30.8412C148.265 30.8412 147.492 31.2091 146.948 31.9462C146.475 32.5962 146.237 33.4178 146.237 34.4097V40.9552H143.396L143.423 32.409C143.423 30.9712 143.387 29.6621 143.316 28.4817H145.792L145.896 30.8685H145.975C146.275 30.0482 146.748 29.3878 147.396 28.8925C148.029 28.4466 148.713 28.2243 149.451 28.2243C149.713 28.2243 149.951 28.2425 150.161 28.275V30.9179Z"
          fill="white"
        />
        <path
          d="M162.875 34.1276C162.875 34.6242 162.841 35.0428 162.771 35.3847H154.243C154.276 36.6171 154.688 37.5596 155.48 38.2096C156.199 38.7907 157.128 39.0819 158.269 39.0819C159.532 39.0819 160.684 38.8856 161.72 38.4917L162.165 40.4157C160.955 40.9305 159.525 41.1866 157.876 41.1866C155.892 41.1866 154.335 40.6172 153.201 39.4797C152.071 38.3422 151.504 36.8147 151.504 34.8985C151.504 33.0174 152.031 31.4509 153.085 30.2016C154.189 28.8678 155.681 28.2009 157.559 28.2009C159.403 28.2009 160.799 28.8678 161.747 30.2016C162.497 31.2611 162.875 32.5715 162.875 34.1276ZM160.164 33.4087C160.183 32.5871 159.997 31.8773 159.612 31.278C159.12 30.5071 158.364 30.1223 157.347 30.1223C156.417 30.1223 155.661 30.498 155.084 31.252C154.611 31.8513 154.329 32.5702 154.243 33.4074H160.164V33.4087Z"
          fill="white"
        />
        <path
          d="M65.4 13.0117C65.4 14.5418 64.9293 15.6936 63.9893 16.4671C63.1187 17.1808 61.8813 17.5383 60.2787 17.5383C59.484 17.5383 58.804 17.5045 58.2347 17.4369V9.07661C58.9773 8.95961 59.7773 8.89981 60.6413 8.89981C62.168 8.89981 63.3187 9.22351 64.0947 9.87091C64.964 10.6028 65.4 11.6493 65.4 13.0117ZM63.9267 13.0494C63.9267 12.0575 63.6573 11.297 63.1187 10.7666C62.58 10.2375 61.7933 9.97231 60.7573 9.97231C60.3173 9.97231 59.9427 10.0009 59.632 10.0607V16.4164C59.804 16.4424 60.1187 16.4541 60.576 16.4541C61.6453 16.4541 62.4707 16.1642 63.052 15.5844C63.6333 15.0046 63.9267 14.1596 63.9267 13.0494Z"
          fill="white"
        />
        <path
          d="M73.212 14.3481C73.212 15.2906 72.936 16.0628 72.384 16.6686C71.8053 17.2913 71.0387 17.602 70.0813 17.602C69.1587 17.602 68.424 17.3043 67.876 16.7063C67.3293 16.1096 67.056 15.3569 67.056 14.4495C67.056 13.5005 67.3373 12.7218 67.9027 12.1173C68.468 11.5128 69.228 11.2099 70.1853 11.2099C71.108 11.2099 71.8493 11.5076 72.4107 12.1043C72.944 12.6841 73.212 13.4329 73.212 14.3481ZM71.7627 14.3923C71.7627 13.8268 71.6373 13.3419 71.388 12.9376C71.0947 12.4488 70.6773 12.2044 70.1347 12.2044C69.5733 12.2044 69.1467 12.4488 68.8533 12.9376C68.6027 13.3419 68.4787 13.8346 68.4787 14.417C68.4787 14.9825 68.604 15.4674 68.8533 15.8717C69.156 16.3605 69.5773 16.6049 70.1213 16.6049C70.6547 16.6049 71.0733 16.3566 71.3747 15.8587C71.6333 15.4466 71.7627 14.9578 71.7627 14.3923Z"
          fill="white"
        />
        <path
          d="M83.6867 11.3347L81.72 17.4629H80.44L79.6253 14.8018C79.4187 14.1375 79.2507 13.4771 79.12 12.8219H79.0947C78.9733 13.4953 78.8053 14.1544 78.5893 14.8018L77.724 17.4629H76.4293L74.58 11.3347H76.016L76.7267 14.248C76.8987 14.937 77.04 15.5935 77.1533 16.2149H77.1787C77.2827 15.7027 77.4547 15.0501 77.6973 14.261L78.5893 11.336H79.728L80.5827 14.1986C80.7893 14.8967 80.9573 15.5688 81.0867 16.2162H81.1253C81.22 15.5857 81.3627 14.9136 81.552 14.1986L82.3147 11.336H83.6867V11.3347Z"
          fill="white"
        />
        <path
          d="M90.9307 17.4629H89.5333V13.9529C89.5333 12.8713 89.112 12.3305 88.2667 12.3305C87.852 12.3305 87.5173 12.4787 87.2573 12.7764C87 13.0741 86.8693 13.4251 86.8693 13.8268V17.4616H85.472V13.0858C85.472 12.5476 85.4547 11.9639 85.4213 11.3321H86.6493L86.7147 12.2902H86.7533C86.916 11.9925 87.1587 11.7468 87.4773 11.5505C87.856 11.3217 88.28 11.206 88.744 11.206C89.3307 11.206 89.8187 11.3906 90.2067 11.7611C90.6893 12.2148 90.9307 12.8921 90.9307 13.7917V17.4629Z"
          fill="white"
        />
        <path d="M94.784 17.4629H93.388V8.5228H94.784V17.4629Z" fill="white" />
        <path
          d="M103.011 14.3481C103.011 15.2906 102.735 16.0628 102.183 16.6686C101.604 17.2913 100.836 17.602 99.88 17.602C98.956 17.602 98.2213 17.3043 97.6747 16.7063C97.128 16.1096 96.8547 15.3569 96.8547 14.4495C96.8547 13.5005 97.136 12.7218 97.7013 12.1173C98.2667 11.5128 99.0267 11.2099 99.9827 11.2099C100.907 11.2099 101.647 11.5076 102.209 12.1043C102.743 12.6841 103.011 13.4329 103.011 14.3481ZM101.56 14.3923C101.56 13.8268 101.435 13.3419 101.185 12.9376C100.893 12.4488 100.475 12.2044 99.9333 12.2044C99.3707 12.2044 98.944 12.4488 98.652 12.9376C98.4013 13.3419 98.2773 13.8346 98.2773 14.417C98.2773 14.9825 98.4027 15.4674 98.652 15.8717C98.9547 16.3605 99.376 16.6049 99.92 16.6049C100.453 16.6049 100.871 16.3566 101.172 15.8587C101.432 15.4466 101.56 14.9578 101.56 14.3923Z"
          fill="white"
        />
        <path
          d="M109.773 17.4629H108.519L108.415 16.757H108.376C107.947 17.3199 107.335 17.602 106.54 17.602C105.947 17.602 105.467 17.4161 105.105 17.0469C104.777 16.7115 104.613 16.2942 104.613 15.7989C104.613 15.0501 104.933 14.4794 105.577 14.0842C106.22 13.689 107.124 13.4953 108.288 13.5044V13.39C108.288 12.5827 107.853 12.1797 106.983 12.1797C106.363 12.1797 105.816 12.3318 105.344 12.6334L105.06 11.739C105.644 11.3867 106.365 11.2099 107.216 11.2099C108.859 11.2099 109.683 12.0549 109.683 13.7449V16.0017C109.683 16.614 109.713 17.1015 109.773 17.4629ZM108.323 15.3569V14.4118C106.781 14.3858 106.011 14.7979 106.011 15.6468C106.011 15.9666 106.099 16.2058 106.279 16.3657C106.459 16.5256 106.688 16.6049 106.961 16.6049C107.268 16.6049 107.555 16.51 107.816 16.3215C108.079 16.1317 108.24 15.8912 108.3 15.5961C108.315 15.5298 108.323 15.4492 108.323 15.3569Z"
          fill="white"
        />
        <path
          d="M117.713 17.4629H116.473L116.408 16.4788H116.369C115.973 17.2276 115.299 17.602 114.351 17.602C113.593 17.602 112.963 17.3121 112.463 16.7323C111.963 16.1525 111.713 15.3998 111.713 14.4755C111.713 13.4836 111.984 12.6802 112.528 12.0666C113.055 11.4946 113.7 11.2086 114.468 11.2086C115.312 11.2086 115.903 11.4855 116.239 12.0406H116.265V8.5228H117.664V15.8119C117.664 16.4086 117.68 16.9585 117.713 17.4629ZM116.265 14.8785V13.8567C116.265 13.6799 116.252 13.5369 116.227 13.4277C116.148 13.1001 115.979 12.8245 115.721 12.6022C115.461 12.3799 115.148 12.2681 114.787 12.2681C114.265 12.2681 113.857 12.4696 113.557 12.8739C113.26 13.2782 113.109 13.7943 113.109 14.4248C113.109 15.0306 113.252 15.522 113.539 15.9003C113.841 16.3033 114.249 16.5048 114.76 16.5048C115.219 16.5048 115.585 16.3371 115.864 16.0004C116.133 15.6897 116.265 15.3153 116.265 14.8785Z"
          fill="white"
        />
        <path
          d="M129.664 14.3481C129.664 15.2906 129.388 16.0628 128.836 16.6686C128.257 17.2913 127.492 17.602 126.533 17.602C125.612 17.602 124.877 17.3043 124.328 16.7063C123.781 16.1096 123.508 15.3569 123.508 14.4495C123.508 13.5005 123.789 12.7218 124.355 12.1173C124.92 11.5128 125.68 11.2099 126.639 11.2099C127.56 11.2099 128.303 11.5076 128.863 12.1043C129.396 12.6841 129.664 13.4329 129.664 14.3481ZM128.216 14.3923C128.216 13.8268 128.091 13.3419 127.841 12.9376C127.547 12.4488 127.131 12.2044 126.587 12.2044C126.027 12.2044 125.6 12.4488 125.305 12.9376C125.055 13.3419 124.931 13.8346 124.931 14.417C124.931 14.9825 125.056 15.4674 125.305 15.8717C125.608 16.3605 126.029 16.6049 126.573 16.6049C127.107 16.6049 127.527 16.3566 127.828 15.8587C128.085 15.4466 128.216 14.9578 128.216 14.3923Z"
          fill="white"
        />
        <path
          d="M137.177 17.4629H135.781V13.9529C135.781 12.8713 135.36 12.3305 134.513 12.3305C134.099 12.3305 133.764 12.4787 133.505 12.7764C133.247 13.0741 133.117 13.4251 133.117 13.8268V17.4616H131.719V13.0858C131.719 12.5476 131.703 11.9639 131.669 11.3321H132.896L132.961 12.2902H133C133.164 11.9925 133.407 11.7468 133.724 11.5505C134.104 11.3217 134.527 11.206 134.992 11.206C135.577 11.206 136.065 11.3906 136.453 11.7611C136.937 12.2148 137.177 12.8921 137.177 13.7917V17.4629Z"
          fill="white"
        />
        <path
          d="M146.581 12.3552H145.043V15.3322C145.043 16.0888 145.316 16.4671 145.857 16.4671C146.108 16.4671 146.316 16.4463 146.48 16.4034L146.516 17.4369C146.24 17.5383 145.877 17.589 145.431 17.589C144.879 17.589 144.449 17.4252 144.139 17.0976C143.827 16.77 143.672 16.2188 143.672 15.4453V12.3552H142.753V11.3347H143.672V10.2115L145.041 9.80851V11.3334H146.58V12.3552H146.581Z"
          fill="white"
        />
        <path
          d="M153.979 17.4629H152.58V13.9789C152.58 12.8804 152.159 12.3305 151.315 12.3305C150.667 12.3305 150.224 12.649 149.981 13.286C149.94 13.4199 149.916 13.5837 149.916 13.7761V17.4616H148.52V8.5228H149.916V12.2161H149.943C150.383 11.544 151.013 11.2086 151.831 11.2086C152.409 11.2086 152.888 11.3932 153.268 11.7637C153.741 12.2252 153.979 12.9116 153.979 13.819V17.4629Z"
          fill="white"
        />
        <path
          d="M161.609 14.1089C161.609 14.3533 161.591 14.5587 161.557 14.7264H157.367C157.385 15.3322 157.585 15.7937 157.973 16.1135C158.328 16.3995 158.785 16.5425 159.345 16.5425C159.965 16.5425 160.531 16.4463 161.04 16.2526L161.259 17.199C160.663 17.4512 159.961 17.5773 159.149 17.5773C158.176 17.5773 157.409 17.2978 156.855 16.7388C156.297 16.1798 156.021 15.4297 156.021 14.4885C156.021 13.5642 156.279 12.7946 156.797 12.181C157.339 11.5258 158.071 11.1982 158.995 11.1982C159.899 11.1982 160.585 11.5258 161.049 12.181C161.424 12.701 161.609 13.3445 161.609 14.1089ZM160.276 13.7566C160.287 13.3523 160.195 13.0039 160.005 12.7101C159.763 12.3318 159.393 12.142 158.893 12.142C158.437 12.142 158.065 12.3266 157.781 12.6971C157.549 12.9922 157.412 13.3445 157.367 13.7566H160.276Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default AppStoreLogo;
