import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { handleAppendQueryToUrl } from "../../utils/utils";
import { apiGateway } from "../../utils/config";
import { errorState } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { handleClickedOnGraphEvent } from "../../utils/eventFunction";
import { useSelector } from "react-redux";
import {
  feedbackStatsDefaultState,
  feedbackStatsLoadingState,
} from "../../utils/constants/VideoDetailsWithStats";
import AllFeedback from "./AllFeedback";
import SeriesStats from "./SeriesStats";
import SeriesStatsSkeleton from "../Skeletons/VideoDetailsWithStats/SeriesStatsSkeleton";
import AllFeedbacksSkeleton from "../Skeletons/VideoDetailsWithStats/AllFeedbacksSkeleton";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import LineGraph from "./Graph";
import CustomModal from "../Common/CustomModal";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import useCmsOpenEvent from "../../hooks/useCmsOpenEvent";

const VideoDetailsWithStats = ({ match }) => {
  const [openGraph, setOpenGraph] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [isLoading, setIsLoading] = useState(feedbackStatsLoadingState);
  const [responseData, setResponseData] = useState(feedbackStatsDefaultState);
  const history = useHistory();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const videoSlug = match?.params?.video_slug;
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const userName = currentUser?.name;
  const userId = currentUser?.id;
  useCmsOpenEvent(); // event triggers every 5sec

  // onPage change
  const handlePageChange = (e, value) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  const handleGetResponse = async ({ pageArg }) => {
    try {
      setIsLoading({
        feedbacks: true,
        series: responseData?.series?.title ? false : true,
      });
      const url = `${apiGateway}/api/v1/cms/series-overview/${videoSlug}/?page=${pageArg}`;
      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        const series = data?.series;
        const feedbacks = data?.feedbacks;
        const nPages = data?.n_pages;
        const seriesPerformanceList = data?.series_completion_rates;
        setResponseData({
          series,
          feedbacks,
          nPages,
          seriesPerformanceList: seriesPerformanceList,
        });
        setIsLoading({ feedbacks: false, series: false });
      }
    } catch (error) {
      setIsLoading({ feedbacks: false, series: false });
      setResponseData(feedbackStatsDefaultState);
      setMessage({
        error: error?.response?.data?.error_message || error?.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    handleGetResponse({ pageArg: searchParamPage });
  }, [searchParamPage]);

  return (
    <div>
      <div className="flex items-center justify-between mt-3">
        <h6 className="!text-[20px] font-medium">Video Details</h6>
        <FontAwesomeIcon
          icon={faPen}
          className="p-2 text-[32px] bg-baseBlueColor text-white rounded-full cursor-pointer"
          onClick={() => history?.push(`/videos/${videoSlug}`)}
        />
      </div>

      <div className="flex gap-x-5 mt-3 flex-col-reverse gap-y-5 lg:flex-row">
        <div
          className="w-full !bg-white px-4 py-4 rounded-xl lg:h-[calc(100vh-140px)] lg:overflow-y-auto lg:!w-[68%]"
          id="ThinGrayScrollBar"
        >
          <h6 className="!text-[20px] font-medium">All Feedbacks</h6>
          {isLoading?.feedbacks ? (
            <AllFeedbacksSkeleton />
          ) : (
            <div>
              {responseData?.feedbacks?.length === 0 &&
              !isLoading?.feedbacks ? (
                <div className="h-[600px] flex items-center justify-center">
                  <NoFeedbackSvj
                    displayText="No feedback to display"
                    className="w-[300px] mx-auto"
                    displayTextClass="text-center text-gray-500 text-[14px]"
                  />
                </div>
              ) : (
                <AllFeedback data={responseData?.feedbacks} />
              )}

              {responseData?.nPages > 1 && (
                <Pagination
                  className="flex justify-center mt-5"
                  shape="rounded"
                  count={responseData?.nPages || 5}
                  page={searchParamPage}
                  onChange={(e, val) => handlePageChange(e, val)}
                />
              )}
            </div>
          )}
        </div>

        <div className="lg:w-[32%]">
          <div>
            {isLoading?.series ? (
              <SeriesStatsSkeleton />
            ) : (
              <SeriesStats
                data={responseData?.series}
                setMessage={setMessage}
              />
            )}
          </div>

          {/* Graph */}
          {responseData?.seriesPerformanceList?.length > 0 ? (
            <div
              className="!bg-white mt-4 rounded-xl px-4 py-3"
              onClick={() => {
                setOpenGraph(true);
                handleClickedOnGraphEvent({ userId, userName });
              }}
            >
              <div className="flex justify-between items-center">
                <p className="text-gray-500">User Completion Rate Graph</p>
                <FullscreenIcon className="cursor-pointer text-gray-400" />
              </div>
              <div className="mt-4">
                <LineGraph
                  data={responseData?.seriesPerformanceList}
                  videoDurationInSeconds={responseData?.series?.duration_s}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />

      {/*Graph Modal  */}
      <CustomModal
        size="lg"
        title="Video Analytics"
        isButtonsRequired={false}
        show={openGraph}
        onHide={() => setOpenGraph(false)}
      >
        <div className="px-2 py-3 shadow-lg rounded-2xl lg:!m-3 lg:!px-4 lg:!py-4">
          <LineGraph
            data={responseData?.seriesPerformanceList}
            videoDurationInSeconds={responseData?.series?.duration_s}
          />
          <h6 className="mt-4 text-gray-600">User completion rate graph</h6>
          <p className="text-[12px] text-gray-500 mt-2">
            This chart shows that at any given second, how many percentage of
            users are still watching the content
          </p>
        </div>
      </CustomModal>
    </div>
  );
};

export default withRouter(VideoDetailsWithStats);
