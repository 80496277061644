import React from "react";
import ExperimentsList from "../../components/Experiments-List/experiments-list.component";
import "./experiment.styles.css";

export default function Experiment() {
  return (
    <div className="mt-3">
      <ExperimentsList />
    </div>
  );
}
