export const CreatorActionTypes = {
  FETCH_CREATOR_FAILURE: "FETCH_CREATOR_FAILURE",
  FETCH_CREATOR_SUCCESS: "FETCH_CREATOR_SUCCESS",
  FETCH_CREATOR_START: "FETCH_CREATOR_START",
  CHANGE_QUALITY_APPROVED_START: "CHANGE_QUALITY_APPROVED_START",
  CHANGE_QUALITY_APPROVED_FAILURE: "CHANGE_QUALITY_APPROVED_FAILURE",
  CHANGE_QUALITY_APPROVED_SUCCESS: "CHANGE_QUALITY_APPROVED_SUCCESS",
  CHANGE_PROMOTION_SCORE_START: "CHANGE_PROMOTION_SCORE_START",
  CHANGE_PROMOTION_SCORE_FAILURE: "CHANGE_PROMOTION_SCORE_FAILURE",
  CHANGE_PROMOTION_SCORE_SUCCESS: "CHANGE_PROMOTION_SCORE_SUCCESS",
  SET_FILTERS:'SET_FILTERS',
  SET_HAS_MORE:'SET_HAS_MORE_CREATORS',
  CREATOR_INITIALIZE:'CREATOR_INITIALIZE'
};

export default CreatorActionTypes;
