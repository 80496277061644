const userDetailsState = {
  name: "",
  phoneNumber: "",
  roles: [],
};

const teamIsLoadingState = {
  getUsers: false,
  createUser: false,
  updateUser: false,
  deleteUser: false,
};

const TEAM_ROLES = {
  superAdmin: "super_admin",
  admin: "admin",
  creator: "creator",
  blogger: "blogger",
  editor: "editor",
  viewer: "viewer",
  content_manager: "content_manager", // must be deactivated
  community_manager: "community_manager", // must be deactivated
};

const rolesLabelAndValue = [
  { label: " Super Admin", value: "super_admin" },
  { label: "Admin", value: "admin" },
  // { label: "Creator", value: "creator" },
  // { label: "Blogger", value: "blogger" },
  { label: "Editor", value: "editor" },
  { label: "Viewer", value: "viewer" },
];

const handleBGColorForRoles = ({ role }) => {
  switch (true) {
    case role?.includes(TEAM_ROLES.superAdmin):
      return `!bg-[#ef4444]`;
    case role?.includes(TEAM_ROLES.admin):
      return `!bg-[#60a5fa]`;
    case role?.includes(TEAM_ROLES.creator):
      return `!bg-[#803dd9]`;
    case role?.includes(TEAM_ROLES.blogger):
      return `!bg-[#818cf8]`;
    case role?.includes(TEAM_ROLES.editor):
      return `!bg-[#3f3f46]`;
    case role?.includes(TEAM_ROLES.viewer):
      return `!bg-[#22c55e]`;
    default:
      return "!bg-black";
  }
};

const handleDisplayRole = ({ role }) => {
  switch (true) {
    case role?.includes(TEAM_ROLES.superAdmin):
      return "Super Admin";
    case role?.includes(TEAM_ROLES.admin):
      return "Admin";
    case role?.includes(TEAM_ROLES.creator):
      return "Creator";
    case role?.includes(TEAM_ROLES.blogger):
      return "Blogger";
    case role?.includes(TEAM_ROLES.editor):
      return "Editor";
    case role?.includes(TEAM_ROLES.viewer):
      return "Viewer";
    case role?.includes(TEAM_ROLES.content_manager): // must be removed
      return "Content Manager";
    case role?.includes(TEAM_ROLES.community_manager): // must be removed
      return "Community Manager";
    default:
      return "";
  }
};

export {
  userDetailsState,
  teamIsLoadingState,
  TEAM_ROLES,
  rolesLabelAndValue,
  handleBGColorForRoles,
  handleDisplayRole,
};
