import { SeriesDetailsActionTypes } from "./seriesDetails.types";

const INITIAL_STATE = {
   series_notification: {}
};

const seriesNotificationReducer = (state = INITIAL_STATE, action) => {
  try {
    switch (action.type) {
      case SeriesDetailsActionTypes.FETCH_A_SERIES_DETAILS: {
        return { ...INITIAL_STATE, series_notification: action.payload.seriesFetched?.data, status: action.payload.seriesFetched?.status  };
      }
      default:
        return state;
    }
  } catch (err) {
    return {
      ...state,
      series_notification: {},
      errorMessage: action.payload,
    };
  }
};

export default seriesNotificationReducer;
