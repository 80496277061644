import React, { useState } from "react";
import { Chip } from "@mui/material";
import { TEAM_ROLES, rolesLabelAndValue } from "../../utils/constants/Team";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CustomModal from "../Common/CustomModal";
import RequiredStar from "../Common/RequiredStar";
import { selectConfigFilters } from "../../redux/config/config.selector";

const AddOrEditTeamMember = ({
  searchRole,
  openAddOrEditModal,
  userDetails,
  isClickedEdit,
  isLoading,
  onHide,
  setUserDetails,
  setSearchRole,
  handleConfirmation,
}) => {
  const configFilters = useSelector(selectConfigFilters);
  const interSeekhoRoles = configFilters?.seekho_team_roles;

  return (
    <CustomModal
      show={openAddOrEditModal}
      title={isClickedEdit ? "Update User" : "Add User"}
      onHide={onHide}
      isLoading={isLoading}
      handleConfirmationBtnText={isClickedEdit ? "Update" : "Add User"}
      handleConfirmation={handleConfirmation}
    >
      <div>
        <label className="text-[14px]">User Name</label> <RequiredStar />
        <input
          type="text"
          disabled={isClickedEdit}
          value={userDetails?.name}
          placeholder={isClickedEdit ? "" : "Name"}
          onChange={(e) =>
            setUserDetails({ ...userDetails, name: e.target.value })
          }
          className={`border py-2 text-[14px] w-full px-2 200 rounded-md ${
            isClickedEdit ? "!cursor-not-allowed text-gray-600 bg-gray-200" : ""
          }`}
        />
        <label className="mt-4 text-[14px]">Phone Number</label>{" "}
        <RequiredStar />
        <input
          type="text"
          disabled={isClickedEdit}
          value={userDetails?.phoneNumber}
          placeholder={isClickedEdit ? "" : "Mobile Number"}
          minLength="10"
          maxLength="10"
          onChange={(e) =>
            setUserDetails({ ...userDetails, phoneNumber: e.target.value })
          }
          className={`border py-2 text-[14px] w-full px-2 rounded-md ${
            isClickedEdit ? "!cursor-not-allowed text-gray-600 bg-gray-200" : ""
          }`}
        />
        {isClickedEdit && (
          <div className="mt-4">
            <label className="text-[14px]">Current Role</label>
            <br />
            <Chip
              label={
                userDetails?.roles?.includes(TEAM_ROLES.superAdmin)
                  ? "Super Admin"
                  : userDetails?.roles
              }
              variant="filled"
              color={"success"}
              className="px-2 block w-[100px] !bg-[#534f8f] text-white capitalize"
            />
          </div>
        )}
        <div className="mt-4">
          <label className="text-[14px]">Update Role</label> <RequiredStar />
          <Autocomplete
            value={searchRole}
            options={interSeekhoRoles}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, newValue) => {
              setSearchRole(newValue);
              setUserDetails({ ...userDetails, roles: [newValue?.value] });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search or Select Role"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#534f8f",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#534f8f",
                    },
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #534f8f",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "15px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#534f8f", // Changing label color when focused
                  },
                }}
              />
            )}
            className=""
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AddOrEditTeamMember;
