import { apiGateway } from "../../utils/config";
import { GET_ADMIN_SERIES, GET_SERIES_LIST, GET_SERIES_V1, SERIES_V1_1 } from "../../utils/constants";
import SeriesActionTypes from "./series.types";

export const setFilters = (filters) => {
  return {
    type: SeriesActionTypes.SET_FILTERS,
    payload: filters,
  };
};

export const setHasMore = (hasMore) => {
  return {
    type: SeriesActionTypes.SET_HAS_MORE,
    payload: hasMore,
  };
};

export const fetchSeriesStart = () => ({
  type: SeriesActionTypes.FETCH_SERIES_START,
});

export const createSeries = (data) => ({
  type: SeriesActionTypes.CREATE_SERIES,
  payload: data,
});

export const fetchSeriesSuccess = (
  data,
  searchedString,
  pageNumber,
  filters,
  sortKey,
  sortReverse
) => ({
  type: SeriesActionTypes.FETCH_SERIES_SUCCESS,
  payload: {
    hasMore: data.has_more,
    seriesFetched: data.series,
    table_data: {
      n_series: data.n_series,
      n_pages: data.n_pages,
      page: data.page
    },
    searchedString,
    pageNumber,
    filters,
    sortKey,
    sortReverse
  },
});

export const fetchSeriesFailure = (errorMessage) => ({
  type: SeriesActionTypes.FETCH_SERIES_FAILURE,
  payload: errorMessage,
});

export const initializeSeries = () => ({
  type: SeriesActionTypes.SERIES_INITIALIZE,
});

export const changeSeriesDetails = (series) => ({
  type: SeriesActionTypes.CHANGE_A_SERIES_DETAILS,
  payload: series,
});

export const addSeries = (series) => ({
  type: SeriesActionTypes.ADD_SERIES,
  payload: series,
});

export const removeSeries = (seriesId) => ({
  type: SeriesActionTypes.REMOVE_SERIES,
  payload: { seriesId },
});

export const updateSeries = (series) => ({
  type: SeriesActionTypes.UPDATE_SERIES,
  payload: series,
});

export const changePromotionScoreStart = () => ({
  type: SeriesActionTypes.CHANGE_PROMOTION_SCORE_START,
});

export const changePromotionScoreSuccess = (seriesSlug, score) => ({
  type: SeriesActionTypes.CHANGE_PROMOTION_SCORE_SUCCESS,
  payload: {
    seriesSlug,
    score,
  },
});

export const changePromotionScoreFailure = (errorMessage) => ({
  type: SeriesActionTypes.CHANGE_PROMOTION_SCORE_FAILURE,
  payload: errorMessage,
});

export const updateSelectedTabStart = (selectedTab) => ({
  type: SeriesActionTypes.UPDATE_SELECTED_TAB_START,
  payload: selectedTab,
});

export const updateSelectedTabSuccess = (selectedTab) => ({
  type: SeriesActionTypes.UPDATE_SELECTED_TAB_SUCCESS,
  payload: selectedTab,
});

export const updateSelectedTab = (tabValue) => {
  return async (dispatch) => {
    dispatch(updateSelectedTabStart(tabValue));
  };
};

export const fetchSeriesStartAsync = (axiosPrivate, pageNumber, searchName, filters, sortKey, sortReverse, selectedCreatorSeriesFilter, setErrorMsg) => {
  return async (dispatch, getState) => {
    const { series } = getState();
    if (series.sortKey !== sortKey || series.sortReverse !== sortReverse) {
      pageNumber = 1;
    }
    if (pageNumber === 1) {
      dispatch(initializeSeries());
    }
    dispatch(fetchSeriesStart());
    if (selectedCreatorSeriesFilter) {
      filters = selectedCreatorSeriesFilter
    }

    let url = `${apiGateway}${GET_SERIES_LIST}?page=${pageNumber}&lang=en`;
    if (searchName) url += `&q=${searchName}`;
    const creatorArray = filters?.get("creatorFilter");
    const showSlug = filters.get("showFilter");
    if (sortKey) {
      url += `&sorting_key=${sortKey}`
      if (sortReverse?.toString())
        url += `&reverse=${sortReverse}`
    };
    if (filters) {
      const categoryArray = filters.get("categoryFilter");
      const statusArray = filters.get("statusFilter");
      const reviewStatusArray = filters.get("reviewStatusFilter");
      const isPremiumArray = filters.get("isPremiumFilter");
      const promotedSeriesArray = filters.get("promotedSeriesFilter");
      const contentManagerArray = filters.get("contentManagerFilter");
      const originalSeriesArray = filters.get("isOriginalFilter");
      const notificationArray = filters.get("notificationFilter");
      const languageArray = filters.get("languageFilter");
      const dubbedArray = filters.get("dubbedFilter");
      const is_news = filters.get("newsFilter");

      if(notificationArray?.length !==0){
        for (let i = 0; i < notificationArray.length; i++) {
          const notificationEle = notificationArray[i];
          url += `&notification_type=${notificationEle}`;
        }
      }
      if (contentManagerArray.length !== 0) {
        for (let i = 0; i < contentManagerArray.length; i++) {
          const contentManager = contentManagerArray[i];
          url += `&content_manager_ids=${contentManager}`;
        }
      }

      if (creatorArray && creatorArray.length !== 0) {
        const creatorId = creatorArray[0].id;
        url += `&creator_ids=${creatorId}`;
      }

      if (categoryArray && categoryArray.length !== 0) {
        for (let i = 0; i < categoryArray.length; i++) {
          const categoryId = categoryArray[i];
          url += `&category_ids=${categoryId}`;
        }
      }
      if (statusArray && statusArray.length !== 0) {
        for (let i = 0; i < statusArray.length; i++) {
          const statusValue = statusArray[i];
          url += `&status=${statusValue}`;
          if(statusValue == 'scheduled'){
            url += `&sorting_key=scheduled_on`
            if (sortReverse?.toString())
              url += `&reverse=${sortReverse}`
          }
        }
      }
      if (reviewStatusArray && reviewStatusArray?.length !== 0) {
        for (let i = 0; i < reviewStatusArray?.length; i++) {
          const reviewStatus = reviewStatusArray[i];
          url += `&review_status=${reviewStatus}`;
        }
      }
      if (isPremiumArray && isPremiumArray?.length !== 0) {
        for (let i = 0; i < isPremiumArray?.length; i++) {
          const isPremium = isPremiumArray[i];
          if (isPremium)
            url += `&is_premium=${isPremium}`;
        }
      }
      if (promotedSeriesArray && promotedSeriesArray?.length !== 0) {
        for (let i = 0; i < promotedSeriesArray?.length; i++) {
          const promotedSeries = promotedSeriesArray[i];
          if (promotedSeries)
            url += `&promotion=${promotedSeries}`;
        }
      }
      
      if (originalSeriesArray && originalSeriesArray?.length !== 0) {
        for (let i = 0; i < originalSeriesArray?.length; i++) {
          const originalSeries = originalSeriesArray[i];
          if (originalSeries)
            url += `&original_series=${originalSeries}`;
        }
      }

      if (languageArray && languageArray?.length !== 0) {
        for (let i = 0; i < languageArray?.length; i++) {
          const language = languageArray[i];
          if (language)
            url += `&language=${language}`;
        }
      }

      if (dubbedArray && dubbedArray?.length !== 0){
        for (let i = 0; i < dubbedArray?.length; i++) {
          const dubbed = dubbedArray[i];
          if (dubbed)
            url += `&is_dubbed=${dubbed}`;
        }
      }

      if (is_news && is_news?.length !== 0){
        for (let i = 0; i < is_news?.length; i++) {
          const is_news_s = is_news[i];
          if (is_news_s)
            url += `&is_news=${is_news_s}`;
        }
      }

      if(showSlug && showSlug?.length !== 0){
        url += `&show_slug=${showSlug}`;
      }
    }
      axiosPrivate.get(url)
      .then(({data, status}) => {
        if(status === 200 && Object?.keys(data).length !== 0)
            dispatch(fetchSeriesSuccess(data, searchName, pageNumber, filters, sortKey, sortReverse));
      })
      .catch((err) => {
        setErrorMsg({error: "Something went wrong.", type: "failed"})
      });
  };
};

export const initializeSeriesData = () => ({
  type: SeriesActionTypes.INITIALIZE_SERIES,
});


export const markSeriesOriginalStart = () => ({
  type: SeriesActionTypes.CHANGE_SERIES_ORIGINAL_START,
});

export const markSeriesOriginalFailure = (errorMessage) => ({
  type: SeriesActionTypes.CHANGE_SERIES_ORIGINAL_FAILURE,
  payload: errorMessage,
});

// change the status of the series

export const changeSeriesStatusStart = () => ({
  type: SeriesActionTypes.CHANGE_SERIES_STATUS_START,
});

export const changeSeriesStatusFailure = (errorMessage) => ({
  type: SeriesActionTypes.CHANGE_SERIES_STATUS_FAILURE,
  payload: errorMessage,
});

/**
 * Returns the updated series.
 *
 * @param {id} seriesSlug The unique identifier for series.
 * @param {String} action The action is based on buttons and based on action 2 endpoints are conditionally used below.
 */
export const changeSeriesStatusAsync = (axiosPrivate, seriesSlug, action, reasonEntered, setReload, isFetching, setErrorMsg, handleGetSeries) => {
  return async (dispatch) => {
    dispatch(changeSeriesStatusStart());

    let urlPublish = `${apiGateway}${SERIES_V1_1}${seriesSlug}/publish/`;
    let urlReview = `${apiGateway}${GET_ADMIN_SERIES}${seriesSlug}/review/`;
    let finalUrl = "";
    let body = {
      action: action === "publish_now" ? "approved": action,
    };
    if (action === "publish" || action === "unpublish") {
      // url = `http://preprod.seekhoapp.com/api/admin/v1/${seriesSlug}/quality/`;
      finalUrl = urlPublish;
    } else if(action === "publish_now"){
        finalUrl = urlReview;
        body['publish_now'] = true;
    } else if (action === "changes_required") {
      finalUrl = urlReview;
      body = {
        action,
        reason: reasonEntered,
      };
    } else {
      finalUrl = urlReview;
    }

    axiosPrivate.post(finalUrl, body)
    .then(({data, status}) => {
      if (data?.error_code || data?.error_message) {
        setErrorMsg({error: data?.error_message??"Oops! Unable to update status", type: "failed"})
      } else if (data?.message) {
        setErrorMsg({error: data.message??"Oops! Unable to update status", type: "failed"})
      } else {
        if(status === 200){
          handleGetSeries();
          setErrorMsg({error: "Series Status Changed", type: "success"})
          if(setReload){
            setReload(!isFetching);
          }
        }
      }
    })
    .catch(({response}) => {    
      setErrorMsg({error: response?.data ? response?.data?.error_message || response?.data?.message  : "Oops! Unable to update status", type: "failed"})
    });
  };
};

export const rejectionReasonStart = () => ({
  type: SeriesActionTypes.REJECTION_REASON_START,
});

export const rejectionReasonFailure = (errorMessage) => ({
  type: SeriesActionTypes.REJECTION_REASON_FAILURE,
  payload: errorMessage,
});

export const rejectionReasonSuccess = (reasonsData) => ({
  type: SeriesActionTypes.REJECTION_REASON_SUCCESS,
  payload: reasonsData,
});

export const fetchRejectionReason = (axiosPrivate, seriesSlug, setErrorMsg) => {
  return async (dispatch) => {
    dispatch(rejectionReasonStart);
    let urlRejectionReasons = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/rejection-reasons/`;
    axiosPrivate.get(urlRejectionReasons)
    .then(({data, status}) => {
      if (status !== 200 || data?.error_code || data?.message) {
        setErrorMsg({
          error: data?.error_message || data?.message, type: "failed"
        })
        } else {
        dispatch(rejectionReasonSuccess(data));
      }
    })
    .catch(({response}) => {
      setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"})
    });
  };
};


export const setSelectedCreatorSeriesFilter = (filters) => {
  return {
    type: SeriesActionTypes.SET_SELECTED_CREATOR_SERIES_FILTER,
    payload: filters,
  }
}