import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  handleAppendQueryToUrl,
  handleConvertUTCToIST,
} from "../../utils/utils";
import { apiGateway } from "../../utils/config";
import { Chip, LinearProgress, Pagination } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoDataFound from "../Common/NoDataFound";
import GuitarIllustration from "../Common/GuitarIllustration";

const TrackLogs = ({ match }) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [trackLogs, setTrackLogs] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const trackId = match.params?.track_id;
  const tableHeading = [
    "Profile Id",
    "Editor",
    "Action Made",
    "Date",
    "Status",
  ];
  const axiosPrivate = useAxiosPrivate();

  const handleGetTrackLogs = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/track/${trackId}/logs/?page=${searchParamPage}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setIsLoading(false);
        setTrackLogs(response?.data?.logs);
        setTotalPages(response?.data?.n_pages);
        setNoDataFound(response?.data?.logs?.length === 0 ? true : false);
      }
    } catch (error) {
      setNoDataFound(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetTrackLogs();
  }, [pageNumber]);

  return (
    <div>
      <h6 className="!text-[20px] mt-2 font-medium">Track Logs</h6>
      <div>
        {isLoading && <LinearProgress color="inherit" className="mt-2" />}
        {noDataFound ? (
          <div className="!flex !justify-center !items-center h-[calc(100vh-160px)]">
            <GuitarIllustration
              className="!w-[250px] !mx-auto"
              displayText="No Logs To Display or Something went wrong"
            />
          </div>
        ) : (
          <div className="mt-2">
            <div
              className="!relative h-[calc(100vh-129px)] !overflow-x-auto rounded-md !bg-white pb-4 border-t"
              id="ThinGrayScrollBar"
            >
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="border-b bg-gray-100">
                  <tr>
                    {tableHeading.map((item) => {
                      return (
                        <th
                          scope="col"
                          className="px-6 py-3 !text-[12px] text-gray-700 font-medium bg-gray-100"
                          key={item}
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {trackLogs?.map((item) => {
                    return (
                      <tr
                        className="text-black cursor-pointer !font-normal border-b last:border-none "
                        key={item?.id}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                          {item?.profile_id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[14px] tracking-wide">
                          {item?.name}
                        </td>

                        <td class="px-6 py-4 whitespace-nowrap text-[14px] tracking-wide">
                          {item?.action}
                        </td>

                        <td class="px-6 py-4 whitespace-nowrap text-[14px] tracking-wide text-gray-500">
                          {handleConvertUTCToIST(item?.created_on)}
                        </td>

                        <td class="px-6 py-4 whitespace-nowrap text-[14px]">
                          <Chip
                            label={item?.status}
                            variant="filled"
                            color={
                              item?.status === "success" ? "success" : "error"
                            }
                            className="px-2 !w-[85px] capitalize"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                count={totalPages}
                page={searchParamPage}
                onChange={(e, val) => {
                  setPageNumber(val);
                  handleAppendQueryToUrl({
                    history,
                    queryName: "page",
                    queryValue: val,
                  });
                }}
                shape="rounded"
                className="!w-fit !mx-auto mt-3"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(TrackLogs);
