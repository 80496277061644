import React, { useEffect, useState } from "react";
import { LinearProgress, Pagination, Paper } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { apiGateway } from "../../utils/config";
import { EDIT_TRACK_ACTION, GET_ALL_TRACKS } from "../../utils/constants";
import { Button, Modal } from "react-bootstrap";
import {
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";
import "./track.styles.css";
import AddIcon from "@mui/icons-material/Add";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";
import CustomSearch from "../Common/CustomSearch";
import TrackListingTable from "./TrackListingTable";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";

export default function TrackList() {
  const [searchQuery, setSearchQuery] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [isDialog, setIsDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogDefault, setIsDialogDefault] = useState(false);
  const [data, setData] = useState([]);
  const [selectedTrack, setSelectTrack] = useState({});
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "" });
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const axiosPrivate = useAxiosPrivate();
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );

  const handleGetTracks = async ({ query, pageArg }) => {
    try {
      let url = `${apiGateway}${GET_ALL_TRACKS}?page=${pageArg}&q=${query}`;
      setIsLoading(true);
      const { data } = await axiosPrivate.get(url);
      if (data) {
        setData(data?.track_list);
        setPageCount(data?.n_pages);
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  // Triggers when searched query and hit enter key
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  const toggleStatus = (track_id, is_active) => {
    let formData = new FormData();
    formData.append("is_active", !is_active);
    let url = `${apiGateway}${EDIT_TRACK_ACTION}${track_id}/toggle-status/`;
    setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          setIsLoading(false);
          handleGetTracks({
            pageArg: searchParamPage,
            query: searchParamSearchQuery,
          });
          setErrorMsg({ type: "success", error: "Track is status changed" });
        }
      })
      .catch(({ response }) => {
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "failed",
            error: response?.data
              ? response?.data?.error_message
              : "Something went wrong",
          });
        }
        setIsLoading(false);
      });
    setSelectTrack({});
    setIsDialog(false);
  };

  const makeDefault = (track_id) => {
    let url = `${apiGateway}${EDIT_TRACK_ACTION}${track_id}/default/`;
    setIsLoading(true);
    axiosPrivate
      .post(url)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          setIsLoading(false);
          handleGetTracks({
            pageArg: searchParamPage,
            query: searchParamSearchQuery,
          });
          setErrorMsg({ type: "success", error: "Track default is updated " });
        }
      })
      .catch(({ response }) => {
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "failed",
            error: response?.data
              ? response?.data?.error_message
              : "Something went wrong",
          });
        }
        setIsLoading(false);
      });
    setSelectTrack({});
    setIsDialogDefault(false);
  };

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  useEffect(() => {
    handleGetTracks({
      pageArg: searchParamPage,
      query: searchParamSearchQuery,
    });
  }, [searchParamPage, searchParamSearchQuery]);

  return (
    <div className="experiments-list">
      {internalTeamAccessGlobalState && (
        <div
          className="cta-button-create"
          onClick={() => {
            window.location.href = "#/track-create";
          }}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Track</span>
        </div>
      )}

      <div className="w-[90%] lg:w-[45%]">
        <CustomSearch
          placeHolder="Search track by title or id"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleInputKey={handleInputKey}
          handleClearInput={() => {
            setSearchQuery("");
            handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
          }}
          handleClickedOnSearchIcon={() => {
            handleGetTracks({
              query: searchParamSearchQuery,
              pageArg: searchParamPage,
            });
          }}
        />
      </div>

      <h6 className="!text-[20px] mt-2 font-medium">Tracks</h6>

      <div className="mt-2">
        {isLoading && <LinearProgress color="inherit" />}
        <div
          className="!relative h-[calc(100vh-179px)] !overflow-x-auto rounded-md !bg-white pb-4 border-t"
          id="ThinGrayScrollBar"
        >
          <div>
            {!isLoading && data?.length === 0 ? (
              <div className="flex justify-center items-center mt-64">
                <NoFeedbackSvj
                  className="w-[250px]"
                  displayText="No tracks to display or something went wrong!!!"
                  displayTextClass="mt-2 text-gray-400 text-[14px]"
                />
              </div>
            ) : (
              <div>
                <TrackListingTable
                  data={data}
                  internalTeamAccessGlobalState={internalTeamAccessGlobalState}
                  setSelectTrack={setSelectTrack}
                  setIsDialogDefault={setIsDialogDefault}
                />

                {pageCount > 1 && (
                  <div className="w-fit mx-auto mt-2">
                    <Pagination
                      count={pageCount}
                      page={pageNumber}
                      onChange={(e, val) => handlePageChange(e, val)}
                      variant="outlined"
                      shape="rounded"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={isDialog} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setIsDialog(false);
            setSelectTrack({});
          }}
        >
          Track - {selectedTrack?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>
              Update track status to{" "}
              {selectedTrack?.is_active ? "InActive" : "Active"}
            </b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                toggleStatus(selectedTrack?.id, selectedTrack?.is_active);
              }}
            >
              {isLoading
                ? "Loading..."
                : selectedTrack?.is_active
                ? "InActive"
                : "Active"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setIsDialog(false);
                setSelectTrack({});
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isDialogDefault} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setIsDialogDefault(false);
            setSelectTrack({});
          }}
        >
          Track - {selectedTrack?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>
              {!selectedTrack?.is_default ? "Make" : "Remove"} this track as
              default
            </b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                makeDefault(selectedTrack?.id);
              }}
            >
              {isLoading
                ? "Loading..."
                : !selectedTrack?.is_default
                ? "Make Default"
                : "Remove Default"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setIsDialogDefault(false);
                setSelectTrack({});
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Alert */}
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() =>
          setErrorMsg({ error: "", type: errorMsg?.type })
        }
      />
    </div>
  );
}
