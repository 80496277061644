import { createSelector } from "reselect";

const selectCreators = (state) => state.creator;

export const selectAllCreators = createSelector(
  [selectCreators],
  (creator) => creator.creators
);

export const selectCurrentPageNumber = createSelector(
  [selectCreators],
  (creator) => creator.currentPageNumber
);

export const selectLastFetchedPageNumber = createSelector(
  [selectCreators],
  (creator) => creator.lastPageFetched
);

export const selectIsCreatorsFetching = createSelector(
  [selectCreators],
  (creator) => creator.isFetching
);

export const selectHasMore = createSelector(
  [selectCreators],
  (creator) => creator.hasMore
);


export const selectFilters = createSelector(
  [selectCreators],
  (creator) => creator.filters
);

