import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CircularProgress } from "@mui/material";

function CustomModal({
  show,
  onHide,
  title,
  size,
  key,
  children,
  handleConfirmation,
  isLoading,
  handleConfirmationBtnText = "Yes",
  isButtonsRequired = true,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size || "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      key={key}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-[17px] line-clamp-2">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {isButtonsRequired && (
        <Modal.Footer>
          <Button
            onClick={handleConfirmation}
            disabled={isLoading}
            style={{ background: "#555190", borderColor: "#555190" }}
          >
            {isLoading ? (
              <div className="h-[25px]">
                <CircularProgress className="text-white mt-1" size={20} />
                <p className="opacity-0">{handleConfirmationBtnText}</p>
              </div>
            ) : (
              handleConfirmationBtnText
            )}
          </Button>
          {onHide && (
            <Button
              onClick={onHide}
              className="bg-gray-600 border-transparent hover:bg-gray-600 hover:border-transparent"
            >
              Cancel
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default CustomModal;
