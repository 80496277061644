import React from "react";
import { Drawer } from "@mui/material";
import { handleDefaultProfile } from "../../../utils/utils";
import { handleDisplayRole } from "../../../utils/constants/Team";
import CloseIcon from "@mui/icons-material/Close";
import SideNavigation from "../../SideNavigation/side-navigation.component";
import SeekhoWhiteLogo from "../../SideNavigation/Icons/seekho-white.png";

const MobileSideDrawer = ({
  openSideDrawer,
  currentUser,
  setOpenSideDrawer,
  setOpenLogoutModal,
}) => {
  return (
    <div>
      <Drawer
        anchor="right"
        open={openSideDrawer}
        onClose={() => setOpenSideDrawer(false)}
        classes={{ paper: "w-full h-full" }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="bg-black flex py-3 px-3 justify-between items-center">
          <div>
            <img src={SeekhoWhiteLogo} alt="" className="w-[100px]" />
          </div>

          <button onClick={() => setOpenSideDrawer(false)}>
            <CloseIcon className="!text-white" />
          </button>
        </div>
        <div className="flex mt-4 !items-start gap-x-4 px-2 md:!block">
          <img
            src={
              currentUser?.avatar ||
              handleDefaultProfile({
                userName: currentUser?.name,
              })
            }
            alt={currentUser?.name}
            className="w-[80px] h-[80px] md:!w-[100px] md:h-[100px] rounded-[50%] md:!m-auto select-none object-contain"
          />

          <div className="md:!text-center mt-2 text-[12px]">
            <p>Welcome,</p>
            <p className="mt-1 font-semibold">{currentUser?.name}</p>

            <div className="flex flex-wrap gap-x-1 mt-2">
              {currentUser?.roles?.map((item, index) => (
                <span
                  key={index}
                  className="text-[10px] border px-2 py-[3px] rounded-full bg-gray-200 text-gray-700"
                >
                  {handleDisplayRole({ role: item })}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 px-2">
          <SideNavigation
            currentUser={currentUser}
            setOpenSideDrawer={setOpenSideDrawer}
            setOpenLogoutModal={setOpenLogoutModal}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default MobileSideDrawer;
