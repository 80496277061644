import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../Loader";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBatteryFull, faBell, faChevronDown, faChevronUp, faPen, faPlay, faSignal } from "@fortawesome/free-solid-svg-icons";
import Mb2 from '../../assests/images/mobile_framef.png';
import Logo from '../../assests/images/seekho-logo.png';
import TextEditor from "./CustomEditor";
import LayoutType from "./Layout.Modal";
import "./add-notification.styles.css";
import AlertComponent from "../Alert-Messages/alert-component.component";
import PromptModal from "./Prompt.Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeriesNotificationSuccess } from "../../redux/seriesDetails/seriesDetails.action";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { NOTIFICATION_CREATE } from "../../utils/constants";


export default function AddNotificationComponent({match, history}) {
    const axiosPrivate = useAxiosPrivate();
    const seriesSlug =  match?.params?.series_slug;
    let url = `${apiGateway}${NOTIFICATION_CREATE}`
    const notificationData = useSelector((state) => state?.notification?.series_notification);
    const editorRef1 = useRef(null);
    const editorRef2 = useRef(null);
    const [isEdit, setEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [viewColor , setViewColor] = useState(false);
    const [viewLayout , setViewlayout] = useState(true);
    const [color, setColor] = useState('#ffffff');
    const [ctaText, setCTAtext] = useState('Play');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedlayout, setSelectedLayout] = useState('default');
    const [selectedFile, setSelectedFile] = useState('');
    const [imageSource, setImageSource] = useState('');
    const [imageSourceNetwork, setImageSourceNetwork] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [promptType, setPromptType] = useState('');
    const [promptAction, setPromptAction] = useState(false);
    const [notificationExpand, setNotificationExpand] = useState(false);
    const [notificationId, setNotificationId] = useState(0)
    const [resetData, setResetData] = useState(false);
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(notificationData && Object.keys(notificationData)?.length > 0) {
            setTitle(notificationData?.title)
            setDescription(notificationData?.description)
            setCTAtext(notificationData?.action_text)
            setColor(notificationData?.background_color)
            setSelectedLayout(notificationData?.notification_type)
            setImageSource(notificationData?.image_url);
            setImageSourceNetwork(notificationData?.image_url)
            setViewColor(true);
            setNotificationId(notificationData?.id);
        }
    },[notificationData]);
    
    useEffect(() => {
        if(notificationData && Object.keys(notificationData)?.length > 0){
            setEdit(true);
            setViewlayout(false);
        }
    },[notificationData]);

    const OnChangeEditor = (type, data, plainText) => {
        setErrorMsg('');
        if(type == 'title'){
            setTitle(data);
        }else{
            setDescription(data);
        }
    }

    const handleFileChange = (event) => {
        setErrorMsg('');
        setSelectedFile('');
        setImageSource('');
        const MAX_FILE_SIZE = 4096 // 4MB
        if(event.target.files?.length > 0){
            const fileSizeKiloBytes = event.target.files[0]?.size / 1024;
            if(fileSizeKiloBytes > MAX_FILE_SIZE){
                setErrorMsg("File size is greater than 4MB");
            }else{
                setSelectedFile(event.target.files[0]);
                const previewURL = URL.createObjectURL(event.target.files[0]);
                setImageSource(previewURL);
                setImageSourceNetwork("");
                // var reader = new FileReader();
                // reader.onload = function (e) {
                //     setImageSource([reader.result]);
                //     setImageSourceNetwork("");
                // }
            }
        }
    }
    
    const tirggerOnSave = () => {
        if(isEdit){
            if(!title || title == null || title == undefined || title == ''){
                setErrorMsg('Please add title to add this layout');
                return true
            }
            if(!description || description == null || description == undefined || description == '' || getTextExtract()[1]?.length === 0){
                setErrorMsg('Please add description to add this layout');
                return 
            }
            if(getTextExtract()[0]?.length > 64){
                setErrorMsg('Only 64 character limit in ttile');
                return true
            }
            if(getTextExtract()[1]?.length > 240){
                setErrorMsg('Only 240 character limit in description');
                return true
            }
            if(selectedlayout === 'title_image' || selectedlayout === 'title_image_play'){
                if(!imageSource){
                    setErrorMsg('Please add image to add this layout up');
                    return
                }
            }
            setPromptType('Save');
            setPromptAction(true);
        }else{
            if(!title || title == null || title == undefined || title == ''){
                setErrorMsg('Please add title to add this layout');
                return true
            }
            if(!description || description == null || description == undefined || description == '' || getTextExtract()[1]?.length === 0){
                setErrorMsg('Please add description to add this layout');
                return 
            }
            if(getTextExtract()[0]?.length > 64){
                setErrorMsg('Only 64 character limit in ttile');
                return true
            }
            if(getTextExtract()[1]?.length > 240){
                setErrorMsg('Only 240 character limit in description');
                return true
            }
            if(selectedlayout === 'title_image' || selectedlayout === 'title_image_play'){
                if(!selectedFile || selectedFile == "" || selectedFile == undefined){
                    setErrorMsg('Please add image to add this layout');
                    return
                }
            }
            setPromptType('Save');
            setPromptAction(true);
        }
    }

    const handleSave = async() => {
        let formData = new FormData(); 
        if(selectedlayout === 'title_image'){
            formData.append('image_url', selectedFile);
        }
        if(selectedlayout === 'title_image_play'){
            formData.append('image_url', selectedFile);
            formData.append('action_text', ctaText);
        }
        formData.append('title', title?.trim());
        formData.append('series_slug', seriesSlug);
        formData.append('background_color', color);
        formData.append('description', description?.trim());
        formData.append('notification_type', selectedlayout);

        axiosPrivate.post(url, formData)
        .then(({data, status}) => {
            if(data?.error_message && Object.keys(data?.error_message).length > 0){
                setErrorMsg(data?.error_message ?? "Something went wrong")
            }else{
                if(data?.series_notification?.title && status === 200){
                    setSuccessMsg("Notification added successfully!. Redirecting in 3 sec... ");
                    setPromptAction(false);
                    dispatch(fetchSeriesNotificationSuccess({
                        status: true,
                        data: data?.series_notification}
                    ));
                    setTimeout(() => {
                        history.goBack();
                    }, 3000);
                }
            }
        }).catch(({response}) => {
            setErrorMsg(response?.data?.error_message ? response?.data?.error_message : "Something went wrong")
        })
    }

    const updateNotification = async() => {
        let formData = new FormData();
        if(selectedlayout === 'title_image'){
            formData.append('image_url', selectedFile ?? imageSource);
        }
        if(selectedlayout === 'title_image_play'){
            formData.append('image_url',  selectedFile ?? imageSource);
            formData.append('action_text', ctaText);
        }
        formData.append('title', title?.trim());
        formData.append('id', notificationId);
        formData.append('background_color', color);
        formData.append('description', description?.trim());
        formData.append('notification_type', selectedlayout);

        axiosPrivate.post(url, formData)
        .then(({data, status}) => {
            if(data?.error_message && Object.keys(data?.error_message).length > 0){
                setErrorMsg(data?.error_message ?? "Something went wrong")
            }else{
                if(status === 200 && data?.series_notification?.title){
                    setSuccessMsg( "Notification updated successfully!.  Redirecting in 3 sec...");
                    setPromptAction(false);
                    dispatch(fetchSeriesNotificationSuccess({
                        status: true,
                        data: data?.series_notification}
                    ));
                    setTimeout(() => {
                        history.goBack();
                    }, 3000);
                }
            }
        }).catch(({response}) => {
            setErrorMsg(response?.data ? response?.data?.error_message : "Something went wrong");
        })
    }

    const copylink = async(data) => {
        var copyText = data;
        await navigator?.clipboard?.writeText(copyText);
        setCopied(true);
    }

    const getTextExtract = () => {
        const plaintext1 = editorRef1?.current?.state?.editorState?.getCurrentContent()?.getPlainText();
        const plaintext2 = editorRef2?.current?.state?.editorState?.getCurrentContent()?.getPlainText();
        return [plaintext1, plaintext2];
    };

    const resetAndClose = () => {
        setResetData(true);
        if(isEdit){
            setTitle(notificationData?.title);
            setDescription(notificationData?.description);
            setCTAtext(notificationData?.action_text);
            setColor(notificationData?.background_color);
            setSelectedLayout(notificationData?.notification_type)
            setImageSource(notificationData?.image_url);
            setViewColor(true);
            setNotificationId(notificationData?.id);
            setPromptAction(!promptAction);
            dispatch(fetchSeriesNotificationSuccess({
                status: true,
                data: notificationData
            }
            ));
        }
        history.goBack();
        // dispatch(fetchSeriesNotificationSuccess({notificationData}));
    }

    return (
        <div className="add-notification-wrapper">
            {copied && <AlertComponent type="success" onClose={() => setCopied(!copied)} message="Image link copied !" setAlertNotification={setCopied} />}
            <AlertComponent type="failed" message={errorMsg} setAlertNotification={setErrorMsg} />
            <AlertComponent type="success" message={successMsg} setAlertNotification={setErrorMsg}/>
            <LayoutType show={viewLayout}  selectedlayout={selectedlayout} setSelectedLayout={setSelectedLayout} layoutTitle={'Select a Layout'} handleClose={() => {
                setViewlayout(!viewLayout);
                setErrorMsg('');
            }}/>
            <PromptModal resetAndClose={resetAndClose} type={promptType} handleSave={isEdit ? updateNotification : handleSave} show={promptAction} handleClose={() => setPromptAction(!promptAction)}/>
            {!isLoading ?
            <div className="section-wrapper-device-form">
                <div className="form-section">
                    <div className="layout-section">
                        <div className="layout-heading"><p>Layout</p></div>
                        <hr/>
                        <div className="layout-form">
                            <div className="layout-form-group">
                                <Form.Label className="layout-label">Layout</Form.Label>
                                <div className="layout-field">
                                    <span>{selectedlayout == 'default' && "Text"}{selectedlayout == 'title_image' && "Text & Image"} {selectedlayout == 'title_image_play' && "Text, Image & CTA"}</span>
                                    <span className="wrapper-icon" onClick={() => setViewlayout(!viewLayout)}>
                                        <FontAwesomeIcon className="layout-icons" icon={faPen} />
                                    </span>
                                </div>
                            </div>
                            <div className="layout-form-group">
                                <Form.Label className="layout-label">Background Color</Form.Label>
                                <div className="layout-field">
                                    <Checkbox style={{marginLeft: "-10px"}}  onChange={() =>  setViewColor(!viewColor)} checked={viewColor}/>
                                </div>
                            </div>
                            {viewColor && <div className="layout-form-group">
                                    <Form.Label className="layout-label">Select Color</Form.Label>
                                    <div className="layout-field">
                                        <div className="color-select-input">
                                            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} className="inputfeild-color"/>
                                            <input type="text" value={color} onChange={(e) => setColor(e.target.value)} className="text-inputfeild-color"/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="layout-section mb-3 mt-3">
                        <div className="layout-heading"><p>Text & Actions</p></div>
                        <hr/>
                        <div className="layout-form">
                            <div className="layout-form-group">
                                <Form.Label className="layout-label">TITLE</Form.Label>
                                <div className="layout-field" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div className="layout-editor-wrapper">
                                        <TextEditor isEdit={isEdit} editorRef={editorRef1} prevData={notificationData?.title} setReset={setResetData} reset={resetData} fontSize={11} placeholder="Write your title" type="title" data={title} onChange={OnChangeEditor}/>  
                                    </div>
                                    <span className="text-span-char-count" style={{color: getTextExtract()[0]?.length > 64 && "red" }}>Character  count  {getTextExtract()[0]?.length }/ 64 character</span>
                                </div>
                            </div>
                            <div className="layout-form-group">
                                <Form.Label className="layout-label">DESCRIPTION</Form.Label>
                                <div className="layout-field" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div className="layout-editor-wrapper">
                                        <TextEditor  isEdit={isEdit} editorRef={editorRef2}  prevData={notificationData?.description} setReset={setResetData} reset={resetData} fontSize={10} placeholder="Write your description" type="description" data={description} onChange={OnChangeEditor} />
                                    </div> 
                                    <span className="text-span-char-count"  style={{color: getTextExtract()[1]?.length > 240 && "red" }}>Character  count  {getTextExtract()[1]?.length }/ 240 character</span>
                                </div>
                            </div>
                            {selectedlayout === 'title_image_play' && <div className="layout-form-group">
                                <Form.Label className="layout-label">Action text</Form.Label>
                                <div className="layout-field">
                                    <input type="text" value={ctaText} onChange={(e) => setCTAtext(e.target.value)} className="textfield-cta" placeholder="Enter the Button text"/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {selectedlayout !== 'default' && <div className="layout-section">
                        <div className="layout-heading"><p> Add Images</p></div>
                        <hr/>
                        <div className="layout-form">
                            <div className="layout-form-group">
                                <Form.Label className="layout-label">Image</Form.Label>
                                <div className="layout-field">
                                    <input
                                        type="file"
                                        name='file'
                                        onChange={handleFileChange}
                                        title=" "
                                        className={imageSourceNetwork?.length > 0 ? "layout-field-fileinput-nohide" : "layout-field-fileinput"}
                                        accept="image/jpeg, image/png, image/jpg"
                                    />
                                    {isEdit && <p className="layout-field-filename" onClick={() => copylink(imageSourceNetwork)}>({ imageSourceNetwork?.length > 0 ? imageSourceNetwork?.split("/")[4] : '' })</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="changes">
                        <Button
                            className="save"
                            variant="primary"
                            // disabled={!isContentChanged}
                            onClick={() => {
                                tirggerOnSave();
                            }}
                        >
                            {!isEdit ? "Save" : "Update"}
                        </Button>
                        <Button
                            className="cancel"
                            variant="outline-secondary"
                            onClick={() => {
                                setPromptType('Cancel');
                                setPromptAction(true);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="device-preview" style={{maxHeight: 900, minHeight: 900}}>
                    <div className="device-preview-back" style={{maxHeight: 630, minHeight: 630, height: 630, borderRadius: 40, maxWidth: 300, minWidth: 300, width: 300}}> 
                    <div className="device-preview-mobile-frame" style={{
                            maxHeight: 650, minHeight: 650, height: 650,
                            maxWidth: 360, minWidth: 360, width: 360,
                            backgroundImage:`url(${Mb2})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            marginLeft: "-10px",
                            marginTop: "-10px",
                            position: 'relative'
                    }}>
                        <div className="frame-top-connection">
                            <FontAwesomeIcon icon={faSignal}/>
                            <FontAwesomeIcon icon={faBatteryFull}/>
                            <span>8 : 00</span>
                        </div>
                        <div className="device-preview-notification-card-parent-layer">
                            <div className="deivce-preview-card-top">
                                <div className="device-preview-card-flex">
                                    <img src={Logo} width={12} alt="No img"/>
                                    <span>Seekho</span>
                                    <b>·</b>
                                    <span>now</span>
                                    <span><FontAwesomeIcon icon={faBell}/></span>
                                </div>
                                <div>
                                    <div className="device-preview-icon-drop" onClick={() => setNotificationExpand(!notificationExpand)}>
                                        <FontAwesomeIcon icon={!notificationExpand ? faChevronDown : faChevronUp} size={8} />
                                    </div>
                                </div>
                            </div>
                            <div className="device-preview-notification-card" style={{background: color }}>
                                <div className="device-preview-notification-card-context">
                                    <div className="device-preview-notification-card-button-flex">
                                    {!notificationExpand ? <div className="notification-title" dangerouslySetInnerHTML={{ __html: title }}></div>
                                        : <div className="notification-title" style={{ wordWrap: 'unset', fontSize: 11, color: "black", fontWeight: 'bold'}}>{getTextExtract()[0].length > 25 ? getTextExtract()[0]?.substr(0, 25)?.concat('...') :  getTextExtract()[0]}</div>
                                    }
                                    {!notificationExpand ? <div className="notification-description" dangerouslySetInnerHTML={{ __html: description }}></div>
                                        : <div className="notification-description" style={{ wordWrap: 'unset', fontSize: 10, color: "grey"}}>{getTextExtract()[1].length > 50 ? getTextExtract()[1]?.substr(0, 50)?.concat('...') :  getTextExtract()[1]}</div>
                                    }
                                    </div>
                                    {selectedlayout === 'title_image_play' && <div>
                                        <button variant="outlined" className="notification-button">
                                            <FontAwesomeIcon style={{marginRight: 3, fontSize: 8}} icon={faPlay} />
                                            {ctaText}
                                        </button>
                                    </div>
                                    }
                                </div>
                                {selectedlayout !== 'default' && <div className="device-preview-notification-card-image">
                                    <img alt="IMG" src={imageSource} className="notification-thumbnail" style={{height : notificationExpand && 60}}/>
                                </div>}
                            </div>
                        </div>
                        {/* <div className="device-preview-notification-card" style={{background: color }}>
                            <div className="device-preview-notification-card-context">
                                <div className="notification-title" dangerouslySetInnerHTML={{ __html: title }}></div>
                                <div className="notification-description" dangerouslySetInnerHTML={{ __html: description }}></div>
                                {selectedlayout === 'title_image_play' && <div>
                                    <button variant="outlined" className="notification-button">
                                        <FontAwesomeIcon style={{marginRight: 3, fontSize: 12}} icon={faPlay} />
                                        {ctaText}
                                    </button>
                                </div>
                                }
                            </div>
                            {selectedlayout !== 'default' && <div className="device-preview-notification-card-image">
                                <img src={imageSource} className="notification-thumbnail"/>
                            </div>}
                        </div> */}
                    </div>
                    </div>
                </div>
              </div>
            : <div className="loader">
                    {isLoading && <Loader />}
              </div>
            }
        </div>
    );
}
