import { handleCheckEnvironment } from "./utils";

let gateway = handleCheckEnvironment() // if this is true, when we are running prod baseUrl else the staging one.
  ? "https://cms.seekhoapp.com"
  : "https://staging.seekhoapp.com";

const { REACT_APP_BACKEND_URL } = process.env;
if (
  !(REACT_APP_BACKEND_URL === null || REACT_APP_BACKEND_URL === undefined) &&
  REACT_APP_BACKEND_URL.trim().length !== 0
) {
  gateway = REACT_APP_BACKEND_URL;
}

export const apiGateway = gateway;
