import CommonDataActionTypes from "./common.types";

const INITIAL_STATE = {
    categories: [],
    activities: [],
    creators: [],
    isFetching: false,
    errorMessage: undefined,
};

const CommonDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CommonDataActionTypes.FETCH_COMMON_DATA_START:
            return {
                ...state,
                isFetching: true,
            };
        case CommonDataActionTypes.FETCH_COMMON_DATA_SUCCESS: {
            const { categories, activities, creators } = action.payload;
            return {
                ...state,
                categories,
                 creators
            };
        }

        // eslint-disable-next-line no-duplicate-case
        case CommonDataActionTypes.FETCH_COMMON_DATA_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default CommonDataReducer;
