import React from "react";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUploader from "../Common/FileUploader";
import RequiredStar from "../Common/RequiredStar";

const AddCategory = ({ categoryDetails, setCategoryDetails }) => {
  const commonInputFields = [
    {
      id: "category-title-input",
      type: "text",
      placeholder: "Category Name",
      label: "Title",
      isRequired: true,
      value: categoryDetails?.title,
      onChange: (e) => {
        setCategoryDetails({ ...categoryDetails, title: e.target.value });
      },
    },
    {
      id: "category-metaTitle-input",
      type: "text",
      placeholder: "ex: Create & Monetize Your Channel",
      label: "Meta Title",
      isRequired: true,
      value: categoryDetails?.metaTitle,
      onChange: (e) => {
        setCategoryDetails({
          ...categoryDetails,
          metaTitle: e.target.value,
        });
      },
    },
  ];

  return (
    <div>
      {commonInputFields.map((item) => {
        return (
          <div key={item?.id} className="first:!mt-0 mt-4">
            <label for={item?.id} className="block mb-2 text-sm font-medium">
              {item?.label} {item?.isRequired && <RequiredStar />}
            </label>
            <input
              type={item?.type}
              id={item?.id}
              placeholder={item?.placeholder}
              value={item?.value}
              onChange={item?.onChange}
              autoComplete="off"
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        );
      })}

      <div className="mt-4">
        <label for="message" className="block text-sm font-medium">
          Home Icon <RequiredStar />
        </label>
        <FileUploader
          displayIcon={<FontAwesomeIcon icon={faImage} />}
          displayText={categoryDetails?.image?.name || "Click here to upload"}
          accept=".png, .jpeg, .jpg"
          onChange={(e) => {
            const { files } = e.target;
            setCategoryDetails({ ...categoryDetails, image: files?.[0] });
          }}
        />
      </div>

      {categoryDetails?.image && (
        <div className="relative w-fit mx-auto mt-3">
          <img
            src={
              categoryDetails?.image?.name // this means the image is displayed from the local device
                ? URL.createObjectURL(categoryDetails?.image)
                : categoryDetails?.image // this is the uploaded image from the response
            }
            alt={categoryDetails?.image?.name}
            className="w-[50px] mx-auto "
          />
        </div>
      )}

      <div className="mt-4">
        <label for="message" className="block text-sm font-medium">
          Website Category Image <RequiredStar />
        </label>
        <FileUploader
          displayIcon={<FontAwesomeIcon icon={faImage} />}
          displayText={
            categoryDetails?.websiteCategoryImage?.name ||
            "Click here to upload"
          }
          accept=".png, .jpeg, .jpg"
          onChange={(e) => {
            const { files } = e.target;
            setCategoryDetails({
              ...categoryDetails,
              websiteCategoryImage: files?.[0],
            });
          }}
        />
      </div>

      {categoryDetails?.websiteCategoryImage && (
        <div className="relative w-fit mx-auto mt-3">
          <img
            src={
              categoryDetails?.websiteCategoryImage?.name // this means the image is displayed from the local device
                ? URL.createObjectURL(categoryDetails?.websiteCategoryImage)
                : categoryDetails?.websiteCategoryImage // this is the uploaded image from the response
            }
            alt={categoryDetails?.websiteCategoryImage?.name}
            className="w-[100px] mx-auto"
          />
        </div>
      )}

      <div className="mt-4">
        <label for="message" className="block mb-2 text-sm font-medium">
          Description <RequiredStar />
        </label>
        <textarea
          id="message"
          rows="10"
          value={categoryDetails?.description}
          onChange={(e) =>
            setCategoryDetails({
              ...categoryDetails,
              description: e.target.value,
            })
          }
          placeholder="Write your thoughts here..."
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
  );
};

export default AddCategory;
