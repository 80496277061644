import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import { withRouter } from "react-router-dom";
import { handleDefaultProfile, handleFormatDate } from "../../utils/utils";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { errorState } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./styles/BlogDetailed.css";
import LoadingSpinner from "../Common/LoadingSpinner";
import GuitarIllustration from "../Common/GuitarIllustration";
import useZustandStore from "../../zustandStore/useZustandStore";
import CustomModal from "../Common/CustomModal";
import AlertComponent from "../Alert-Messages/alert-component.component";

const BlogDetailed = ({ match }) => {
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openUnPublishModal, setOpenUnPublishModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorState);
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const blogSlug = match?.params?.blog_slug;
  const isBlogger = globalRoles?.blogger;
  const hasAccessToEdit = internalTeamAccessGlobalState || isBlogger;
  const status = data?.status;

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/?request_source=cms`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setIsLoading(false);
        setData(response?.data?.blog);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handlePublishBlog = async () => {
    try {
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/publish/`;
      const response = await axiosPrivate.post(url);
      const data = response?.data;
      if (data) {
        setOpenPublishModal(false);
        handleGetData();
        setErrorMessage({
          type: "success",
          error: "Blog Published Successfully!!",
        });
      }
    } catch (error) {
      setErrorMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleUnPublishBlog = async () => {
    try {
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/unpublish/`;
      const response = await axiosPrivate.post(url);
      const data = response?.data;
      if (data) {
        setOpenUnPublishModal(false);
        handleGetData();
        setErrorMessage({
          type: "success",
          error: "Blog Unpublished Successfully!!",
        });
      }
    } catch (error) {
      setErrorMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetData();
  }, [blogSlug]);

  return (
    <div>
      <div className=" !bg-white pt-3" id="HideScroll">
        {isError ? (
          <div className=" h-screen flex justify-center items-center">
            <GuitarIllustration
              displayText="Something Went Wrong!!!!!"
              className="w-[240px] mx-auto"
            />
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div className="h-screen flex justify-center items-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="!bg-white">
                <div className="flex justify-between items-center mt-2">
                  <div className="text-[14px] pl-2">
                    <span
                      className={`capitalize px-2 py-[2px] rounded-full !text-[12px] text-white ${
                        status === "live"
                          ? "bg-green-500"
                          : status === "draft"
                          ? "bg-orange-500"
                          : "bg-red-500"
                      }`}
                    >
                      {status}
                    </span>
                  </div>
                  {hasAccessToEdit && (
                    <div className="flex justify-end">
                      <Button
                        style={{
                          background: "#555190",
                          borderColor: "#555190",
                        }}
                        className="rounded-md w-[90px] mr-2 text-[13px]"
                        onClick={() => {
                          if (status === "live") setOpenUnPublishModal(true);
                          else setOpenPublishModal(true);
                        }}
                      >
                        {status === "live" ? "Unpublish" : "Publish"}
                      </Button>
                      <Button
                        style={{
                          background: "#555190",
                          borderColor: "#555190",
                        }}
                        className="rounded-md w-[90px] mr-5 text-[13px]"
                        onClick={() =>
                          history.push(
                            `/blogs/edit-blog/${data?.slug || blogSlug}`
                          )
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>

                <div className="mx-auto !w-full lg:!w-[50%] mt-4 font-sans">
                  {data?.image && (
                    <img
                      src={data?.image}
                      alt={data?.title}
                      className="!max-w-full object-contain mx-auto !max-h-[250px] lg:!max-h-[500px]"
                    />
                  )}

                  <div className="mt-2">
                    <h1 className="font-bold text-[42px] capitalize mt-4">
                      {data?.title}
                    </h1>

                    <div className="flex w-fit gap-x-4 mt-4">
                      <img
                        src={handleDefaultProfile({ userName: data?.author })}
                        alt={data?.author}
                        className="w-[40px] h-[40px] rounded-full"
                      />

                      <div>
                        <p className="m-0 text-[14px]">
                          {data?.author || "Seekho User"}
                        </p>
                        <p className="text-[12px] text-gray-500">
                          {handleFormatDate({
                            dateInString: data?.created_on,
                            showTime: false,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3" />

                  <div
                    dangerouslySetInnerHTML={{ __html: data?.content || "" }}
                    className="mt-5 HTMLContent pb-14 "
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <CustomModal
        show={openPublishModal || openUnPublishModal}
        title={openPublishModal ? "Publish Blog" : "Unpublish Blog"}
        handleConfirmationBtnText={openPublishModal ? "Publish" : "Unpublish"}
        children={
          <div>
            Are you sure you want to{" "}
            {openPublishModal ? "Publish" : "Unpublish"} the blog ?
          </div>
        }
        onHide={() => {
          setOpenPublishModal(false);
          setOpenUnPublishModal(false);
        }}
        handleConfirmation={() => {
          if (openPublishModal) {
            handlePublishBlog();
          } else {
            handleUnPublishBlog();
          }
        }}
      />

      {/* Alert */}
      <AlertComponent
        message={errorMessage?.error}
        type={errorMessage?.type}
        setAlertNotification={() =>
          setErrorMessage({ error: "", type: "failed" })
        }
      />
    </div>
  );
};

export default withRouter(BlogDetailed);
