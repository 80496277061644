import React from "react";
import QueryValueChip from "../Common/QueryValueChip";

const FiltersTags = ({
  history,
  searchParamCategoryFilter,
  searchQueryTag,
  searchParamSearchQuery,
  setSearchQuery,
  setSearchQueryTag,
  handleRemoveFilter,
  handleRemoveSearchQueryFromUrl,
}) => {
  return (
    <div>
      <div className="flex gap-x-2 flex-wrap">
        {searchParamCategoryFilter?.map((item) => {
          return (
            <>
              <div key={item}>
                <QueryValueChip
                  label={item?.split("-")?.[0]}
                  onDelete={() => {
                    handleRemoveFilter({
                      filterName: "category",
                      filterToRemove: item,
                    });
                  }}
                />
              </div>
            </>
          );
        })}

        {searchQueryTag || searchParamSearchQuery ? (
          <QueryValueChip
            label={searchQueryTag || searchParamSearchQuery}
            onDelete={() => {
              setSearchQuery("");
              setSearchQueryTag("");
              handleRemoveSearchQueryFromUrl({
                history,
                queryNames: ["query"],
              });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FiltersTags;
