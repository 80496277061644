import React, { useEffect, useState } from "react";
import "./profile.styles.css";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/index";
import CreatorDetails from "../../components/Creator-Details/creator-details.component";
import AlertComponent from "../../components/Alert-Messages/alert-component.component";

function ProfilePage() {
  const currentUser = useSelector(state => state?.user?.currentUser)
  const [profile, setProfile] = useState(null);
  const [errorMsg, setErrorMsg] = useState({error: "", type: 'success'});

  useEffect(() => {
    setProfile(currentUser);
  }, [currentUser]);

  return (
    <>
      {profile ? (
        <div className="profile">
          <AlertComponent
            message={errorMsg?.error} 
            type={errorMsg?.type} 
            setAlertNotification={() => setErrorMsg({error: '', type: 'failed'})}
          />
          <CreatorDetails
            profileDataOfUser={profile}
            isOnUserProfilePage={true}
          />
        </div>
      ) : (
        <div className="details-loader">
          <Loader />
        </div>
      )}
    </>
  );
}

export default ProfilePage;
