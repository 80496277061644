import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import FileUploader from "../Common/FileUploader";
import RequiredStar from "../Common/RequiredStar";

const CreateBlog = ({ blogDetails, setBlogDetails }) => {
  return (
    <div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900">
          Title <RequiredStar />
        </label>
        <input
          type="text"
          id="small-input"
          placeholder="Title of the blog"
          autoComplete="off"
          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-sm focus:outline-none"
          onChange={(e) => {
            const { value } = e.target;
            setBlogDetails({
              ...blogDetails,
              title: value,
            });
          }}
        />
      </div>

      <label className="block mb-2 text-sm font-medium text-gray-900 mt-3">
        Display Image
      </label>
      <FileUploader
        displayIcon={<FontAwesomeIcon icon={faImage} />}
        displayText="Click here to upload (PNG, JPG, JPEG)"
        accept=".png, .jpg, .jpeg"
        className="opacity-0 w-[200px]"
        onChange={(e) => {
          const { files } = e.target;
          setBlogDetails({
            ...blogDetails,
            displayImage: files?.[0],
          });
        }}
      />
      {blogDetails?.displayImage && (
        <img
          src={URL.createObjectURL(blogDetails?.displayImage)}
          alt={blogDetails?.title}
          className="w-[100px] object-contain mx-auto mt-2"
        />
      )}

      <div className="mt-3">
        <label className="block mb-2 text-sm font-medium text-gray-900">
          Blog Description <RequiredStar />
        </label>
        <textarea
          id="message"
          rows="8"
          placeholder="Write your thoughts here..."
          onChange={(e) => {
            const { value } = e.target;
            setBlogDetails({ ...blogDetails, description: value });
          }}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default CreateBlog;
