import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import SideNavigation from "../SideNavigation/side-navigation.component";
import useZustandStore from "../../zustandStore/useZustandStore";
import SessionExpiredModal from "./SessionExpiredModal";

const DashboardLayout = ({ children, currentUser }) => {
  const { showSideNavigationBar, openSessionExpiredModal } = useZustandStore();
  const history = useHistory();
  const isNotOnLoginPage = history?.location?.pathname !== "/";

  return (
    <div>
      {/* Session Expired Modal */}
      {openSessionExpiredModal && <SessionExpiredModal />}
      {isNotOnLoginPage && <Navbar currentUser={currentUser} />}
      <div className={` flex `}>
        {isNotOnLoginPage && (
          <div
            className={`hidden !bg-white h-screen pt-1 lg:block transition-all duration-300 ease-in-out transform ${
              showSideNavigationBar
                ? "opacity-100 translate-x-0 lg:!w-[16%]"
                : "opacity-0 -translate-x-full"
            }`}
          >
            {showSideNavigationBar && (
              <div>
                <SideNavigation currentUser={currentUser} />
              </div>
            )}
          </div>
        )}

        <div
          className={` w-[95%] mx-auto pb-20 max-h-screen !overflow-y-auto lg:!w-full ${
            isNotOnLoginPage ? "lg:px-4" : ""
          }`}
          id="ThinGrayScrollBar"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
