import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import VideoDetail from "../Video-Detail/video-detail.component";
import "./add-series-videos.styles.css";
import Loader from "../Loader";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Button } from "@mui/material";
import Upload from "../Upload/upload.component";
import VideoPlayerCustom from "../VideoPlayer/VideoPlayerCustom";
import { CMS_UNIT_V1_1 } from "../../utils/constants";

export default function AddSeriesVideos({
  creatorId,
  seriesSlug,
  seriesVideos,
  addVideoToSeries,
  categoryId
}) {
  const axiosPrivate = useAxiosPrivate();
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showUpload, setShowUpload] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoSource, setVideoSource] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const videoRef = useRef();
  
  useEffect(() => {
    addSeriesVideos(pageNumber)
  }, [pageNumber]);

  const addSeriesVideos = async (pageNumber) => {
    const url = `${apiGateway}${CMS_UNIT_V1_1}?page=${pageNumber}&creator_ids=${creatorId}&exclude_series_cus=true&status=live`;
    axiosPrivate.get(url)
    .then(({data, status}) => {
      if (data && status === 200) {
        setHasMoreVideos(data.has_more);
        setCreatorVideos((prev) => prev.concat(data.units));
      }
    })
    .catch((err) => {
      console.log("Error in add series videos............ ", err);
    });
  }

  const handleVideoModal = (videoSource, videoTitle) => {
    if (showVideo) {
      setShowVideo(false);
      return;
    }
    setVideoSource(videoSource);
    setVideoTitle(videoTitle);
    setShowVideo((prev) => !prev);
  };


  const handleAdd = (video) => {
    const videos = creatorVideos.filter(
      (creatorVideo) => creatorVideo.id !== video.id
    );
    setCreatorVideos([...videos]);
    addVideoToSeries(video);
  };

  const observer = useRef();
  const lastVideoElementRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          if (errorFetching) {
            setPageNumber(1);
          } else {
            setPageNumber((prevState) => prevState + 1); //delete the pageNumber from Redux state
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMoreVideos]
  );

  const toggleUpload = () => {
    setShowUpload((prev) => !prev);
  };

  return (
    <div className="add-series-videos">
      {!showUpload ? <div>
      <div className="flex-center">
        <Button
          variant="outlined"
          className="add-btn"
          onClick={() => toggleUpload()}
        >
          Upload Videos
        </Button>
      </div>
      {creatorVideos.length !== 0
        ? creatorVideos?.map((video, index) => {
          return (
            <div className="video-details" key={index}>
              <VideoDetail
                imageUrl={video.image}
                id={video.id}
                title={video?.title}
                category={video.category.name}
                handleVideoModal={handleVideoModal}
                videoUrl={
                  video?.status?.toLowerCase() === "live"
                    ? video.content.h264_media_url
                      ? video.content.h264_media_url
                      : video.content.url
                    : null
                }
              />
              <div className="spacer"></div>
              <div>
                <Button
                  variant="outline-primary"
                  className="add-btn"
                  onClick={() => handleAdd(video)}
                >
                  Add
                </Button>
              </div>
            </div>
          );
        })
      : "No Videos Found"}
      <div className="loader" ref={lastVideoElementRef}>
        {hasMoreVideos && <Loader />}
      </div>
      </div> :
        <Upload categoryId={categoryId} creatorId={creatorId} setCreatorVideos={setCreatorVideos} toggleUpload={toggleUpload} addSeriesVideos={addSeriesVideos}/>
      }
      {showVideo && (
        <Modal className="modalvideo" show={showVideo} centered>
          <Modal.Header closeButton onHide={handleVideoModal}>
            <div className="modal-video-title" style={{fontSize: 12}}>{videoTitle?.length > 60 ? videoTitle?.substr(0, 60)+ "..." : videoTitle}</div>
          </Modal.Header>
          <Modal.Body>
            <div className="videojs-center-video">
              {videoSource ? (
                <VideoPlayerCustom
                  src={
                    videoSource
                  }
                  videoRef={videoRef}
                  muted={false}
                  controls={true}
                  autoplay={true}
                />
              ) : (
                <div className="message">Source Not Found</div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
