import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";

function FilterButton({ onClick }) {
  return (
    <Button
      variant="outlined"
      startIcon={<FilterListIcon />}
      className="!text-[12px] !px-4 !py-1 border border-gray-500 !text-gray-500 hover:bg-gray-100"
      onClick={onClick}
    >
      Filters
    </Button>
  );
}

export default FilterButton;
