import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleCMSOpen } from "../utils/eventFunction";

const useCmsOpenEvent = () => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const location = useLocation();
  const userName = currentUser?.name;
  const userId = currentUser?.id;
  const screen = location.pathname;

  useEffect(() => {
    let intervalId;

    const startTracking = () => {
      // Set up the interval when the page is visible
      intervalId = setInterval(() => {
        handleCMSOpen({ userName, userId, screen });
      }, 5000);
    };

    const stopTracking = () => {
      // Clear the interval when the page is not visible
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    // Handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        startTracking();
      } else {
        stopTracking();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start tracking immediately if the page is visible
    if (document.visibilityState === "visible") {
      startTracking();
    }

    // Clean up on component unmount or when dependencies change
    return () => {
      stopTracking();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userName, userId, screen]);
};

export default useCmsOpenEvent;
