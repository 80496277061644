import React from "react";
import "./track.styles.css";
import TrackCreate from "../../components/Track/track-create.component";

function TrackPage() {
    return (
        <div>
            <TrackCreate />
        </div>
    );
}

export default TrackPage;
