import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { handleDefaultProfile } from "../../utils/utils";
import { useSelector } from "react-redux";
import { apiGateway } from "../../utils/config";
import { GET_USER, USER_V1 } from "../../utils/constants";
import { store } from "../../redux/store";
import {
  fetchCurrentUserFailure,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
} from "../../redux/user/user.action";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomModal from "./CustomModal";
import RequiredStar from "./RequiredStar";

// common edit profile component
const EditProfileModal = ({ openModal, setMessage, handleCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    gender: "",
    image: null,
  });
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const axiosPrivate = useAxiosPrivate();
  const userId = currentUser?.id;

  // input mapping
  const inputFields = [
    {
      id: 1,
      title: "First Name",
      placeholder: "First Name",
      value: userDetails?.firstName,
      onChange: (e) =>
        setUserDetails({ ...userDetails, firstName: e.target.value }),
    },
    {
      id: 2,
      title: "Last Name",
      placeholder: "Last Name",
      value: userDetails?.lastName,
      onChange: (e) =>
        setUserDetails({ ...userDetails, lastName: e.target.value }),
    },
    {
      id: 3,
      title: "Email",
      placeholder: "Email",
      value: userDetails?.email,
      onChange: (e) =>
        setUserDetails({ ...userDetails, email: e.target.value }),
    },
    {
      id: 4,
      title: "Status",
      placeholder: "Status",
      value: userDetails?.status,
      onChange: (e) =>
        setUserDetails({ ...userDetails, status: e.target.value }),
    },
  ];

  // Fetches fresh data after update
  const handleGetUpdatedUserData = async () => {
    try {
      store.dispatch(fetchCurrentUserStart());
      const url = `${apiGateway}${GET_USER}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        store.dispatch(fetchCurrentUserSuccess(response?.data?.user));
      }
    } catch (error) {
      store.dispatch(fetchCurrentUserFailure());
    }
  };

  // Update Profile API
  const handleUpdateProfile = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}${USER_V1}${userId}/update/`;
      const payload = new FormData();
      const name = `${userDetails?.firstName} ${userDetails?.lastName}`;
      payload.append("name", name);
      payload?.append("avatar", userDetails?.image);
      payload.append("status", userDetails?.status);
      payload.append("gender", userDetails?.gender);
      payload.append("email", userDetails?.email);
      const response = await axiosPrivate.post(url, payload);
      const data = response?.data;
      if (data) {
        setIsLoading(false);
        handleGetUpdatedUserData();
        handleCloseModal();
        setMessage({ type: "success", error: "Profile Updated Successfully" });
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Prefills the user data when modal opened
  useEffect(() => {
    if (openModal) {
      const name = currentUser?.name;
      const spaceIndex = name.indexOf(" ");
      const firstName = spaceIndex >= 0 ? name.slice(0, spaceIndex) : name;
      const lastName =
        spaceIndex >= 0 ? name.slice(spaceIndex + 1, name.length) : "";
      setUserDetails({
        firstName,
        lastName,
        email: currentUser?.email,
        gender: currentUser?.gender,
        status: currentUser?.status,
      });
    }
  }, [openModal]);

  return (
    <div>
      <CustomModal
        show={openModal}
        title="Update Profile"
        handleConfirmationBtnText="Update"
        isLoading={isLoading}
        onHide={handleCloseModal}
        handleConfirmation={handleUpdateProfile}
      >
        <div>
          <div className="w-fit mx-auto">
            <div className="relative w-[96px] flex justify-center !rounded-[50%] mx-auto md:!m-0">
              <img
                src={
                  userDetails?.image
                    ? URL.createObjectURL(userDetails?.image)
                    : currentUser?.avatar ||
                      handleDefaultProfile({
                        userName: currentUser?.name || "Seekho User",
                      })
                }
                alt="User"
                className="w-[100px] h-[100px] rounded-[50%] object-contain"
              />

              <div className="relative">
                <input
                  id="fileInput"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="border w-[20px] absolute right-0 bottom-0 opacity-0"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, image: e.target.files[0] })
                  }
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => document.getElementById("fileInput")?.click()}
                  className="absolute bottom-2 right-0 p-[2px] !bg-white rounded-full cursor-pointer text-[16px]"
                />
              </div>
            </div>
          </div>

          {inputFields?.map((item) => {
            return (
              <div key={item?.id} className="mt-3">
                <label
                  for="small-input"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {item?.title} <RequiredStar />
                </label>
                {item?.title === "Status" ? (
                  <textarea
                    id="small-textarea"
                    rows="6"
                    placeholder={item?.placeholder}
                    value={item?.value}
                    onChange={item?.onChange}
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:outline-none"
                  />
                ) : (
                  <input
                    type="text"
                    placeholder={item?.placeholder}
                    value={item?.value}
                    onChange={item?.onChange}
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:outline-none"
                  />
                )}
              </div>
            );
          })}

          <div className="mt-3">
            <label
              htmlFor="gender"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Gender
            </label>

            <select
              id="gender"
              value={userDetails?.gender}
              onChange={(e) =>
                setUserDetails({ ...userDetails, gender: e.target.value })
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option value={null}>Select</option>
              <option value="m">Male</option>
              <option value="f">Female</option>
              <option value="t">Others</option>
            </select>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default EditProfileModal;
