import events from "../events";

const handleCMSOpen = ({ userId, userName, screen }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: userId,
      user_name: userName,
      screen,
      date_time: new Date(),
    };
    window.dataLayer.push({ event: events.CMS_OPEN, ...eventParams });
  }
};

const handleClickedOnGraphEvent = ({ userId, userName }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: userId,
      user_name: userName,
      date_time: new Date(),
    };
    window.dataLayer.push({ event: events.CLICKED_ON_GRAPH, ...eventParams });
  }
};

export { handleCMSOpen, handleClickedOnGraphEvent };
