import React, { useState, useCallback, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { styles } from "./image-editor.styles.js";
import "./image-editor.styles.css";
import { Modal } from "react-bootstrap";
import seekhoLogo from "./seekho-white-logo.png";
import {
  Button,
  Checkbox,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import { withStyles } from "@mui/styles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhotoOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import GradientOutlinedIcon from "@mui/icons-material/GradientOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import Brightness5OutlinedIcon from "@mui/icons-material/Brightness5Outlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ImageEditor = ({
  classes,
  done,
  close,
  image,
  imageUrl,
  creatorColor,
  setCreatorColor,
  creatorName = "CREATOR NAME",
  seriesTitle = "SERIES TITLE",
  addTitleImage,
  setAddTitleImage,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [enableBrightness, setEnableBrightness] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(image);
  const [srcImage, setSrcImage] = useState(null);
  const [gradientColor, setGradientColor] = useState({ r: 0, g: 0, b: 0 });
  const [logoPresent, setLogoPresent] = useState(false);
  const [applyGradient, setApplyGradient] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageUrl && !image) {
      const imageData = new FormData();
      imageData.append("imageUrl", imageUrl);
      const url =
        process.env.NODE_ENV === "development" ? "http://localhost:80" : "";
      fetch(url + "/image-data", {
        method: "POST",
        body: imageData,
      })
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          urltoFile(
            `data:image/jpeg;base64,${data.b64}`,
            "hello.jpeg",
            "image/jpeg"
          ).then(function (file) {
            setSrcImage(URL.createObjectURL(file));
          });
        })
        .catch((error) => {
          console.error("Error while image upload");
        });
    }
  }, [imageUrl]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        srcImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setSrcImage(null);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const save = async () => {
    const dataUrl = await htmlToImage.toJpeg(imageRef.current);
    const file = await urltoFile(dataUrl, "1.jpeg", "image/jpeg");
    return file;
  };

  const download = async () => {
    const dataUrl = await htmlToImage.toPng(imageRef.current);
    const link = document?.createElement("a");
    link.download = "seekho-series-thumbnail.png";
    link.href = dataUrl;
    link.click();
  };

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      })
      .catch((error) => {
        console.error("Error while converting url to file");
      });
  }

  return (
    <div className="image-editor">
      <div className="editor-options-section">
        <div className="icon-background-editor">
          <AddAPhotoIcon />
          <input
            type="file"
            accept=".png, .jpeg, .jpg"
            className="input-hide-editor"
            // hidden
            value={null}
            onChange={(e) => {
              setSrcImage(URL.createObjectURL(e.target.files[0]));
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <span className="icon-background-editor-title">Add</span>
        </div>
        {/* <div className="icon-background-editor">
          <CropOutlinedIcon />
          <span className="icon-background-editor-title">Crop</span>
        </div> */}
        {/* <div
          className="icon-background-editor"
          style={{ background: addTitleImage && "rgb(223, 223, 223)" }}
          onClick={(e) => {
            setAddTitleImage((prev) => !prev);
          }}
        >
          <ContactMailOutlinedIcon />
          <span className="icon-background-editor-title">
            Add title & Creator
          </span>
        </div> */}
        {/* <div className="icon-background-editor">
          <FormatColorFillOutlinedIcon />
          <span className="icon-background-editor-title">
            Creator background
          </span>
          <input
            type="color"
            className="input-color-hide"
            value={creatorColor}
            disabled={!addTitleImage}
            onChange={(e) => setCreatorColor(e.target.value)}
          />
        </div> */}
        {/* <div
          className="icon-background-editor"
          style={{ background: applyGradient && "rgb(223, 223, 223)" }}
          onClick={() => setApplyGradient(!applyGradient)}
        >
          <GradientOutlinedIcon />
          <span className="icon-background-editor-title">Gradient</span>
        </div> */}
        {/* <div className="icon-background-editor">
          <ColorLensOutlinedIcon />
          <span className="icon-background-editor-title">Gradient Colour</span>
          <input
            type="color"
            className="input-color-hide"
            disabled={!applyGradient}
            onChange={(e) => setGradientColor(hexToRgb(e.target.value))}
          />
        </div> */}
        {/* <div
          className="icon-background-editor"
          style={{ background: logoPresent && "rgb(223, 223, 223)" }}
          onClick={() => setLogoPresent((prev) => !prev)}
        >
          <InsertPhotoOutlinedIcon />
          <span className="icon-background-editor-title">Seekho logo</span>
        </div> */}
        {/* <div
          className="icon-background-editor"
          style={{ background: enableBrightness && "rgb(223, 223, 223)" }}
          onClick={() => setEnableBrightness(!enableBrightness)}
        >
          <Brightness5OutlinedIcon />
          <span className="icon-background-editor-title">Brightness</span>
        </div> */}
      </div>
      <div className="editor-image-section">
        <div className="editor-image-section-top-action">
          <Button
            variant="contained"
            color="primary"
            disabled={!croppedImage}
            className="button-cta-editor"
            onClick={async () => {
              if (done) {
                const file = await save();
                done(file);
                close();
              }
            }}
          >
            Done
          </Button>
          <Button
            disabled={!croppedImage}
            variant="contained"
            className="button-cta-editor"
            onClick={download}
          >
            Download
          </Button>
        </div>
        <div className="editor-image-section-image-space">
          {srcImage ? (
            <Cropper
              image={srcImage}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={332 / 470}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              // cropSize={{ width: 332, height: 470 }}
            />
          ) : (
            <div className="preview-image-editor">
              <div className="download-div">
                {logoPresent && (
                  <div className="logo-image">
                    <img width={14} height={21} src={seekhoLogo} alt=""></img>
                  </div>
                )}
                <div className="main-image">
                  <img
                    style={{ filter: `brightness(${brightness})` }}
                    width={332}
                    height={470}
                    src={croppedImage}
                    alt=""
                  ></img>
                </div>
                {applyGradient && (
                  <div
                    className="gradient"
                    style={{
                      backgroundImage: `
                                    linear-gradient(to top, rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},1) ,rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.9), rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.8),  rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.5),rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0))`,
                    }}
                  ></div>
                )}
                {addTitleImage && (
                  <div className="series-details">
                    <div className="series-title">{seriesTitle}</div>
                    <div className="creator-details">
                      <span style={{ backgroundColor: creatorColor }}>
                        {creatorName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="editor-image-section-image-crop">
          <div className="editor-image-section-image-slider">
            {enableBrightness && (
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Brightness
                </Typography>
                <Slider
                  value={brightness}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, brightness) => setBrightness(brightness)}
                />
              </div>
            )}
            {srcImage && (
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}
                >
                  Done
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="download-preview">
        {croppedImage && (
          <div className="download-image">
            <div className="download-div" ref={imageRef}>
              {logoPresent && (
                <div className="logo-image">
                  <img width={28} height={42} src={seekhoLogo} alt=""></img>
                </div>
              )}
              <div className="main-image">
                {" "}
                <img
                  style={{ filter: `brightness(${brightness})` }}
                  width={664}
                  height={940}
                  src={croppedImage}
                  alt=""
                ></img>
              </div>
              {applyGradient && (
                <div
                  className="gradient"
                  style={{
                    backgroundImage: `
                                        linear-gradient(to top, rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},1) ,rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.9), rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.8),  rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0.5),rgba(${gradientColor.r},${gradientColor.g},${gradientColor.b},0))`,
                  }}
                ></div>
              )}
            </div>
            {addTitleImage && (
              <div className="series-details">
                <div className="series-title">{seriesTitle}</div>
                <div
                  className="creator-details"
                  style={{ backgroundColor: creatorColor }}
                >
                  {creatorName}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledImageEditor = withStyles(styles)(ImageEditor);
export default StyledImageEditor;
