import UserActionTypes from "./user.types";
import { fetchCreatorsStartAsync } from "../creator/creator.action";
import { apiGateway } from "../../utils/config";
import { GET_USER, USER_LOGOUT } from "../../utils/constants";
import { store } from "../store";
import Cookies from "js-cookie";
import { axiosPrivate } from "../../api/axiosCustom";
import { TEAM_ROLES } from "../../utils/constants/Team";

export const setCurrentUser = (user) => {
  return {
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user,
  };
};

export const fetchCurrentUserStart = () => ({
  type: UserActionTypes.FETCH_USER_START,
});

export const fetchCurrentUserSuccess = (idToken) => ({
  type: UserActionTypes.FETCH_USER_SUCCESS,
  payload: idToken,
});

export const fetchCurrentUserFailure = (errorMessage) => ({
  type: UserActionTypes.FETCH_USER_FAILURE,
  payload: errorMessage,
});

export const fetchUserStartAsync = async (
  axiosPrivate,
  setErrorMessage,
  setErrorType,
  history,
  cookies,
  setAuth,
  isUserInvited
) => {
  store.dispatch(fetchCurrentUserStart());
  await axiosPrivate
    .get(apiGateway + GET_USER)
    .then(({ data, status }) => {
      if (status === 200) {
        const isBlogger = data?.user?.roles?.includes(TEAM_ROLES.blogger);
        const isEditor = data?.user?.roles?.includes(TEAM_ROLES.editor);
        const isViewer = data?.user?.roles?.includes(TEAM_ROLES.viewer);
        const isSuperAdmin = data?.user?.roles?.includes(TEAM_ROLES.superAdmin);

        const isAdmin =
          data?.user?.roles?.includes(TEAM_ROLES.admin) || data?.user?.is_admin;
        const isCreator =
          data?.user?.roles?.includes(TEAM_ROLES.creator) ||
          data?.user?.is_quality_approved;

        const internalTeamAccess =
          isAdmin || isSuperAdmin || isViewer || isEditor;

        const allowLoginIf =
          isAdmin ||
          isCreator ||
          isBlogger ||
          isSuperAdmin ||
          isEditor ||
          isViewer;

        if (allowLoginIf) {
          cookies.set("loggedin_seekho", true, { expires: 7 });
          store.dispatch(fetchCurrentUserSuccess(data.user));

          if (isAdmin) {
            store.dispatch(
              fetchCreatorsStartAsync(
                axiosPrivate,
                1,
                "",
                null,
                "",
                "",
                () => {}
              )
            );
          }

          setErrorMessage(`Wow! You logged in successfully.`);
          setErrorType("success");
          setAuth(data.user);

          if (isUserInvited) {
            history.push("/profile");
          } else {
            if (internalTeamAccess) {
              history.push("/shows");
            } else if (isBlogger) {
              history.push("/blogs");
            } else {
              history.push("/dashboard");
            }
          }
        } else {
          localStorage.clear();
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
          setErrorMessage(
            `Sorry! You are not authorized to perform this action`
          );
          setErrorType("failed");
          history.push("/");
        }
      }
    })
    .catch(({ response }) => {
      setErrorMessage(
        response?.data
          ? response?.data?.message
          : "Unable to fetch your profile"
      );
      setErrorType("failed");
    });
};

export const logout = async (
  setCurrentUser,
  setHideLogut,
  setIsLoading,
  type
) => {
  try {
    // Sign out
    if (type === "refresh_token") {
      setCurrentUser(null);
      setHideLogut(false);
      localStorage.clear();
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        Cookies.remove(cookieName);
      });
      setIsLoading(false);
      window.location.reload();
    } else {
      await axiosPrivate
        .post(apiGateway + USER_LOGOUT)
        .then(({ data, status }) => {
          if (status === 200) {
            setCurrentUser(null);
            setHideLogut(false);
            localStorage.clear();
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            setIsLoading(false);
            window.location.reload();
          }
        })
        .catch(({ response }) => {
          setCurrentUser(null);
          setHideLogut(false);
          localStorage.clear();
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
          setIsLoading(false);
          window.location.reload();
        });
    }
  } catch (error) {
    setIsLoading(false);
    console.log("Sign-out error:", error);
  }
};
