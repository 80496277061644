import React, { useState } from "react";
import FeedbackWithProfile from "../Common/FeedbackWithProfile";

const AllFeedback = ({ data }) => {
  const [expandedFeedback, setExpandedFeedback] = useState({});

  // Function to toggle the read more state for each feedback
  const handleToggleReadMore = (index) => {
    setExpandedFeedback((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <div className="mt-3">
        {data?.map((item, index) => {
          return (
            <div key={`${item?.id}${index}`} className="mt-4 border-b pb-2">
              <FeedbackWithProfile
                index={index}
                review={item?.review}
                updatedOn={item?.updated_on}
                avatar={item?.profile?.avatar}
                userName={item?.profile?.name}
                expandedFeedback={expandedFeedback}
                handleToggleReadMore={() => handleToggleReadMore(index)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllFeedback;
