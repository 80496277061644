import React from "react";
import { withRouter } from "react-router-dom";

function ShowDashboard({ match, history }) {
  const showId = match?.params?.show_id;

  return (
    <iframe
      title="Embedded Website"
      src={`https://analytics.seekho.in/public/dashboards/MukGbrT1AERAYUYFaaGgDY5gT8oZ4uqZ9ZBgkvQi?org_slug=default&p_show_id=${showId}&refresh=300&fullscreen`}
      width="100%"
      style={{
        height: "98vh",
      }}
      frameBorder="0"
    />
  );
}

export default withRouter(ShowDashboard);
