import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LinearProgress, Pagination } from "@mui/material";
import { handleAppendQueryToUrl } from "../../utils/utils";
import { apiGateway } from "../../utils/config";
import { errorState } from "../../utils/constants";
import {
  teamIsLoadingState,
  userDetailsState,
} from "../../utils/constants/Team";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../Common/CustomModal";
import TeamTable from "./TeamTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import AddOrEditTeamMember from "./AddOrEditTeamMember";
import NoDataFound from "../Common/NoDataFound";

const TeamListing = () => {
  const [profileId, setProfileId] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [isClickedEdit, setIsClickedEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);
  const [allUsers, setAllUsers] = useState({ nPages: 0, data: [] });
  const [isLoading, setIsLoading] = useState(teamIsLoadingState);
  const [errorMessage, setErrorMessage] = useState(errorState);
  const [userDetails, setUserDetails] = useState(userDetailsState);
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);

  // Fetch all the users
  const handleGetAllUsers = async ({ pageArg }) => {
    try {
      setIsLoading({ ...isLoading, getUsers: true });
      const url = `${apiGateway}/api/v1/team/members/?page=${pageArg}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        const nPages = response?.data?.n_pages;
        const users = response?.data?.team_members;
        setAllUsers({ nPages, data: users });
        setIsLoading({ ...isLoading, getUsers: false });
      }
    } catch (error) {
      setIsApiError(true);
      setIsLoading({ ...isLoading, getUsers: false });
      setErrorMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // function to open the Create Modal
  const handleOpenCreateModal = () => {
    setSearchRole("");
    setIsClickedEdit(false);
    setOpenAddOrEditModal(true);
    setUserDetails(userDetailsState);
  };

  // Creating a user API CAll with validations
  const handleCreateUser = async () => {
    const missingFields =
      !userDetails?.name ||
      !userDetails?.phoneNumber ||
      userDetails?.roles?.length === 0;
    if (missingFields) {
      setErrorMessage({
        error: `Some Fields are required`,
        type: "error",
      });
    } else {
      try {
        setIsLoading({ ...isLoading, createUser: true });
        const url = `${apiGateway}/api/v1/team/invite/`;
        const payload = {
          team: "Seekho",
          name: userDetails?.name,
          phone_number: userDetails?.phoneNumber,
          roles: userDetails?.roles,
        };
        const response = await axiosPrivate.post(url, payload);
        if (response?.data) {
          setIsLoading({ ...isLoading, createUser: false });
          setOpenAddOrEditModal(false);
          setErrorMessage({
            type: "success",
            error: "User Created Successfully",
          });
        }
      } catch (error) {
        setIsLoading({ ...isLoading, createUser: false });
        setErrorMessage({
          type: "error",
          error: error?.response?.data?.error_message || error?.message,
        });
      }
    }
  };

  // Function invoked when click on edit
  const handleClickedEdit = ({ item }) => {
    setIsClickedEdit(true);
    setSearchRole("");
    setOpenAddOrEditModal(true);
    setProfileId(item?.id);
    setUserDetails({
      name: item?.name,
      phoneNumber: item?.phone,
      roles: item?.roles,
    });
  };

  //  Functions updates the user role API Call
  const handleUpdateUser = async () => {
    try {
      setIsLoading({ ...isLoading, updateUser: true });
      const url = `${apiGateway}/api/v1/team/update_roles/`;
      const payload = {
        team: "Seekho",
        profile_id: profileId,
        roles: userDetails?.roles,
      };
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        handleGetAllUsers({ pageArg: searchParamPage });
        setOpenAddOrEditModal(false);
        setIsLoading({ ...isLoading, updateUser: false });
        setErrorMessage({
          type: "success",
          error: "User Updated Successfully",
        });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, updateUser: false });
      setErrorMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function invoked when clicked on delete, opens the delete modal
  const handleClickedDelete = ({ id }) => {
    setOpenDeleteModal(true);
    setProfileId(id);
  };

  // Function deletes the user by API Call
  const handleDeleteUserAPICall = async () => {
    try {
      setIsLoading({ ...isLoading, deleteUser: true });
      const url = `${apiGateway}/api/v1/team/${profileId}/delete/`;
      const response = await axiosPrivate.delete(url);
      if (response?.data) {
        // Filtering out locally, just to save 1 api call, handleGetAllUsers
        const filterDeletedUser = allUsers?.data?.filter(
          (item) => item?.id !== profileId
        );
        setAllUsers({ ...allUsers, data: filterDeletedUser });
        setIsLoading({ ...isLoading, deleteUser: false });
        setOpenDeleteModal(false);
        setErrorMessage({
          type: "success",
          error: "User Deleted Successfully!!",
        });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, deleteUser: false });
      setErrorMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetAllUsers({ pageArg: searchParamPage });
  }, [searchParamPage]);

  return (
    <div>
      {isLoading.getUsers && <LinearProgress color="inherit" />}
      <button className="cta-button-create" onClick={handleOpenCreateModal}>
        <AddIcon fontSize="large" className="icon-plus-cta" />
        <span>Add User</span>
      </button>

      <h6 className="!text-[20px] mt-2 font-medium">Team</h6>

      {isApiError ? (
        <div className="flex justify-center items-center h-screen">
          <NoDataFound
            className="w-[400px] mx-auto"
            displayText="Oops! Something Went Wrong!!!!"
          />
        </div>
      ) : (
        <div
          className="!relative !overflow-x-auto rounded-md min-h-screen bg-white mt-2 border-t"
          id="ThinGrayScrollBar"
        >
          <TeamTable
            data={allUsers?.data}
            handleClickedEdit={handleClickedEdit}
            handleClickedDelete={handleClickedDelete}
          />
          <Pagination
            count={allUsers?.nPages}
            page={searchParamPage || pageNumber}
            onChange={(e, val) => {
              setPageNumber(val);
              handleAppendQueryToUrl({
                history,
                queryName: "page",
                queryValue: val,
              });
            }}
            shape="rounded"
            className="!w-fit !mx-auto py-3"
          />
        </div>
      )}

      {/* // Add Or Edit User */}
      <AddOrEditTeamMember
        searchRole={searchRole}
        openAddOrEditModal={openAddOrEditModal}
        userDetails={userDetails}
        isClickedEdit={isClickedEdit}
        isLoading={isLoading?.createUser || isLoading.updateUser}
        setUserDetails={setUserDetails}
        setSearchRole={setSearchRole}
        onHide={() => {
          setOpenAddOrEditModal(false);
          setIsClickedEdit(false);
        }}
        handleConfirmation={() => {
          isClickedEdit ? handleUpdateUser() : handleCreateUser();
        }}
      />

      {/* // Delete User */}
      <CustomModal
        title="Delete User"
        show={openDeleteModal}
        isLoading={isLoading.deleteUser}
        onHide={() => setOpenDeleteModal(false)}
        handleConfirmation={handleDeleteUserAPICall}
      >
        <div>Are you sure you want to delete the user?</div>
      </CustomModal>

      {/* Alert */}
      <AlertComponent
        message={errorMessage?.error}
        type={errorMessage?.type}
        setAlertNotification={() =>
          setErrorMessage({ error: "", type: "failed" })
        }
      />
    </div>
  );
};

export default TeamListing;
