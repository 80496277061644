import React from "react";
import "./track.styles.css";
import TrackList from "../../components/Track/track-list.component";

function TrackPageList() {
  return (
    <div className="mt-3">
      <TrackList />
    </div>
  );
}

export default TrackPageList;
