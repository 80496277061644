import React from "react";
import { Chip } from "@mui/material";
import { handleShowTableHeadline } from "../../utils/constants/Shows";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import {
  handleSeriesStatusColor,
  seriesStatus,
} from "../../utils/constants/VideoListing";
import placeholder from "../../assests/images/placeholder1.png";
import CustomAnchor from "../Common/CustomAnchor";

const ShowListTable = ({
  data,
  isEditor,
  hasAccessToActions,
  handleDeleteShow,
  handleToggleShowLiveOrDraft,
  hasAccessToCreatorTableField,
}) => {
  const history = useHistory();

  const handleRouteToShow = ({ showSlug }) => {
    history.push(`/shows/${showSlug}`);
  };

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 !bg-gray-100 !border-b">
          <tr>
            {handleShowTableHeadline({
              hasAccessToActions,
              hasAccessToCreatorTableField,
            })?.map((item) => {
              return (
                item?.display && (
                  <th
                    scope="col"
                    className={`px-3 py-3 whitespace-nowrap !border !text-[12px] text-gray-700 !bg-gray-100 z-[999] !font-medium `}
                    key={item.id}
                  >
                    {item?.title}
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            const isLive = item?.status === seriesStatus.LIVE;
            const isDraft = item?.status === seriesStatus.DRAFT;
            const isDeleted = item?.status === seriesStatus.DELETED;
            return (
              <tr
                key={item?.id}
                className="last:!border-none border-b text-black !font-normal text-[12px]"
              >
                <td className="px-3 py-3 min-w-[200px] lg:w-[200px]">
                  <div
                    className="flex gap-x-1 items-center cursor-pointer"
                    onClick={() => handleRouteToShow({ showSlug: item?.slug })}
                  >
                    <img
                      src={item?.image || placeholder}
                      alt={item?.title}
                      className="w-[50px] h-[50px] rounded"
                    />

                    <img
                      src={item?.banner_image || placeholder}
                      alt={item?.title}
                      className="w-[84px] h-[50px] rounded"
                    />
                  </div>
                </td>

                <td className="px-3 py-3 text-[14px] whitespace-nowrap cursor-pointer tracking-wide lg:whitespace-normal lg:!max-w-[180px]">
                  <CustomAnchor
                    displayText={item?.title}
                    href={`/#/shows/${item?.slug}`}
                  />
                </td>

                <td className="px-3 py-3 text-[14px] text-gray-500">
                  {`#${item?.id}`}
                </td>

                {hasAccessToCreatorTableField && (
                  <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                    <CustomAnchor
                      displayText={item?.creator?.name}
                      href={`/#/creators/${item?.creator?.id}`}
                    />
                  </td>
                )}

                <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                  {item?.n_series}
                </td>

                <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                  {item?.category?.title}
                </td>

                <td className={` px-3 py-3 text-[14px] capitalize `}>
                  <Chip
                    label={item?.status}
                    className={` ${handleSeriesStatusColor({
                      status: item?.status,
                    })} w-fit px-3 `}
                  />
                </td>

                {hasAccessToActions ? (
                  <td className="px-3 py-2 text-[14px] capitalize">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-action">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="xs"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {!isDraft && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleToggleShowLiveOrDraft({ item })
                            }
                          >
                            Move To Draft
                          </Dropdown.Item>
                        )}

                        {isLive || isDeleted || isEditor ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleToggleShowLiveOrDraft({ item })
                            }
                          >
                            Make Live
                          </Dropdown.Item>
                        )}

                        {!isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() => handleDeleteShow({ item })}
                          >
                            Delete Show
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowListTable;
