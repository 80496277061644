import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import "./show-details.styles.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../components/Loader/index";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import { GET_SHOW_ACTION_V1, SHOW_V1 } from "../../utils/constants";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateShow from "../Create-Show/create-show.component";
import useZustandStore from "../../zustandStore/useZustandStore";
import VideoListing from "../VideoListing";
import ShowRequiredDetails from "./ShowRequiredDetails";

function ShowDetails({ history, match }) {
  var showSlug = match.params.show_slug;
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const axiosPrivate = useAxiosPrivate();
  const [show, setShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const { globalRoles, internalTeamAccessGlobalState } = useZustandStore();
  const isAdmin = globalRoles?.admin;
  const isSuperAdmin = globalRoles?.superAdmin;
  const isEditor = globalRoles?.editor;
  const hasAccessToActions = internalTeamAccessGlobalState;

  const fetchShowData = (showSlug) => {
    if (!showSlug || showSlug == "" || showSlug == undefined) {
      return;
    } else {
      setIsLoading((prev) => true);
      let url = `${apiGateway}${SHOW_V1}${showSlug}/`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (
            status !== 200 ||
            (data?.error_message && Object.keys(data?.error_message).length > 0)
          ) {
            setErrorMsg({
              type: "failed",
              error: data?.error_message ?? "Something went wrong",
            });
          } else {
            setShow(data?.show_data);
          }
          setIsLoading((prev) => false);
        })
        .catch(({ response }) => {
          if (response?.status == 404) {
            setErrorMsg({ type: "failed", error: "Url not found" });
          } else {
            setErrorMsg({
              type: "failed",
              error: response?.data
                ? response?.data?.error_message
                : "Something went wrong",
            });
          }
          setIsLoading((prev) => false);
        });
    }
  };

  useEffect(() => {
    fetchShowData(showSlug);
  }, [showSlug]);

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    // setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          // setIsLoading(false);
          fetchShowData(showSlug);
          setErrorMsg({ type: "success", error: "Track is status changed" });
        }
      })
      .catch(({ response }) => {
        console.log(response);
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "error",
            error: response?.data?.error_message || response?.data?.message,
          });
        }
        // setIsLoading(false);
      });
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created) => {
    if (isDialogCreate && show_created) {
      fetchShowData(showSlug);
    }
    setIsDialogCreate((prev) => !prev);
  };

  return (
    <>
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: "failed" })
          }
        />
        {!isLoading ? (
          show ? (
            <div>
              <ShowRequiredDetails
                data={show}
                isAdmin={isAdmin}
                isEditor={isEditor}
                isSuperAdmin={isSuperAdmin}
                hasAccessToActions={hasAccessToActions}
                setDialogStatus={setDialogStatus}
                toggleCreate={toggleCreate}
              />

              <div className="mt-2">
                <VideoListing
                  isShowUploadCta={false}
                  isOnShowDetailsPage={{ value: true, showSlug }}
                />
              </div>
            </div>
          ) : (
            <div className="center-loader-screen">
              Sorry we failed to load the data
            </div>
          )
        ) : (
          <div className="center-loader-screen">
            <Loader />
          </div>
        )}
      </div>
      <Modal show={dialogStatus?.open} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setDialogStatus({ open: false });
          }}
        >
          {show?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{dialogStatus.msg}</b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                toggleStatus(show?.slug, dialogStatus?.type);
              }}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setDialogStatus({ open: false });
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* CREATE FORM MODAL */}
      <Modal show={isDialogCreate} centered>
        <Modal.Header closeButton onHide={toggleCreate}>
          <div className="modal-video-title">Update Show</div>
        </Modal.Header>
        <Modal.Body>
          <CreateShow toggleCreate={toggleCreate} show_data={show} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withRouter(ShowDetails);
