import React from "react";

const LatestVideosSkeleton = () => {
  return (
    <div>
      <div className="flex gap-x-4 mt-2 flex-col lg:flex-row">
        {[...Array(3)].map((_, index) => {
          return (
            <div
              key={index}
              className="animate-pulse !bg-white px-3 py-3 rounded-md shadow-sm mb-4 lg:w-[34%]"
            >
              <div className="flex gap-x-3">
                {/* Skeleton Image */}
                <div className="w-[110px] h-[128px] bg-gray-300 rounded" />

                {/* Skeleton Series details */}
                <div className="w-full">
                  <div className="h-[75px]">
                    <div className="flex justify-between items-start">
                      <div className="w-[210px] h-[40px] bg-gray-300 rounded"></div>
                      <div className="w-[50px] h-[20px] bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex gap-x-1 mt-1">
                      <div className="w-[80px] h-[12px] bg-gray-300 rounded"></div>{" "}
                      •
                      <div className="w-[50px] h-[12px] bg-gray-300 rounded"></div>{" "}
                      •
                      <div className="w-[40px] h-[12px] bg-gray-300 rounded"></div>
                    </div>
                  </div>

                  <div className="text-[10px]">
                    <div className="flex justify-between mt-[2px]">
                      <div className="w-[80px] h-[12px] bg-gray-300 rounded"></div>
                      <div className="w-[40px] h-[12px] bg-gray-300 rounded"></div>
                    </div>

                    <div className="flex justify-between mt-[2px]">
                      <div className="w-[100px] h-[12px] bg-gray-300 rounded"></div>
                      <div className="w-[50px] h-[12px] bg-gray-300 rounded"></div>
                    </div>

                    <div className="flex justify-between mt-[2px]">
                      <div className="w-[60px] h-[12px] bg-gray-300 rounded"></div>
                      <div className="w-[40px] h-[12px] bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Skeleton Feedback */}
              <div className="mt-4 pb-5">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="flex gap-x-3 py-2">
                    <div className="w-[31px] h-[31px] bg-gray-300 rounded-full" />
                    <div className="w-full">
                      <div className="w-[150px] h-[12px] bg-gray-300 rounded mb-2"></div>
                      <div className="w-full h-[24px] bg-gray-300 rounded"></div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-fit mx-auto mt-2">
                <div className="w-[150px] h-[24px] bg-gray-300 rounded-xl"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestVideosSkeleton;
