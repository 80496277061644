import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";

export default function CustomBreadcrumbs() {
  const location = useLocation();
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    let path = event.currentTarget.getAttribute("data-path");
    // changing its path to videos because video-detailed for creator without video-slug shows blank page
    if (path.includes("/videos-detail")) {
      path = `/videos`;
    }
    history.push(path);
  };

  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbs = [
      { label: "Home", href: "/" }, // Hardcoded Home breadcrumb
    ];

    // Add dynamic pathnames to the breadcrumbs
    pathnames.forEach((pathname, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
      breadcrumbs.push({ label: pathname, href: routeTo });
    });

    return breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1;

      return isLast ? (
        <div className="capitalize !text-[13px] cursor-pointer !p-0 !m-0 !mt-[2.5px]">
          {breadcrumb.label}
        </div>
      ) : (
        <Link
          key={index}
          underline="hover"
          sx={{ color: "white" }}
          data-path={breadcrumb.href}
          onClick={handleClick}
          className="!text-white capitalize !text-[13px] cursor-pointer opacity-[85%]"
        >
          {breadcrumb.label}
        </Link>
      );
    });
  };

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" className="!text-white">
        {generateBreadcrumbs()}
      </Breadcrumbs>
    </div>
  );
}
