import ExperimentTypes from "./experiments.types";

const INITIAL_STATE = {
  series: [],
  currentPageNumber: 0,
  hasMore: true,
  isFetching: false,
  isDeleting: false,
  errorMessage: undefined,
  searchedString: "",
  filters: new Map([
    ["experimentStatusFilter", []],
  ]),
  appliedFilters: new Map([
    ["experimentStatusFilter", []]

  ]),
  sorting: {
    key: "",
    isReverse: false
  },
};

const ExperimentsReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case ExperimentTypes.INITIALIZE_EXPERIMENTS:
      {
        return {
          ...INITIAL_STATE, ...action.payload
        };
      }
    case ExperimentTypes.FETCH_EXPERIMENTS_START:
      return {
        ...state,
        errorMessage: undefined,
        isFetching: true
      };
    case ExperimentTypes.FETCH_EXPERIMENTS_SUCCESS:{
        const currentPageNumber = action.payload.pageNumber;
        const hasMore = action.payload.hasMore;
        const table_data = action.payload.table_data;
        const searchedString = action.payload.searchedString;
        let series = action.payload.series || [];
        const sorting = {
          key: action.payload.sorting.key,
          isReverse: action.payload.sorting.isReverse
        }

        if (currentPageNumber > 1) {
          series = [...series]
        }

        return {
          ...state,
          hasMore,
          series,
          currentPageNumber,
          searchedString,
          sorting,
          table_data,
          isFetching: false
        }
      }
    case ExperimentTypes.FETCH_EXPERIMENTS_FAILURE:
      {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.payload
        }
      }
    case ExperimentTypes.SET_EXPERIMENTS_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
      case ExperimentTypes.SET_APPLIED_EXPERIMENTS_FILTER:
        {
          return {
            ...state,
            appliedFilters: new Map(state.filters)
          }
        }


    default:
      return state;
  }
};

export default ExperimentsReducer;
