import React from "react";
import useZustandStore from "../../zustandStore/useZustandStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const ProgressBar = () => {
  const { openProgressBarPopUp, uploads, cancelUpload } = useZustandStore();

  if (!openProgressBarPopUp || !uploads || uploads.length === 0) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 z-50 flex flex-col space-y-4">
      {uploads?.map((item, index) => (
        <div
          key={index}
          className="w-80 p-4 bg-white shadow-lg border border-gray-200 rounded-lg"
        >
          <div className="flex justify-between items-center">
            <h6 className="text-[13px]">Uploading....</h6>
            <button
              className="text-black rounded-lg"
              onClick={() => cancelUpload(item?.id)} // Assuming item has an id property
            >
              <FontAwesomeIcon icon={faWindowClose} className="text-[18px]" />
            </button>
          </div>
          <h6 className="text-[12px] line-clamp-1 text-[black] font-semibold">{item?.title}</h6>
          <div className="w-full h-[7px] bg-gray-200 rounded-full overflow-hidden mr-4 mt-1">
            <div
              className="h-full bg-green-500 transition-all duration-300"
              style={{ width: `${item?.progress}%` }}
            ></div>
          </div>
          <h6 className="text-[13px] mt-2 text-right">{item?.progress}%</h6>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
