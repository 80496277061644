import React from "react";

const ExperimentIcon = ({ className }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M6 2H18"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M5.38672 15L9.00772 7.5195V2H15.0127V7.5195L18.6207 15"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.89614 21.8382C3.50548 21.6491 3.20594 21.3126 3.06341 20.9026C2.92088 20.4927 2.94705 20.0429 3.13614 19.6522L5.38764 15.0017C5.38764 15.0017 8.99914 17.5017 11.9991 15.0017C14.9991 12.5017 18.6221 15.0017 18.6221 15.0017L20.8671 19.6542C20.9875 19.9038 21.0425 20.1797 21.0271 20.4563C21.0117 20.7329 20.9263 21.0011 20.779 21.2357C20.6316 21.4703 20.4271 21.6636 20.1847 21.7976C19.9422 21.9316 19.6697 22.0018 19.3926 22.0017H4.60914C4.36214 22.0017 4.11864 21.9457 3.89614 21.8382Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default ExperimentIcon;
