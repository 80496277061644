import CommonDataActionTypes from "./common.types";
import { apiGateway } from "../../utils/config";
import { store } from "../store";
import { GET_COMMON_DATA, GET_USERS } from "../../utils/constants";

export const fetchCommonDataStart = () => ({
  type: CommonDataActionTypes.FETCH_COMMON_DATA_START,
});

export const fetchCommonDataSuccess = (data) => { 
  return({
  type: CommonDataActionTypes.FETCH_COMMON_DATA_SUCCESS,
  payload: data,
})};

export const fetchCommonDataFailure = (errorMessage) => ({
  type: CommonDataActionTypes.FETCH_COMMON_DATA_FAILURE,
  payload: errorMessage,
});

export const fetchCommonDataStartAsync = (axiosPrivate, setErrorMsg) => {
    store.dispatch(fetchCommonDataStart());
    let url = apiGateway + GET_COMMON_DATA;
    axiosPrivate.get(url)
      .then(async ({data, status}) => {
        let url = apiGateway + GET_USERS;
        let commonData = data;
        axiosPrivate.get(url)
          .then(({data}) => {
            let creatorData = data;
            const commonUserPayload = {
              categories: commonData?.categories,
              creators: creatorData?.users,
            };
            store.dispatch(fetchCommonDataSuccess(commonUserPayload));
          })
          .catch(({response}) => {
            setErrorMsg({ error: 'Unable to fetch category users', type: "failed" });
          });
      })
      .catch(({response}) => {
        console.log("response", response)
        // setErrorMsg({ error: "", type: "failed" });
      });
};
