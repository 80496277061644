/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Modal, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./edit-series.styles.css";
import { validateImage } from "../../utils/utils";
import ImageEditor from "../ImageEditor/image-editor.component";
import { Chip, Paper, styled } from "@mui/material";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { GET_SERIES_V1, GET_SERIES_SIMILAR_TITLES, CATEGORY_CMS_ALL, CATEGORY_V1, GET_SHOWS_LIST_V1 } from "../../utils/constants";

export default function EditSeries({
  series,
  setSeries,
  primaryTags,
  secondaryTags,
  setReload,
}) {
  const language = [{id: 1, language: 'Hindi', code: 'hi'}, {id: 2, language: 'Bangla', code: 'bn'}];
  const SeriesQuality = [{id: 1, label: 'Original', value: 'false'}, {id: 2, label: 'Dubbed', value: 'true'}];
  const axiosPrivate = useAxiosPrivate();
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const [lang, setLang] = useState('hi');
  const [seriesTitles, setSeriesTitles] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showSeriesTitle, setShowSeriesTitle] = useState(false);
  const [title, setTitle] = useState(series?.display_title);
  const [thumbnail, setThumbnail] = useState(null);
  const [category, setCategory] = useState("");
  const [showId, setShowId] = useState("");
  const [showsList, setShowsList] = useState([]);
  const [description, setDescription] = useState(series.description);
  const [creatorColor, setCreatorColor] = useState(series.creator_color);
  const [seriesQual, setSeriesQuality] = useState(series.is_dubbed);
  const [categories, setCategories] = useState([]);
  const [isNews, setIsNews] = useState(
    series?.is_news
  );
  const [errorMsg, setErrorMsg] = useState({
    error: "",
    type: ""
  });
  const [listIsLoading, setListIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [creatorId, setCreatorId] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState(series.original_image);
  const [titleIconImage, setTitleIconImage] = useState(series.title_icon);
  const [tagsMap, setTagsMap] = useState(new Map());
  const [primaryChips, setPrimaryChips] = useState([]);
  const [secondaryChips, setSecondaryChips] = useState([]);
  const [primaryChips1, setPrimaryChips1] = useState([]);
  const [secondaryChips1, setSecondaryChips1] = useState([]);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [addTitleImage, setAddTitleImage] = useState(
    series?.add_title_in_image
  );
  const [titleIcon, setTitleIcon] = useState(null);
  const titleIconRef = useRef();
  const commonData = useSelector((state) => state.common);
  const creatorCategory = useSelector(
    (state) => state.user.currentUser.category
  );

  useEffect(() => {
    let url = `${apiGateway}${CATEGORY_CMS_ALL}?page=1&default_only=true`;
    axiosPrivate.get(url)
    .then(({data, status}) => {
      if(status === 200){
        setCategories([...data.categories]);
        // handleFetchSuggestions(null, null, data.categories[0]?.slug);
      }
    }).catch(({response}) => {
      setErrorMsg({
        type: "failed",
        error: response?.data ? response?.data?.error_message : "Category failed to fetch"
      })
    })
    getShowsList();
  }, [series]);


  const getShowsList = () => {
    setListIsLoading(true);
    const url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=1&page_size=1000000`;
    axiosPrivate.get(url)
      .then(({data, status}) => {
        if(status === 200){
          setShowsList(data?.show_list)
          setListIsLoading(false);
        }
      })
      .catch(({response}) => {
        setErrorMsg({
          type: "failed",
          error: response?.data?.message ?? "Something went wrong"
        })
        setListIsLoading(false);
      });
  }

  useEffect(() => {
    setCategory(series?.category?.id);
    setLang(series?.language);
    setShowId(series?.show?.id)
    setCreatorId(series?.creator?.id);
    let titles = primaryTags?.map((tag) => {
      tagsMap.set(tag.title, tag.id);
      return tag.title;
    });
    setPrimaryChips(titles);
    titles = secondaryTags?.map((tag) => {
      tagsMap.set(tag.title, tag.id);
      return tag.title;
    });
    handleFetchSuggestions(series?.category?.id)
    setSecondaryChips(titles);
  }, [series, categories]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    const primaryTagIds = primaryChips?.map((chip) => tagsMap.get(chip));
    const secondaryTagIds = secondaryChips?.map((chip) => tagsMap.get(chip));

    data.append("title", title);
    data.append("description", description);
    data.append("category_id", category);
    data.append("language", lang);
    data.append("is_dubbed", seriesQual);
    data.append("add_title_in_image ", addTitleImage);
    data.append("is_news ", isNews);
    if(showId == 0){
      data.append("show_id ", null);
    }else{
      data.append("show_id ", showId);
    }

    if (thumbnail) {
      data.append("image", thumbnail);
    }
    if (titleIcon) {
      data.append("title_icon", titleIcon);
    }

    if (!thumbnail && !thumbnailImageSource) {
      data.append("image", thumbnail);
    }
    if (!titleIcon && !titleIconImageSource) {
      data.append("title_icon", titleIcon);
    }
    data.append("creator_id", creatorId);
    data.append("creator_color", creatorColor);
    if (primaryTagIds?.length === 0) {
      data.append("primary_tag_ids", null);
    } else {
      for (let i = 0; i < primaryTagIds?.length; i++) {
        data.append("primary_tag_ids", primaryTagIds[i]);
      }
    }
    if (primaryTagIds?.length === 0) {
      data.append("primary_tag_ids", null);
    } else {
      for (let i = 0; i < secondaryTagIds?.length; i++) {
        data.append("secondary_tag_ids", secondaryTagIds[i]);
      }
    }
    setShowLoader(true);
    axiosPrivate.post(`${apiGateway}${GET_SERIES_V1}${series.slug}/`, data)
    .then(({data, status}) => {
      setShowLoader(false);
      if(status === 200){
        setSeries((prev) => ({
          ...prev,
          ...data.series,
          add_title_in_image: addTitleImage,
        }));
        setReload(true);
        setErrorMsg({
          type: "success",
          error:"Series Updated successfully"
        })
      }
    })
    .catch(({response}) => {
      setErrorMsg({
        type: "failed",
        error: response?.data ? response?.data?.error_message : "Not able to update series"
      })
    })
  };

  const handleFetchSuggestions = async (categoryId) => {
    setPrimaryChips1([])
    if (categories?.length) {
      let category  = categories?.filter(it => it?.id == parseInt(categoryId))

      if (categoryId && category) {
        let categorySlug = category[0]?.slug
        const url = `${apiGateway}${CATEGORY_V1}${categorySlug}/tags/`;
          axiosPrivate.get(url)
          .then(async ({data, status}) => {
            if(status === 200){
              data?.tags?.map((tag) => {
                tagsMap.set(tag.title, tag.id);
                setPrimaryChips1((prev) => [
                  ...prev,
                  { title: tag.title, id: tag.id },
                ]);
                setSecondaryChips1((prev) => [
                  ...prev,
                  { title: tag.title, id: tag.id },
                ]);
                return tag.title;
              });
            }
          })
          .catch(({response}) => {
            setErrorMsg({
              type: "failed",
              error: response?.data ? response?.data?.error_message : "Fetch failed !"
            })
          });
      }
    }
  };

  const fetchSeriesTitles = async () => {
    const url = `${apiGateway}${GET_SERIES_SIMILAR_TITLES}/?q=${title}`;
    axiosPrivate.get(url)
    .then(async ({data, status}) => {
      if(status === 200){
        setSeriesTitles(data?.series_titles);
        setShowMore(false);
      }
    }).catch(({response}) => {
      setErrorMsg({
        type: "failed",
        error: response?.data ? response?.data?.error_message : "Failed to fetch titles"
      })
    });
  };

  const thumbnailImageSource =
    thumbnail && thumbnailImage
      ? thumbnail
      : thumbnail && !thumbnailImage
      ? thumbnail
      : !thumbnail && thumbnailImage
      ? thumbnailImage
      : "";

  const titleIconImageSource =
    titleIcon && titleIconImage
      ? titleIcon
      : titleIcon && !titleIconImage
      ? titleIcon
      : !titleIcon && titleIconImage
      ? titleIconImage
      : "";

    useEffect(() => {
      if(thumbnailImage){
        setAddTitleImage(series?.add_title_in_image);
      }else{
        setAddTitleImage(false);
      }
    },[thumbnailImage]);

  return (
    <div>
      <div>
        <AlertComponent message={errorMsg?.error} type={errorMsg?.type} setAlertNotification={() => setErrorMsg({error: '', type: 'failed'})}/>
        <Container className="edit-series">
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-1">
                <Form.Group
                    className="flex-1 w-50 mr-2"
                    controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                      as="select"
                      name="language"
                      onChange={(e) => {
                        setLang(e.target.value)
                      }}
                      value={lang}
                    >
                      {language?.map((it) => (
                        <option value={it.code} key={it.code}>{it?.language}</option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="flex-1 w-50"
                  controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Series Quality</Form.Label>
                  <Form.Control
                      as="select"
                      name="quality"
                      onChange={(e) => {
                        setSeriesQuality(e.target.value)
                      }}
                      value={seriesQual}
                    >
                      {SeriesQuality?.map((it) => (
                        <option value={it.value} key={it.value}>{it?.label}</option>
                      ))}
                  </Form.Control>
              </Form.Group>
              </div>
              <Form.Group
                controlId="exampleForm.ControlInput1"
                onClick={() => setShowSeriesTitle(true)}
              >
                <Form.Label>Title</Form.Label>
                <div className="series-title-input">
                  <div className="series-title-dropdown">
                    <Form.Control
                      type="text"
                      placeholder="Enter the title of video"
                      required
                      name="title"
                      value={title}
                      onChange={(e) => {
                        setSeriesTitles([]);
                        setTitle(e.target.value);
                      }}
                    />
                    {showSeriesTitle && seriesTitles?.length > 0 && (
                      <div className="dropdown">
                        {seriesTitles
                          ?.map((title, i) => <li key={i}>{title}</li>)
                          .slice(0, showMore ? seriesTitles?.length : 2)}
                        <div
                          className="show-more"
                          onClick={() => setShowMore((prev) => !prev)}
                        >
                          {" "}
                          {showMore ? (
                            <span>show less</span>
                          ) : (
                            <span>show more</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <span className="verify" onClick={fetchSeriesTitles}>
                    verify
                  </span>
                </div>
              </Form.Group>
              <div onClick={() => setShowSeriesTitle(false)}>
                <Form.Group
                  className="cta"
                  controlId="exampleForm.ControlInput1"
                >
                  <div>
                    <Button
                      variant="secondary"
                      active
                      onClick={(e) => {
                        e.preventDefault();
                        setShowImageEditor(true);
                      }}
                    >
                      Select Thumbnail
                    </Button>
                    {(thumbnail || thumbnailImageSource) && (
                    <div className="image-preview">
                      <img
                        src={
                          thumbnail
                            ? URL.createObjectURL(thumbnail)
                            : thumbnailImageSource
                        }
                      />
                      {/* <FontAwesomeIcon className="icon" icon={faTrashAlt} onClick={() => { setThumbnailImage(null); setThumbnail(null) }} /> */}
                    </div>
                  )}
                  </div>
                  <div className="title-icon">
                    <Button
                      variant="success"
                      active
                      onClick={(e) => {
                        titleIconRef.current.click();
                      }}
                    >
                      Select Title Icon
                    </Button>
                    <Form.Control
                      type="file"
                      name="titleIcon"
                      className=""
                      ref={titleIconRef}
                      onChange={(e) => setTitleIcon(e.target.files[0])}
                    />{" "}
                    {(titleIcon || titleIconImageSource) && (
                    <div className="image-preview">
                      <div className="image-prview-edit">
                        <img
                          src={
                            titleIcon
                              ? URL.createObjectURL(titleIcon)
                              : titleIconImageSource
                          }
                        />
                      </div>
                      {/* <FontAwesomeIcon className="icon" icon={faTrashAlt} onClick={() => { setTitleIconImage(null); setTitleIcon(null); titleIconRef.current.value = null }} /> */}
                    </div>
                  )}
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    onChange={(e) => {
                      setPrimaryChips([]);
                      setSecondaryChips([]);
                      setPrimaryChips1([]);
                      setSecondaryChips1([]);
                      setCategory(e.target.value);
                      handleFetchSuggestions(
                        e.target.value
                      );
                    }}
                  >
                    {categories?.map((category, index) => (
                      <option
                        key={`${category.id}-${index}`}
                        selected={category.id === series.category.id}
                        value={category.id}
                      >
                        {category.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Select Show</Form.Label>
                  <Form.Control
                    as="select"
                    name="show"
                    onChange={(e) => {
                      setShowId(e.target.value);
                    }}
                  >
                    <option value={0}>{listIsLoading ? "Loading...": "None"}</option>
                    {showsList?.map((show, index) => (
                      <option
                        key={`${show.id}-${index}`}
                        selected={show.id === showId}
                        value={show.id}
                      >
                        {show.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Tags</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val) setPrimaryChips((prev) => [...prev, val]);
                  }}
                >
                  <option value={null}></option>
                  {primaryChips1?.map(
                    (tag, i) =>
                      primaryChips?.indexOf(tag?.title) < 0 && (
                        <option value={tag?.title} key={i}>{tag?.title}</option>
                      )
                  )}
                </Form.Control>

                {primaryChips?.length !== 0 && (
                  <Paper
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {primaryChips?.map((data) => {
                      let icon;

                      return (
                        <ListItem key={data}>
                          <Chip
                            icon={icon}
                            label={data}
                            onDelete={() =>
                              setPrimaryChips((prev) =>
                                prev.filter((d) => d !== data)
                              )
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                )}
                </Form.Group>
                
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter the description of video"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="d-flex" style={{ alignItems: "center" }} controlId="exampleForm.ControlTextarea1">
                  <Form.Check
                    name="isNews"
                    checked={isNews}
                    onChange={(e) => setIsNews(e.target.checked)}
                    label="Is News?"
                  />
                </Form.Group>
              </div>
              <div className="center">
                <Button type="submit" disabled={showLoader} variant="outline-primary" active className="cta-update-series">
                  {showLoader ? "Updating..." : "Update"}
                </Button>
              </div>
            </Form>
          </div>
        </Container>
        <Modal fullscreen={true} className="image-editor-modal" show={showImageEditor}>
          <Modal.Header closeButton onHide={() => setShowImageEditor(false)}>
            <div className="modal-video-title">Select Thumbnail</div>
          </Modal.Header>
          <Modal.Body>
            <ImageEditor
              done={(file) => setThumbnail(file)}
              close={() => setShowImageEditor(false)}
              image={thumbnail && URL.createObjectURL(thumbnail)}
              imageUrl={thumbnailImageSource}
              creatorColor={creatorColor}
              setCreatorColor={(creatorColor) => setCreatorColor(creatorColor)}
              seriesTitle={title}
              creatorName={series?.creator?.name}
              addTitleImage={addTitleImage}
              setAddTitleImage={setAddTitleImage}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
