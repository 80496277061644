import React from "react";
import SplashScreen from "../../assests/images/tree-swing.svg";

const NoDataFound = ({ className, displayText }) => {
  return (
    <div>
      <img src={SplashScreen} alt="tree-swing" className={className} />
      <div className="text-center mt-4 italic">{displayText}</div>
    </div>
  );
};

export default NoDataFound;
