import React from "react";
import Feedback from "../../components/Feedback";
import useCmsOpenEvent from "../../hooks/useCmsOpenEvent";

const FeedBackPage = () => {
  useCmsOpenEvent();

  return (
    <div className="mt-3">
      <Feedback />
    </div>
  );
};

export default FeedBackPage;
