import { createSelector } from "reselect";

const selectVideos = (state) => state.video;

export const selectAllVideos = createSelector(
  [selectVideos],
  (video) => video.videos
);

export const selectCurrentPageNumber = createSelector(
  [selectVideos],
  (video) => video.currentPageNumber
);

export const selectHasMore = createSelector(
  [selectVideos],
  (video) => video.hasMore
);

export const selectIsFetching = createSelector(
  [selectVideos],
  (video) => video.isFetching
);

export const selectIsDeleting = createSelector(
  [selectVideos],
  (video) => video.isDeleting
);

export const selectFilters = createSelector(
  [selectVideos],
  (video) => video.filters
);
