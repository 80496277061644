import SeriesActionTypes from "./series.types";

const INITIAL_STATE = {
  series: [],
  currentPageNumber: 0,
  filters: new Map([
    ["categoryFilter", []],
    ["statusFilter", []],
    ["durationFilter", []],
    ["creatorFilter", []],
    ["reviewStatusFilter", []],
    ["contentManagerFilter", []],
    ["isPremiumFilter", []],
    ["promotedSeriesFilter", []],
    ["isOriginalFilter", []],
    ["contentManagerFilter", []],
    ["notificationFilter", []],
    ["languageFilter", []],
    ["dubbedFilter", []],
    ["newsFilter", []],
  ]),
  hasMore: true,
  page_data: {
    n_series: 0,
    n_pages: 1,
    page: 1
  },
  isFetching: false,
  isDeleting: false,
  isUpdating: false,
  isFilterApplied: false,
  errorMessage: undefined,
  searchedString: "",
  sortKey: "",
  sortReverse: "",
  selectedTabForSeriesPage: "SERIES",
  selectedCreatorSeriesFilter: null
};

const seriesReducer = (state = INITIAL_STATE, action) => {
  try {
    switch (action.type) {
      case SeriesActionTypes.SERIES_INITIALIZE: {
        return { ...INITIAL_STATE, selectedTabForSeriesPage: state.selectedTabForSeriesPage, selectedCreatorSeriesFilter: state.selectedCreatorSeriesFilter };
      }
      case SeriesActionTypes.FETCH_SERIES_START:
        return {
          ...state,
          isFetching: true,
        };
      case SeriesActionTypes.FETCH_SERIES_SUCCESS: {
        const pageNumber = action.payload.pageNumber;
        const fetchedSeries = [...action.payload.seriesFetched];
        const filters = action.payload.filters;

        let isFilterApplied = false;
        for (const [key, value] of filters.entries()) {
          if (value.length > 0) {
            isFilterApplied = true;
            break;
          }
        }

        return {
          ...state,
          series: [...fetchedSeries],
          hasMore: action.payload.hasMore,
          currentPageNumber: pageNumber,
          searchedString: action.payload.searchedString,
          isFetching: false,
          page_data: action.payload.table_data,
          errorMessage: undefined,
          isFilterApplied,
          filters,
          sortKey: action.payload.sortKey,
          sortReverse: action.payload.sortReverse,
        };
      }
      case SeriesActionTypes.SET_HAS_MORE:
        return {
          ...state,
          hasMore: action.payload,
        };

      case SeriesActionTypes.FETCH_SERIES_FAILURE:
        return {
          ...state,
          isFetching: false,
          hasMore: false,
          errorMessage: action?.payload?.message ?? {},
        };
      case SeriesActionTypes.INITIALIZE_SERIES:
        return {
          series: [],
          currentPageNumber: 0,
          filters: new Map([
            ["categoryFilter", []],
            ["statusFilter", []],
            ["durationFilter", []],
            ["creatorFilter", []],
            ["reviewStatusFilter", []],
            ["contentManagerFilter", []],
            ["isPremiumFilter", []],
            ["promotedSeriesFilter", []],
            ["newsFilter", []],
            ["isOriginalFilter", []],
            ["notificationFilter", []],
            ["languageFilter", []],
            ["dubbedFilter", []],
          ]),
          hasMore: true,
          isFetching: false,
          isDeleting: false,
          isUpdating: false,
          isFilterApplied: false,
          errorMessage: undefined,
          searchedString: "",
          selectedCreatorSeriesFilter: state.selectedCreatorSeriesFilter
        };

      case SeriesActionTypes.SET_FILTERS: {
        const filters = action.payload;
        return {
          ...state,
          filters,
        };
      }

      case SeriesActionTypes.CHANGE_A_SERIES_DETAILS: {
        const changedSeries = action.payload;
        const series = state.series?.map((series) => {
          if (series.id === changedSeries.id) {
            return changedSeries;
          } else {
            return series;
          }
        });

        return {
          ...state,
          series,
          isUpdating: false,
        };
      }

      case SeriesActionTypes.CREATE_SERIES: {
        const newSeries = action.payload.series;
        return {
          ...state,
          series: [newSeries, ...state.series],
        };
      }

      case SeriesActionTypes.REMOVE_SERIES: {
        const seriesId = action.payload.seriesId;
        const updatedSeries = state.series.filter(
          (data) => data.id !== seriesId
        );
        return {
          ...state,
          series: updatedSeries,
        };
      }
      case SeriesActionTypes.UPDATE_SERIES: {
        const series = state.series;
        const updatedSeriesData = action.payload;
        const updatedSeries = [];
        series?.map((data) => {
          if (data.id === updatedSeriesData.id) {
            updatedSeries.push({ ...data, ...updatedSeriesData });
          } else {
            updatedSeries.push(data);
          }
        });

        return {
          ...state,
          series: updatedSeries,
          isUpdating: false,
        };
      }
      case SeriesActionTypes.CHANGE_PROMOTION_SCORE_START: {
        return {
          ...state,
          isUpdating: true,
        };
      }
      case SeriesActionTypes.CHANGE_PROMOTION_SCORE_SUCCESS: {
        const series = state?.series;
        const seriesSlug = action?.payload?.seriesSlug;
        const score = action?.payload?.score;
        const updatedSeries = [];
        series?.map((data) => {
          if (data.slug === seriesSlug) {
            updatedSeries.push({ ...data, promotion_score: score });
          } else {
            updatedSeries.push(data);
          }
        });
        return {
          ...state,
          series: updatedSeries,
          isUpdating: false,
        };
      }
      case SeriesActionTypes.CHANGE_PROMOTION_SCORE_FAILURE: {
        return {
          ...state,
          isUpdating: false,
          errorMessage: action.payload,
        };
      }
      case SeriesActionTypes.CHANGE_PREMIUM_SERIES_START: {
        return {
          ...state,
          isUpdating: true,
        };
      }
      case SeriesActionTypes.CHANGE_PREMIUM_SERIES_SUCCESS: {
        return {
          ...state,
          isUpdating: false,
        };
      }
      case SeriesActionTypes.CHANGE_PREMIUM_SERIES_FAILURE: {
        return {
          ...state,
          isUpdating: false,
          errorMessage: action.payload,
        };
      }
      case SeriesActionTypes.PROMOTE_SERIES_START: {
        return {
          ...state,
          isUpdating: true,
        };
      }

      case SeriesActionTypes.PROMOTE_SERIES_SUCCESS: {
        return {
          ...state,
          isUpdating: false,
        };
      }

      case SeriesActionTypes.PROMOTE_SERIES_FAILURE: {
        return {
          ...state,
          isUpdating: false,
          errorMessage: action.payload,
        };
      }

      case SeriesActionTypes.REJECTION_REASON_SUCCESS: {
        return {
          ...state,
          rejectionReasonData: action.payload,
        };
      }

      case SeriesActionTypes.UPDATE_SELECTED_TAB: {
        return {
          ...state,
          selectedTabForSeriesPage: action.payload,
        };
      }

      case SeriesActionTypes.UPDATE_SELECTED_TAB_START: {

        return {
          ...state,
          selectedTabForSeriesPage: action.payload,
        };
      }

      case SeriesActionTypes.REJECTION_REASON_START: {
        return {
          ...state,
          errorMessage: "",
        };
      }

      case SeriesActionTypes.REJECTION_REASON_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload,
        };
      }

      case SeriesActionTypes.CHANGE_SERIES_ORIGINAL_START: {
        return {
          ...state,
          errorMessage: "",
        };
      }

      case SeriesActionTypes.CHANGE_SERIES_ORIGINAL_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload,
        };
      }

      case SeriesActionTypes.CHANGE_SERIES_STATUS_START: {
        return {
          ...state,
          isUpdating: true,
          errorMessage: "",
        };
      }
      case SeriesActionTypes.CHANGE_SERIES_STATUS_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload,
        };
      }
      case SeriesActionTypes.SET_SELECTED_CREATOR_SERIES_FILTER: {
        return {
          ...state,
          selectedCreatorSeriesFilter: action.payload,
        };
      }
      default:
        return state;
    }
  } catch (err) {
    return {
      ...state,
      hasMore: false,
      isFetching: false,
      errorMessage: action.payload,
    };
  }
};

export default seriesReducer;
