import React from "react";

const MetricsSkeleton = () => {
  return (
    <div className="flex gap-x-2 !flex-wrap gap-y-2 lg:gap-x-5 lg:!flex-nowrap">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="!w-[30%] !px-2 !py-2 rounded-md !bg-white shadow-sm lg:!w-[25%] lg:!px-7 lg:!py-6 lg:rounded-xl animate-pulse"
        >
          <div className="w-[35%] h-[12px] bg-gray-300 rounded mb-1"></div>
          <div className="w-[25%] h-[16px] bg-gray-300 rounded mt-2 lg:!h-[28px]"></div>
        </div>
      ))}
    </div>
  );
};

export default MetricsSkeleton;
