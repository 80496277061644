import React from "react";
import "./track.styles.css";
import TrackEdit from "../../components/Track/track-edit.component";
import { withRouter } from "react-router-dom";

function TrackPageEdit({match, history}) {
    return (
        <div>
            <TrackEdit match={match} history={history}/>
        </div>
    );
}

export default withRouter(TrackPageEdit);
