import React from "react";

const AllFeedbacksSkeleton = () => {
  return (
    <div>
      {[
        Array(10)
          .fill("")
          .map((_) => {
            return (
              <div className="animate-pulse mt-4">
                <div className="flex gap-x-3">
                  {/* Avatar Skeleton */}
                  <div className="rounded-full w-[36px] h-[36px] bg-gray-300"></div>

                  <div className="flex flex-col w-full">
                    {/* Username and Date Skeleton */}
                    <div className="text-[12px] text-gray-400">
                      <div className="w-[150px] h-4 bg-gray-300 rounded mb-1"></div>
                    </div>

                    {/* Review Text Skeleton */}
                    <div className="w-full mt-1">
                      <div className="h-[18px] bg-gray-300 rounded mb-1"></div>
                      <div className="h-[18px] bg-gray-300 rounded mb-1"></div>
                      <div className="w-[70%] h-[18px] bg-gray-300 rounded mb-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }),
      ]}
    </div>
  );
};

export default AllFeedbacksSkeleton;
