import axios from 'axios';
import { apiGateway } from '../utils/config';

export const axiosPublic = axios.create({
    baseURL: apiGateway,
});

export const axiosPrivate = axios.create({
    baseURL: apiGateway,
    headers: {
        withCredentials: true,
    }
});