const stepperData = [
  {
    step: 1,
    name: "track",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "Track",
  },
  {
    step: 2,
    name: "home",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "Home",
  },
  {
    step: 3,
    name: "category",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "Category Page",
  },
  {
    step: 4,
    name: "show",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "Show Page",
  },
  {
    step: 5,
    name: "stories",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "Stories",
  },
  {
    step: 6,
    name: "new",
    status: true,
    showButton: true,
    isValid: { control: false, experiment: false },
    title: "New tab",
  },
];

const variantGroupsDefaultState = [
  {
    id: 0,
    title: "Control",
    value: "control",
    homeTop10SeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    homeTrendingSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categories: [],
    shows: [],
    topNavbar: [],
    defaultTopNavbar: [],
    newSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categoryAllSeriesIds: [],
    newCategory: {},
    categoryAllCuration: [],
    showsAllCuration: [],
    originalCuration: [],
  },
  {
    id: 1,
    title: "Experiment",
    value: "experiment",
    homeTop10SeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    homeTrendingSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categories: [],
    shows: [],
    topNavbar: [],
    defaultTopNavbar: [],
    newSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categoryAllSeriesIds: [],
    newCategory: {},
    categoryAllCuration: [],
    showsAllCuration: [],
    originalCuration: [],
  },
];

const variantGroupsDefaultForCreate = [
  {
    id: 0,
    title: "Control",
    value: "control",
    homeTop10SeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    homeTrendingSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categories: [],
    shows: [],
    topNavbar: [],
    defaultTopNavbar: [],
    newSeriesIds: [{ id: 1, dfs: 0, series_ids: "" }],
    categoryAllCuration: [],
    showsAllCuration: [],
    newCategory: {},
  },
];

const tracksTableHeading = [
  "ID",
  "Title",
  "Created On",
  "Updated On",
  "Status",
  "Default",
  "A/B",
  "Onboarding",
  "Score",
  "Actions",
];

export {
  stepperData,
  variantGroupsDefaultState,
  variantGroupsDefaultForCreate,
  tracksTableHeading,
};
