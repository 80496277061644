import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SIDE_NAVIGATION } from "../../utils/constants";
import "./side-navigation.styles.css";
import LogoutIcon from "@mui/icons-material/Logout";
import useZustandStore from "../../zustandStore/useZustandStore";

const SideNavigation = ({
  currentUser,
  setOpenSideDrawer,
  setOpenLogoutModal,
}) => {
  const [currentActive, setCurrentActive] = useState(
    window.location.hash ?? "#/shows"
  );
  const setShowSideNavigationBar = useZustandStore(
    (val) => val?.setShowSideNavigationBar
  );

  // just to have a safe side, when roles key is not defined from backend
  const localRoles = [];
  if (currentUser?.is_admin) {
    localRoles.push("admin");
  }

  if (currentUser?.is_quality_approved) {
    localRoles.push("creator");
  }

  // if roles key is missed from backed, alternate localRoles will handle it, to avoid any break down
  const roles = currentUser?.roles || localRoles;

  // Filter navigation based on user roles
  const getFilteredNavigation = (roles) => {
    let filteredNavigation = SIDE_NAVIGATION?.filter((item) =>
      item?.requiredRoles?.some((role) => roles?.includes(role))
    );
    return filteredNavigation;
  };

  const filteredNavigation = getFilteredNavigation(roles);

  useEffect(() => {
    const handleResize = () => {
      setCurrentActive(window.location.hash);
    };
    window.addEventListener("hashchange", handleResize);
    return () => window.removeEventListener("hashchange", handleResize);
  }, [window.location]);

  return (
    <div className="max-h-screen overflow-y-auto pb-20 -mt-1" id="HideScroll">
      <ul>
        {filteredNavigation?.map((item) => {
          return (
            <div
              key={item.id}
              className={` ${
                currentActive.startsWith(`#${item.navTo}`) ? "bg-[#eeeeff]" : ""
              }`}
            >
              <li
                onClick={() => {
                  setCurrentActive(`#${item.navTo}`);
                  if (setOpenSideDrawer) setOpenSideDrawer(false);
                  if (item.title === "Tracks") {
                    setShowSideNavigationBar(false);
                  }
                }}
              >
                <Link
                  className={`flex px-3 py-3 gap-x-3 no-underline hover:!no-underline hover:text-gray-500 font-semibold ${
                    currentActive.startsWith(`#${item.navTo}`)
                      ? "!text-baseBlueColor"
                      : "text-gray-500 hover:!text-gray-500"
                  }`}
                  to={item.navTo}
                >
                  <div className="!text-center flex gap-x-2">
                    <div className="w-[24px] mx-auto">{item.icon}</div>
                    <div>{item.title}</div>
                  </div>
                </Link>
              </li>
            </div>
          );
        })}
      </ul>

      <button
        className="px-3 text-[15px] mt-2 lg:hidden"
        onClick={() => {
          if (setOpenLogoutModal) {
            setOpenSideDrawer(false);
            setOpenLogoutModal(true);
          }
        }}
      >
        <LogoutIcon /> <span className="ml-1">Logout</span>
      </button>
    </div>
  );
};

export default SideNavigation;
