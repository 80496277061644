import React from "react";
import GuitarIllustrationSVG from "../../assests/images/music-guitar-illustration.svg";

const GuitarIllustration = ({ displayText, className }) => {
  return (
    <div>
      <img src={GuitarIllustrationSVG} alt="tree-swing" className={className} />
      <div className="text-center mt-4 italic">{displayText}</div>
    </div>
  );
};

export default GuitarIllustration;
