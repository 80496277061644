export const QuestionAnswerTypes = {
    INITIALIZE_EXPERIMENTS:"INITIALIZE_EXPERIMENTS",
    SET_HAS_MORE_EXPERIMENTS:"SET_HAS_MORE_EXPERIMENTS",
    FETCH_EXPERIMENTS_START:"FETCH_EXPERIMENTS_START",
    FETCH_EXPERIMENTS_FAILURE:"FETCH_EXPERIMENTS_FAILURE",
    FETCH_EXPERIMENTS_SUCCESS:"FETCH_EXPERIMENTS_SUCCESS",
    SET_EXPERIMENTS_FILTERS:"SET_EXPERIMENTS_FILTERS",
    SET_APPLIED_EXPERIMENTS_FILTER:"SET_APPLIED_EXPERIMENTS_FILTER"

    
  };
  
  export default QuestionAnswerTypes;
  