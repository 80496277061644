import {createStore,applyMiddleware} from 'redux'
import logger from 'redux-logger'
import {persistStore} from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './root-reducer'

let middlewares = [thunk]
if(process.env.NODE_ENV === 'development'){
   middlewares = [logger];
}


const store = createStore(rootReducer,applyMiddleware(thunk))//why ...middlewares array is not working?
const persistor = persistStore(store)

export  {store,persistor};