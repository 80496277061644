import { apiGateway } from "../../utils/config";
import { GET_ADMIN_CREATOR, GET_CREATOR_LIST, UPDATE_PROMOTER_SCORE } from "../../utils/constants";
import CreatorActionTypes from "./creator.types";

export const setFilters = (filters) => {
  return {
    type: CreatorActionTypes.SET_FILTERS,
    payload: filters,
  };
};

export const setHasMore = (hasMore) => {
  return {
    type: CreatorActionTypes.SET_HAS_MORE,
    payload: hasMore,
  };
};

export const fetchCreatorsStart = () => ({
  type: CreatorActionTypes.FETCH_CREATOR_START,
});

export const fetchCreatorsSuccess = (data, pageNumber,searchName, sortKey, sortReverse) => ({
  type: CreatorActionTypes.FETCH_CREATOR_SUCCESS,
  payload: { 
    hasMore: data.has_more,
    creatorsFetched: data?.users,
    pageNumber,
    table_data: {
      n_series: data.n_users,
      n_pages: data.n_pages,
      page: data.page
    },
    sortKey: sortKey,
    sortReverse: sortReverse,
    searchedName:searchName 
  },
});

export const fetchCreatorsFailure = (errorMessage) => ({
  type: CreatorActionTypes.FETCH_CREATOR_FAILURE,
  payload: errorMessage,
});

export const initalizeCreators = () => ({
  type: CreatorActionTypes.CREATOR_INITIALIZE,
});

export const fetchCreatorsStartAsync = (axiosPrivate, pageNumber, searchName, filters, sortKey, sortReverse, setErrorMsg) => {
  return async (dispatch) => {
    if (pageNumber === 1) {
      dispatch(initalizeCreators());
    }
    dispatch(fetchCreatorsStart());
    let url = `${apiGateway}${GET_CREATOR_LIST}?page=${pageNumber}`;
    if (searchName) url += `&q=${searchName}`;
    if (sortKey) {
      url += `&sorting_key=${sortKey}`
      if (sortReverse)
        url += `&reverse=${sortReverse}`
    };
    if (filters && filters !== null) {
      const categoryArray = filters?.get("categoryFilter");
      const contentManagerArray = filters?.get("contentManagerFilter");

      if (categoryArray.length !== 0) {
        for (let i = 0; i < categoryArray.length; i++) {
          const categoryId = categoryArray[i];
          url += `&category_ids=${categoryId}`;
        }
      }
      if (contentManagerArray.length !== 0) {
        for (let i = 0; i < contentManagerArray.length; i++) {
          const contentManager = contentManagerArray[i];
          url += `&content_manager_ids=${contentManager}`;
        }
      }
    }
    axiosPrivate.get(url)
    .then(({data, status}) => {
      if (status === 200 && Object?.keys(data)?.length !== 0 && !data?.message)
        dispatch(fetchCreatorsSuccess(data, pageNumber,searchName, sortKey, sortReverse));
      else{
        setErrorMsg({error: data?.message, type: "failed"});
      }
    })
    .catch(({response}) => {
      setErrorMsg({error: response?.data ? response.data?.response?.message : "Failed to fetch creator", type: "failed"});
    });
  };
};

//Change Quality Approved
export const changeQualityApprovedStart = () => ({
  type: CreatorActionTypes.CHANGE_QUALITY_APPROVED_START,
});

export const changeQualityApprovedSuccess = () => ({
  type: CreatorActionTypes.CHANGE_QUALITY_APPROVED_SUCCESS,
});

export const changeQualityApprovedFailure = (errorMessage) => ({
  type: CreatorActionTypes.CHANGE_QUALITY_APPROVED_FAILURE,
  payload: errorMessage,
});

export const changeQualityApprovedStartAsync = (
  axiosPrivate,
  creatorId,
  isQualityApproved,
  setErrorMsg
) => {
  return async (dispatch) => {
    dispatch(changeQualityApprovedStart());
    let url = `${apiGateway}${GET_ADMIN_CREATOR}${creatorId}/quality/`;
      axiosPrivate.post(url, { quality: isQualityApproved } )
      .then(({data, status}) => {
        if (status === 200 && data) {
          if(isQualityApproved){
            setErrorMsg({error:"Creator is marked as Quality Approved", type: "success"});
          } else {
          setErrorMsg({error: "Creator is removed from Quality Approved ", type: "success"});
        }
      }
      })
      .catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Unable to update creator", type: "failed"});
      });
  };
};

export const changePromotionScoreStart = () => ({
  type: CreatorActionTypes.CHANGE_PROMOTION_SCORE_START,
});

export const changePromotionScoreSuccess = () => ({
  type: CreatorActionTypes.CHANGE_PROMOTION_SCORE_SUCCESS,
});

export const changePromotionScoreFailure = (errorMessage) => ({
  type: CreatorActionTypes.CHANGE_PROMOTION_SCORE_FAILURE,
  payload: errorMessage,
});

export const changePromotionScoreStartAsync = (axiosPrivate, creatorId, score, setErrorMsg) => {
  return async (dispatch) => {
    dispatch(changePromotionScoreStart());
    let url = apiGateway + UPDATE_PROMOTER_SCORE;
      axiosPrivate.post(url, { creator_id: creatorId, score: score })
      .then(({data, status}) => {
        if(status === 200)
          setErrorMsg({error: "Promotion Score is changed for the creator", type: "success"})
      })
      .catch((err) => {
        setErrorMsg({error: "Unable to update data", type: "failed"})
      });
  };
};
