import { TEAM_ROLES } from "./constants/Team";
import FeedbackIcon from "../components/SideNavigation/Icons/FeedbackIcon";
import DashboardIcon from "../components/SideNavigation/Icons/DashboardIcon";
import VideoIcon from "../components/SideNavigation/Icons/VideoIcon";
import ShowsIcon from "../components/SideNavigation/Icons/ShowsIcon";
import ExperimentIcon from "../components/SideNavigation/Icons/ExperimentIcon";
import CategoriesIcon from "../components/SideNavigation/Icons/CategoriesIcon";
import TracksIcon from "../components/SideNavigation/Icons/TracksIcon";
import CreatorsIcon from "../components/SideNavigation/Icons/CreatorsIcon";
import TeamIcon from "../components/SideNavigation/Icons/TeamIcon";
import BlogsIcon from "../components/SideNavigation/Icons/BlogsIcon";

export const ENVIRONMENT = "DEV";

export const GET_USER = "/api/v1/users/me/";
export const OTP_SEND = "/api/v1/auth/send-otp/";
export const OTP_VERIFY = "/api/v1/auth/web/verify-otp/";
export const OTP_VERIFY_LOCAL = "/api/v1/auth/verify-otp/";
export const GET_CONFIG = "/api/v1/config/cms/?lang=en";
export const GET_COMMON_DATA = "/api/v1/units/create-unit/?lang=en";
export const GET_PRESIGNED_URL = "/trailer/presigned-url/";
export const GET_USERS = "/api/v1/categories/users/?page_size=200";
export const GET_ACCESS_TOKEN = "/api/v1/auth/web/renew-access-token/";
export const GET_ACCESS_TOKEN_LOCAL = "/api/v1/auth/renew-access-token/";
export const GET_COMMUNITY_LIST = "/api/v1/cms/posts/";
export const GET_CREATOR_LIST = "/api/v1/cms/creators/";
export const GET_ADMIN_CREATOR = "/api/v1/admin/creator/";
export const UPDATE_PROMOTER_SCORE = "/api/v1/cms/creator/promotion-score/";
export const GET_EXPERIMENTS_LIST = "/api/v1/cms/experiments/";
export const GET_PREMIUM_ITEMS = "/api/v1/cms/premium-items/";
export const GET_QA_LIST = "/api/v1/cms/questions/";
export const UPDATE_PROMOTER_SCORE_SERIES = "/api/v1/cms/promotion-score/";
export const GET_SERIES_LIST = "/api/v1/cms/series/";
export const GET_VIDEO_UNITS = "/api/v1/cms/units/";
export const BULK_DELETE_VIDEOS = "/api/v1/units/bulk-delete/";
export const USER_LOGOUT = "/api/v1/auth/logout/";
export const CREATE_TRACK = "/api/v1/cms/track/create/";
export const GET_ALL_TRACKS = "/api/v1/cms/tracks/";
export const EDIT_TRACK = "/api/v1/cms/track/";
export const GET_TRACK_BY_ID = "/api/v1.1/cms/track/";
export const EDIT_TRACK_EXPERIMENT = "/api/v1/cms/track-experiment/";
export const EDIT_TRACK_ACTION = "/api/v1/cms/track-action/";
export const TRACK_VALIDATIONS = "/api/v1/cms/track-validation/";
export const CATEGORY_CMS = "/api/v1/cms/category/";
export const CATEGORY_CMS_ALL = "/api/v1/categories/all/";
export const HOME_TOP_BAR = "/api/v1.2/home/top-bar/";
export const SERIES_V1_1 = "/api/v1.1/series/";
export const SHOW_V1 = "/api/v1/cms/show/";
export const GET_SERIES_SIMILAR_TITLES = "/api/v1/cms/series/similar-titles";
export const GET_ADMIN_SERIES = "/api/v1.2/admin/series/";
export const GET_SERIES_V1 = "/api/v1/series/";
export const GET_SHOWS_LIST_V1 = "/api/v1/cms/shows";
export const GET_SHOW_ACTION_V1 = "/api/v1/cms/show-action";
export const SERIES_SCHEDULE_V1 = "/api/v1/series/schedule/create/";
export const SERIES_CREATE_V1 = "/api/v1/series/create/";
export const SHOW_CREATE_V1 = "/api/v1/cms/show/create/";
export const SHOW_UPDATE_V1 = "/api/v1/cms/show/update/";
export const USER_V1 = "/api/v1/users/";
export const UNIT_V1 = "/api/v1/units/";
export const CMS_UNIT_V1_1 = "/api/v1.1/cms/units/";
export const NOTIFICATION_V1 = "/api/v1/notifications/";
export const NOTIFICATION_CREATE = "/api/v1/notifications/create/";
export const CATEGORY_V1 = "/api/v1/categories/";
export const PUBLISH_EXPERIMENT = "/api/v1/cms/publish-experiment/";
export const UPDATE_MANAGER_V1 = "/api/v1/cms/update-manager/";
export const SHARE_LINK_V1 = "/api/v1/social/share-link/";
export const VALIDATE_SHOW_TITLE = "/api/v1/cms/show/validate-title/";
export const GET_ALL_GROUPS = `/api/v1/community/all/`;
export const GET_POSTS_FROM_GROUPS = `/api/v1/cms/posts/`;
export const POST_IN_GROUP = `/api/v1/community/post/add/`;
export const GET_SIGNED_URL_FOR_POSTS = `/api/v1/utility/presigned-url/?media_extension=mp4&file_type=post`;
export const DELETE_POST = `/api/v1/community/post/`;
export const EDIT_POST = `/api/v1/community/post/edit/`;
export const EDIT_TACK_WITH_CSV = `/api/v1/cms/track/`;
export const CREATE_SERIES = `/api/v1/cms/series-create/`;
export const CREATE_CREATOR = `/api/v1/cms/onboard-creator/`;
export const GET_CATEGORY_LIST = `/api/v1/cms/categories/`;
export const TOGGLE_CATEGORY_STATUS = `/api/v1/cms/category/`;
export const CREATE_CATEGORY = `/api/v1/cms/category/create/`;
export const EDIT_CATEGORY = `/api/v1/cms/category/`;

export const SIDE_NAVIGATION = [
  {
    id: 0,
    title: "Dashboard",
    icon: <DashboardIcon className="w-[17px] mx-auto" />,
    navTo: "/dashboard",
    requiredRoles: [TEAM_ROLES.creator],
  },
  {
    id: 1,
    title: "Shows",
    icon: <ShowsIcon className="w-[20px]" />,
    navTo: "/shows",
    // The roles mention in requiredRoles are allowed to access that particular tab
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.creator,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 2.1,
    title: "Videos",
    icon: <VideoIcon className="w-[20px]" />,
    navTo: "/videos",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.creator,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 2.2,
    title: "Feedbacks",
    icon: <FeedbackIcon className="w-[20px]" />,
    navTo: "/feedback",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.editor,
      TEAM_ROLES.viewer,
      TEAM_ROLES.creator,
    ],
  },
  {
    id: 3,
    title: "Experiments",
    icon: <ExperimentIcon className="w-[20px]" />,
    navTo: "/experiment",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 4,
    title: "Creators",
    icon: <CreatorsIcon className="w-[20px]" />,
    navTo: "/creators",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 5,
    title: "Tracks",
    icon: <TracksIcon className="w-[20px]" />,
    navTo: "/tracks",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 6,
    title: "Categories",
    icon: <CategoriesIcon className="w-[20px]" />,
    navTo: "/category",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 7,
    title: "Blogs",
    icon: <BlogsIcon className="w-[20px]" />,
    navTo: "/blogs",
    requiredRoles: [
      TEAM_ROLES.superAdmin,
      TEAM_ROLES.admin,
      TEAM_ROLES.blogger,
      TEAM_ROLES.viewer,
      TEAM_ROLES.editor,
    ],
  },
  {
    id: 8,
    title: "Team",
    icon: <TeamIcon className="w-[20px]" />,
    navTo: "/team",
    requiredRoles: [TEAM_ROLES.superAdmin],
  },
];

export const errorState = {
  error: "",
  type: "success",
};
