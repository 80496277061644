import { apiGateway } from "../../utils/config";
import { GET_CONFIG } from "../../utils/constants";
import { store } from "../store";
import ConfigActionTypes from "./config.types";

export const fetchConfigStart = () => ({
  type: ConfigActionTypes.FETCH_CONFIG_START,
});

export const fetchConfigSuccess = (data) => ({
  type: ConfigActionTypes.FETCH_CONFIG_SUCCESS,
  payload: data,
});

export const fetchConfigFailure = (errorMessage) => ({
  type: ConfigActionTypes.FETCH_CONFIG_FAILURE,
  payload: errorMessage,
});

export const fetchConfigStartAsync = (axiosPrivate, setErrorMsg) => {
    store.dispatch(fetchConfigStart());
    let url = apiGateway + GET_CONFIG;
      axiosPrivate.get(url)
      .then(({data, status}) => {
        if(status === 200){
          store.dispatch(fetchConfigSuccess(data));
        }
      })
      .catch((err) => {
        setErrorMsg({error: "Something went wrong", type: "failed"});
      });
};
