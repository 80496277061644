/* eslint-disable no-unused-vars */
import React from "react";
import SplashScreen from "../../assests/images/Splashscreen_xxxhdpi.png";
import "./login.styles.css";
import LoginForm from "../../components/Login-Form/login-form.component";
import PlayStoreLogo from "../../components/Common/Icons/PlayStoreLogo";
import AppStoreLogo from "../../components/Common/Icons/AppStoreLogo";

const Login = () => {
  return (
    <div>
      <div className="SW-wrapper-block">
        <div className="SW-splash-screen-left">
          <img src={SplashScreen} className="SW-splash-block-image" alt="" />
        </div>
        <div className="SW-splash-screen-form">
          <div className="flex justify-end gap-x-3">
            <a href="https://play.google.com/store/apps/details?id=com.seekho.android">
              <PlayStoreLogo className="w-[130px]" />
            </a>
            <a href="https://apps.apple.com/in/app/seekho-short-learning-videos/id6670752585">
              <AppStoreLogo className="w-[130px]" />
            </a>
          </div>

          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
