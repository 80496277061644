import React from "react";
import { Dropdown } from "react-bootstrap";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";
import { TrendingUp } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import placeholder from "../../assests/images/placeholder1.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const ShowRequiredDetails = ({
  data,
  toggleCreate,
  isAdmin,
  isSuperAdmin,
  hasAccessToActions,
  isEditor,
  setDialogStatus,
}) => {
  const history = useHistory();

  const handleRouteToCreator = ({ creatorId }) => {
    history.push(`/creators/${creatorId}`);
  };

  return (
    <div>
      <Accordion className="mt-3 shadow-none rounded-lg">
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="w-full mx-auto flex justify-between items-center px-4">
            <div className="flex gap-x-4 lg:w-[82%] lg:!items-center">
              {/* image */}
              <div>
                <img
                  src={data?.image || placeholder}
                  alt={data?.title}
                  className="w-[50px] h-[50px] rounded"
                />
              </div>

              {/* Details */}
              <div>
                <div className="flex gap-x-6 text-[14px] text-gray-500 flex-col flex-wrap lg:!flex-row ">
                  <h6 className="!text-[16px] font-semibold text-black max-w-[230px]">
                    {data?.title}
                  </h6>
                  <div
                    className={`capitalize w-fit !px-4 rounded-2xl text-[12px] py-[1px] h-fit ${handleSeriesStatusColor(
                      { status: data?.status }
                    )}`}
                  >
                    {data?.status}
                  </div>
                  <button
                    className="!p-0 !h-fit hover:underline"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRouteToCreator({ creatorId: data?.creator?.id });
                    }}
                  >
                    {data?.creator?.name}
                  </button>
                  <div>{data?.category?.title}</div>
                  {/* <div>{`#${data?.id}`}</div> */}
                  <div>{`${data?.n_series} videos`}</div>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-2 flex-col lg:!flex-row">
              {data?.topic_sheet_link && (
                <a
                  href={data?.topic_sheet_link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                >
                  <div className="flex items-center space-x-4 text-sm text-gray-600">
                    <button className="bg-baseBlueColor text-white px-3 py-2 rounded-full text-[14px] flex items-center">
                      <TrendingUp className="mr-2 h-4 w-4" />
                      Topics
                    </button>
                  </div>
                </a>
              )}

              {hasAccessToActions && (
                <div onClick={(e) => e.stopPropagation()}>
                  {/* Prevent accordion toggle */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-action !shadow-none !outline-none focus:!shadow-none focus:!outline-none"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisH}
                        className="rejection-logs mr-3 ml-0"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2">
                      <Dropdown.Item
                        className="action-menu-item"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent accordion toggle
                          toggleCreate();
                        }}
                      >
                        Edit Show
                      </Dropdown.Item>
                      {(isAdmin ||
                        isSuperAdmin ||
                        (isEditor && data?.status === "live")) && (
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            setDialogStatus({
                              open: true,
                              type: data?.status == "draft" ? "live" : "draft",
                              msg:
                                data?.status == "draft"
                                  ? "Make this show live"
                                  : "Move this show to draft",
                            });
                          }}
                        >
                          {data?.status == "draft"
                            ? "Make Live"
                            : "Move to Draft"}
                        </Dropdown.Item>
                      )}
                      {data?.status === "draft" && (
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            setDialogStatus({
                              open: true,
                              type: "deleted",
                              msg: "Delete this show",
                            });
                          }}
                        >
                          Delete Show
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="-mt-5">
          <div className="flex px-4 w-[85%]">
            <img
              src={data?.image || placeholder}
              alt={data?.title}
              className="w-[50px] h-[50px] rounded opacity-0"
            />
            <h6 className="px-3 text-[14px] text-gray-500 mt-1 leading-[23px] pb-2">
              {data?.description}
            </h6>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ShowRequiredDetails;
