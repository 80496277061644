import React from "react";
import { tracksTableHeading } from "../../utils/constants/Tracks";
import { getDateString } from "../../utils/utils";
import { Chip } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const TrackListingTable = ({
  data,
  internalTeamAccessGlobalState,
  setSelectTrack,
  setIsDialogDefault,
}) => {
  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right">
        <thead className="border-b bg-gray-100">
          <tr>
            {tracksTableHeading.map((item) => {
              return (
                <th
                  scope="col"
                  className="px-6 py-3 !text-[12px] !text-gray-700 font-medium bg-gray-100 z-[999]"
                  key={item}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            return (
              <tr
                className="cursor-pointer !font-normal border-b text-[12px] last:border-none"
                key={item?.id}
              >
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                  {item?.id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[15px] ">
                  {item?.title}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {getDateString(
                    new Date(item?.created_on).toISOString().split("T")[0]
                  )}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {getDateString(
                    new Date(item?.updated_on).toISOString().split("T")[0]
                  )}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <Chip
                    label={item?.is_active ? "Active" : "InActive"}
                    className={
                      item?.is_active ? "track-active" : "track-in_active"
                    }
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <Chip
                    label={item?.is_default ? "Default" : "No"}
                    className={
                      item?.is_default ? "track-default" : "track-in_active"
                    }
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <Chip
                    label={item?.is_experiment ? "Yes" : "No"}
                    className={
                      item?.is_experiment ? "track-active" : "track-in_active"
                    }
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <Chip
                    label={item?.onboarding_status ? "Yes" : "No"}
                    className={
                      item?.onboarding_status
                        ? "track-active"
                        : "track-in_active"
                    }
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.score}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Dropdown className="w-100">
                    <Dropdown.Toggle className="dropdown-action">
                      <FontAwesomeIcon
                        className="rejection-logs ml-1"
                        icon={faEllipsisV}
                        size="1x"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="action-menu">
                      <Dropdown.Item
                        className="action-menu-item"
                        style={{ padding: 0 }}
                        href={`#/track-edit/${item?.id}`}
                      >
                        View details
                      </Dropdown.Item>
                      {internalTeamAccessGlobalState && (
                        <div>
                          {!item?.is_default && (
                            <Dropdown.Item
                              className="action-menu-item"
                              onClick={() => {
                                setSelectTrack(item);
                                setIsDialogDefault(true);
                              }}
                            >
                              Make Default
                            </Dropdown.Item>
                          )}
                        </div>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TrackListingTable;
