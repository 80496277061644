import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { responseDefaultState } from "../../utils/constants/Feedback";
import { apiGateway } from "../../utils/config";
import { errorState } from "../../utils/constants";
import {
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleRemoveParticularFilter,
} from "../../utils/utils";
import UIListing from "./UIListing";
import CustomSearch from "../Common/CustomSearch";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import ShowPills from "../Common/ShowPills";
import ShowPillsSkeleton from "../Skeletons/ShowPillsSkeleton";
import FeedbackListingSkeleton from "../Skeletons/FeedbackListingSkeleton";
import FilterButton from "../Common/FilterButton";
import FilterDrawer from "./FilterDrawer";
import QueryValueChip from "../Common/QueryValueChip";

const Feedback = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [responseData, setResponseData] = useState(responseDefaultState);
  const history = useHistory();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [, setPageNumber] = useState(searchParamPage);
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamSortingKey = searchParams?.get("s") || "";
  const searchParamSortBy = searchParams?.get("order") || "";
  const searchParamShowSlug = searchParams?.get("show") || "";
  const searchParamFilters = searchParams?.getAll("filter") || [];
  const searchParamReportedFilter = searchParams?.getAll("reported") || [];
  const searchParamReactionFilter = searchParams?.getAll("reaction") || [];

  const handleGetFeedback = async ({ query, pageArg }) => {
    try {
      setIsLoading(true);
      // Append filters to URL
      const appendReactionFilter = searchParamReactionFilter
        ?.map((item) => `&filter_by=${item}`)
        .join("");

      const appendReportedFilter = searchParamReportedFilter
        .map((item) => `&is_reported=${item}`)
        .join("");

      let url = `${apiGateway}/api/v1/cms/creator-feedback/?page=${pageArg}&page_size=20&q=${query}${
        searchParamSortingKey
          ? `&s=${searchParamSortingKey}&order=${searchParamSortBy}`
          : ""
      }${searchParamShowSlug ? `&show_slug=${searchParamShowSlug}` : ""}`;

      if (appendReactionFilter) url += appendReactionFilter;
      if (appendReportedFilter) url += appendReportedFilter;

      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        const feedbacks = data?.feedbacks;
        const shows = data?.shows;
        const nPages = data?.n_pages;
        const hasMore = data?.has_more;
        setResponseData({ feedbacks, nPages, hasMore, shows });
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // onPage change
  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  // on hitting enter after search query
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  // onChange function on selected filters
  const handleFilterChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevSelectedFilters) => {
      if (checked) {
        // Add the selected filter
        return [...prevSelectedFilters, value];
      } else {
        // Remove the unselected filter
        return prevSelectedFilters.filter((filter) => filter !== value);
      }
    });
  };

  // Apply filters function
  const handleApplyFilters = () => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 }); // setting to page 1
    // Separating the filters
    const separatedReactionFilters = selectedFilters?.filter(
      (item) => item !== "true" && item !== "false"
    );

    const separatedReportedFilters = selectedFilters.filter(
      (item) => item === "true" || item === "false"
    );

    // Appending the filters to URL
    handleAppendFiltersToUrl({
      filters: separatedReactionFilters,
      history,
      filterName: "reaction",
    });
    handleAppendFiltersToUrl({
      filters: separatedReportedFilters,
      history,
      filterName: "reported",
    });
    setOpenFilters(false);
  };

  //Remove Filters function
  const handleRemoveFilter = ({ filterName, filterToRemove }) => {
    handleRemoveParticularFilter({ filterName, filterToRemove, history });
  };

  // when clicked on particular show
  const handleOnShowClick = ({ showSlug }) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    handleAppendQueryToUrl({
      history,
      queryName: "show",
      queryValue: showSlug,
    });
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order"],
    });
  };

  // when clicked on All Show CTA Pill
  const handleClickOnAllShowCTA = () => {
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order", "show"],
    });
  };

  // useEffect handling all the API calls based on sort and filters
  useEffect(() => {
    handleGetFeedback({
      query: searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [
    searchParamSearchQuery,
    searchParamPage,
    searchParamSortingKey,
    searchParamSortBy,
    searchParamShowSlug,
    JSON.stringify(searchParamReactionFilter), // render the useEffect when any of the value changes in the array
    JSON.stringify(searchParamReportedFilter), // render the useEffect when any of the value changes in the array
  ]);

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  return (
    <div>
      <div>
        <div>
          <div className="flex gap-x-3">
            <div className="lg:w-[50%]">
              <CustomSearch
                placeHolder="Search by video id or title"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleInputKey={handleInputKey}
                handleClearInput={() => {
                  setSearchQuery("");
                  handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
                }}
              />
            </div>
            <FilterButton
              onClick={() => {
                setOpenFilters(true);
                const combinedFilters = [
                  ...searchParamReportedFilter,
                  ...searchParamReactionFilter,
                ];
                setSelectedFilters(combinedFilters);
              }}
            />
          </div>

          <div className="flex gap-x-2 mt-1">
            {searchParamReactionFilter?.map((item) => {
              return (
                <QueryValueChip
                  label={item}
                  onDelete={() => {
                    handleRemoveFilter({
                      filterName: "reaction",
                      filterToRemove: item,
                    });
                  }}
                />
              );
            })}

            {searchParamReportedFilter?.map((item) => {
              return (
                <QueryValueChip
                  label={item === "true" ? "Reported" : "Not Reported"}
                  onDelete={() => {
                    handleRemoveFilter({
                      filterName: "reported",
                      filterToRemove: item,
                    });
                  }}
                />
              );
            })}
          </div>

          <h6 className="!text-[20px] mt-2 font-medium">Feedbacks</h6>

          {/* Show Listing Pills */}
          {isLoading ? (
            <div className="mt-4">
              <ShowPillsSkeleton />
              <div className="mt-2">
                <FeedbackListingSkeleton />
              </div>
            </div>
          ) : (
            <div>
              {responseData?.shows?.length > 0 ? (
                <div className="mt-3">
                  <ShowPills
                    shows={responseData?.shows}
                    searchParamShowSlug={searchParamShowSlug}
                    handleClickOnAllShowCTA={handleClickOnAllShowCTA}
                    handleOnShowClick={handleOnShowClick}
                  />
                </div>
              ) : null}

              {/* Table UI with error handling & Pagination */}
              {responseData?.feedbacks?.length === 0 ? (
                <div className="!bg-white h-[calc(100vh-200px)] flex items-center justify-center mt-2">
                  <NoFeedbackSvj
                    className="w-[250px] mx-auto"
                    displayText={
                      isError
                        ? "Something Went Wrong, Please Try Later"
                        : "You don’t have any feedbacks on this video yet"
                    }
                    displayTextClass="mt-2 text-gray-400 text-[14px]"
                  />
                </div>
              ) : (
                <div className="!overflow-x-auto rounded-md !bg-white mt-2 pb-4 border-t">
                  <UIListing data={responseData?.feedbacks} />
                  {responseData?.nPages > 1 && (
                    <Pagination
                      className="flex justify-center mt-5"
                      shape="rounded"
                      count={responseData?.nPages}
                      page={searchParamPage}
                      onChange={(e, val) => handlePageChange(e, val)}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Filter Drawer */}
      <FilterDrawer
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        selectedFilters={selectedFilters}
        handleFilterChange={handleFilterChange}
        handleApplyFilters={handleApplyFilters}
        handleClearAllFilters={() => {
          handleDeleteQueryFromUrl({
            history,
            queryNames: ["reaction", "reported"],
          });
        }}
      />

      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default Feedback;
