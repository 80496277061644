import React, { useEffect, useState } from "react";
import { errorState } from "../../utils/constants";
import { apiGateway } from "../../utils/config";
import Metrics from "./Metrics";
import LatestVideos from "./LatestVideos";
import EditProfileModal from "../Common/EditProfileModal";
import AlertComponent from "../Alert-Messages/alert-component.component";
import LatestVideosSkeleton from "../Skeletons/CreatorDash/LatestVideosSkeleton";
import MetricsSkeleton from "../Skeletons/CreatorDash/MetricsSkeleton";
import ProfileDetails from "./ProfileDetails";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";

const CreatorDash = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [data, setData] = useState({ creatorStats: {}, series: [] });
  const axiosPrivate = useAxiosPrivate();

  // API Function Call to get the Metrics and SeriesData
  const handleGetData = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/creator-dashboard/`;
      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        setIsLoading(false);
        setData({
          creatorStats: data?.creator_stats,
          series: data?.series_list,
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
      setTimeout(() => {
        setIsError(true);
        setIsLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div>
      <div>
        <ProfileDetails setOpenUpdateProfile={setOpenUpdateProfile} />
      </div>

      {isError ? (
        <div className="!bg-white h-[calc(100vh-170px)] flex items-center justify-center mt-4">
          <NoFeedbackSvj
            className="w-[250px] mx-auto"
            displayText={
              isError
                ? "Something Went Wrong, Please Try Later"
                : "You don’t have any feedbacks on this video yet"
            }
            displayTextClass="mt-2 text-gray-400 text-[14px]"
          />
        </div>
      ) : (
        <div>
          <div className="mt-4">
            {isLoading ? (
              <MetricsSkeleton />
            ) : (
              <Metrics data={data?.creatorStats} />
            )}
          </div>

          <div className="mt-4">
            {isLoading ? (
              <LatestVideosSkeleton />
            ) : (
              <LatestVideos series={data?.series} />
            )}
          </div>
        </div>
      )}

      {/* Edit User Profile Modal */}
      {openUpdateProfile && (
        <EditProfileModal
          openModal={openUpdateProfile}
          setMessage={setMessage}
          handleCloseModal={() => setOpenUpdateProfile(false)}
        />
      )}
      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default CreatorDash;
