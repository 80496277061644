const isLoadingState = {
  getBlog: false,
  createBlog: false,
  publishBlog: false,
  unPublishBlog: false,
  deleteBlog: false,
};

const apiErrorState = {
  noResponse: false,
  apiFail: false,
};

const blogsDetailsState = {
  title: "",
  description: "",
  displayImage: null,
};

const allBlogsState = {
  blogs: [],
  nPages: 0,
};

export { isLoadingState, apiErrorState, blogsDetailsState, allBlogsState };
