import React from 'react';
import './notification.styles.css';
import AddNotificationComponent from '../../components/Add-Notification/add-notifications.component';
import { withRouter } from "react-router-dom";

 function Notification({match, history}) {
    return (
        <div className='notifications-module'>
            <AddNotificationComponent match={match} history={history} />
        </div>
    )
}

export default withRouter(Notification);
