import VideoActionTypes from "./video.types";
import { apiGateway } from "../../utils/config";
import { BULK_DELETE_VIDEOS, GET_CONFIG, GET_VIDEO_UNITS, UNIT_V1, USER_V1 } from "../../utils/constants";

export const setCurrentPageNumber = (pageNumber) => {
  return {
    type: VideoActionTypes.SET_CURRENT_VIDEO_PAGE_NUMBER,
    payload: pageNumber,
  };
};
export const setFilters = (filters) => {
  return {
    type: VideoActionTypes.SET_FILTERS,
    payload: filters,
  };
};

export const setHasMore = (hasMore) => {
  return {
    type: VideoActionTypes.SET_HAS_MORE,
    payload: hasMore,
  };
};

export const fetchVideosStart = () => ({
  type: VideoActionTypes.FETCH_VIDEOS_START,
});

export const fetchVideosSuccess = (data, pageNumber) => ({
  type: VideoActionTypes.FETCH_VIDEOS_SUCCESS,
  payload: {
    hasMore: data.has_more,
    table_data: {
      n_content_units: data.n_content_units,
      n_pages: data.n_pages,
      page: data.page
    },
    videosFetched: data.units ? data.units : data.content_units,
    pageNumber,
  },
});

export const initializeVideos = () => ({
  type: VideoActionTypes.VIDEOS_INITIALIZE,
});

export const fetchVideosFailure = (errorMessage) => ({
  type: VideoActionTypes.FETCH_VIDEOS_FAILURE,
  payload: errorMessage,
});

export const updateVideo = (data) => {
  return ({
    type: VideoActionTypes.UPDATE_SINGLE_VIDEO,
    payload: data,
  })
}

export const addVideo = (data) => {

  return ({
    type: VideoActionTypes.ADD_VIDEO,
    payload: data,
  })
}

export const fetchVideosStartAsync = (
  axiosPrivate,
  pageNumber,
  filters,
  sortKey,
  reverse,
  searchedName,
  setErrorMsg
) => {
  let url = "";
  return async(dispatch, getState) => {
    const { user } = getState();
    const isAdmin = user.currentUser?.is_admin;
    const userId = user.currentUser.id;

    if (isAdmin) {
      if (pageNumber === 1) {
        dispatch(initializeVideos());
      }
      url = `${apiGateway}${GET_VIDEO_UNITS}?page=${pageNumber}`;
      if (searchedName) url += `&q=${searchedName}`;

      if (filters) {
        const categoryArray = filters.get("categoryFilter");
        const statusArray = filters.get("statusFilter");
        const creatorArray = filters.get("creatorFilter");

        const involvesActivityArray = filters.get("involvesActivityFilter");

        if (categoryArray && categoryArray.length !== 0) {
          for (let i = 0; i < categoryArray.length; i++) {
            const categoryId = categoryArray[i];
            url += `&category_ids=${categoryId}`;
          }
        }
        if (statusArray && statusArray.length !== 0) {
          for (let i = 0; i < statusArray.length; i++) {
            const statusValue = statusArray[i];
            url += `&status=${statusValue}`;
          }
        }
        if (creatorArray && creatorArray.length !== 0) {
          for (let i = 0; i < creatorArray.length; i++) {
            const creatorId = creatorArray[i].id || creatorArray[i];
            url += `&creator_ids=${creatorId}`;
          }
        }
        if (involvesActivityArray && involvesActivityArray.length !== 0) {
          for (let i = 0; i < involvesActivityArray.length; i++) {
            const involvesActivityValue = involvesActivityArray[i];
            url += `&involves_activity=${involvesActivityValue}`;
          }
        }
        if (sortKey) {
          url += `&sorting_key=${sortKey}&reverse=${reverse}`;
        }
      }

      dispatch(fetchVideosStart());
      axiosPrivate.get(url)
      .then(async({data, status}) => {
        if (!data?.status_code || status === 200) {
          dispatch(fetchVideosSuccess(data, pageNumber));
        }
      })
      .catch((err) => {
        setErrorMsg({error: "Something went wrong", type: "failed"});
      });
    } else {
      url = `${apiGateway}${USER_V1}${userId}/units/?page=${pageNumber}`;
      dispatch(fetchVideosStart());
        axiosPrivate.get(url)
        .then(({data, status}) => {
          if(status === 200)
            dispatch(fetchVideosSuccess(data, pageNumber));
        })
        .catch((err) => {
          setErrorMsg({error: "Something went wrong", type: "failed"});
        });
    }
  };
};

export const deleteSingleVideoStart = () => ({
  type: VideoActionTypes.DELETE_SINGLE_VIDEO_START,
});

export const deleteSingleVideoSuccess = (slug) => ({
  type: VideoActionTypes.DELETE_SINGLE_VIDEO_SUCCESS,
  payload: slug,
});

export const deleteSingleVideoFailure = (errorMessage) => ({
  type: VideoActionTypes.DELETE_SINGLE_VIDEO_FAILURE,
  payload: errorMessage,
});

export const deleteSingleVideoStartAsync = (axiosPrivate, slug, setErrorMsg) => {
  return async (dispatch) => {
    dispatch(deleteSingleVideoStart());
      let url = `${apiGateway}${UNIT_V1}${slug}/delete/`
      axiosPrivate.delete(url)
      .then(({data, status}) => {
        if(status === 200){
          setErrorMsg({error: 'Video deleted successfully', type: "success"});
          dispatch(deleteSingleVideoSuccess(slug));
        }
      })
      .catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Unable to delete videos", type: "failed"});
      });
  };
};

export const deleteMultipleVideoStart = () => ({
  type: VideoActionTypes.DELETE_MULTIPLE_VIDEOS_START,
});

export const deleteMultipleVideosSuccess = (data) => ({
  type: VideoActionTypes.DELETE_MULTIPLE_VIDEOS_SUCCESS,
  payload: { hasMore: data.has_more, videosFetched: data.content_units },
});

export const deleteMultipleVideosFailure = (errorMessage) => ({
  type: VideoActionTypes.DELETE_MULTIPLE_VIDEOS_FAILURE,
  payload: errorMessage,
});

export const deleteMultipleVideoStartAsync = (axiosPrivate, deleteVideoIds, setErrorMsg) => {
  const contentUnit_Ids = { contentunit_ids: deleteVideoIds };
  return async (dispatch) => {
    dispatch(deleteMultipleVideoStart());
      axiosPrivate.post(`${apiGateway}${BULK_DELETE_VIDEOS}`, contentUnit_Ids)
      .then(({data, status}) => {
        if(status === 200 ){
          dispatch(deleteMultipleVideosSuccess(data));
          setErrorMsg({error: 'Video deleted successfully', type: "success"});
        }
      })
      .catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"});
      });
  };
};

export const fetchFiltersStart = () => ({
  type: VideoActionTypes.FETCH_FILTERS_START,
});

export const fetchFiltersSuccess = (data) => ({
  type: VideoActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { filtersFetched: data },
});

export const fetchFiltersFailure = (errorMessage) => ({
  type: VideoActionTypes.FETCH_FILTERS_FAILURE,
  payload: errorMessage,
});

export const fetchFiltersStartAsync = (axiosPrivate, setErrorMsg) => {
  return async(dispatch) => {
    dispatch(fetchFiltersStart());
      axiosPrivate.get(apiGateway + GET_CONFIG)
      .then(({data, status}) => {
        if(status === 200)
          dispatch(fetchFiltersSuccess(data));
      })
      .catch(({response}) => {
        setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"});
      });
  };
};

export const clearState = () => ({
  type: VideoActionTypes.CLEAR_ALL,
});
