import React from "react";
import TeamListing from "../../components/Team";

const TeamMembersPage = () => {
  return (
    <div className="mt-3">
      <TeamListing />
    </div>
  );
};

export default TeamMembersPage;
