import React from "react";
import BlogListing from "../../components/Blogs/BlogListing";

const BlogsPage = () => {
  return (
    <div>
      <BlogListing />
    </div>
  );
};

export default BlogsPage;
