import { axiosPublic } from "../api/axiosCustom";
import { logout, setCurrentUser } from "../redux/user/user.action";
import { apiGateway } from "../utils/config";
import { GET_ACCESS_TOKEN, GET_ACCESS_TOKEN_LOCAL } from "../utils/constants";
import Cookies from "js-cookie";
import useZustandStore from "../zustandStore/useZustandStore";

const useRefreshToken = () => {
  const setOpenSessionExpiredModal = useZustandStore(
    (val) => val?.setOpenSessionExpiredModal
  );

  var responseToken = "";
  const refresh = async () => {
    let refresh_token_expiry = parseInt(
      Cookies.get("refresh_token_expiry_seekho")
    );
    let newEpochTime = parseInt(new Date().getTime() / 1000);
    if (refresh_token_expiry - newEpochTime < 0) {
      logout(
        setCurrentUser,
        () => {},
        () => {},
        "refresh_token"
      );
    } else {
      let hostName_endPoint =
        window.location.hostname !== "localhost"
          ? GET_ACCESS_TOKEN
          : GET_ACCESS_TOKEN_LOCAL;
      const url = apiGateway + hostName_endPoint;
      await axiosPublic
        .post(
          url,
          {},
          {
            withCredentials: false,
            headers: {
              Authorization: `jwt ${Cookies.get("refresh_token")}`,
            },
          }
        )
        .then(({ data, status }) => {
          if (status === 200) {
            responseToken = data?.access_token;
            if (process.env.NODE_ENV === "development") {
              Cookies.set("access_token", responseToken, { expires: 7 });
            }
            Cookies.set(
              "access_token_expiry_seekho",
              data?.access_token_expiry,
              { expires: 7 }
            );
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) setOpenSessionExpiredModal(true);
          if (err?.message == "Refresh Token is expired") {
            logout(
              setCurrentUser,
              () => {},
              () => {},
              "refresh_token"
            );
          }
          console.log(err, "error while renew access token");
        });
    }
    return responseToken;
  };
  return refresh;
};

export default useRefreshToken;
