import React from 'react'
import { Modal } from 'react-bootstrap';
import Type1 from  '../../assests/images/Type1.png';
import Type2 from  '../../assests/images/Type2.png';
import Type3 from  '../../assests/images/Type3.png';

const LayoutType = ({layoutTitle, show, handleClose, selectedlayout, setSelectedLayout }) => {
  return (
    <div>
        <Modal className="modalvideo" size="lg" centered show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <div className="modal-video-title">{layoutTitle}</div>
            </Modal.Header>
            <Modal.Body style={{
              background: "#f4f8fc",
              padding: 20
            }}>
              <div className='layout-parent-wrapper'>
                <div className='layout-type-select' onClick={() => {
                    setSelectedLayout('default');
                    handleClose();
                  }} style={{borderColor: selectedlayout == 'default' && '#534f8f'}}>
                  <p>Text</p>
                  <img src={Type1} className='layout-type-image' />
                </div>
                <div className='layout-type-select' onClick={() => {
                    setSelectedLayout('title_image');
                    handleClose();
                  }} style={{borderColor: selectedlayout == 'title_image' && '#534f8f'}}>
                  <p>Text & Image</p>
                  <img src={Type2} className='layout-type-image'/>
                </div>
                <div className='layout-type-select'  onClick={() => {
                    setSelectedLayout('title_image_play');
                    handleClose();
                  }} style={{borderColor: selectedlayout == 'title_image_play' && '#534f8f'}}>
                  <p>Text, Image & CTA</p>
                  <img src={Type3} className='layout-type-image'/>
                </div>
              </div>
            </Modal.Body>
        </Modal>
  </div>
  )
}

export default LayoutType;