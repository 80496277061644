/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import {
  CATEGORY_CMS_ALL,
  GET_CREATOR_LIST,
  SHOW_CREATE_V1,
  SHOW_UPDATE_V1,
} from "../../utils/constants";
import "./create-show.styles.css";
import { Container, Form } from "react-bootstrap";
import { Button } from "@mui/material";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploader from "../Common/FileUploader";
import RequiredStar from "../Common/RequiredStar";
import useZustandStore from "../../zustandStore/useZustandStore";

function CreateShow({ toggleCreate, show_data }) {
  const axiosPrivate = useAxiosPrivate();
  const [searchInput, setSearchInput] = useState("");
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [editBannerImage, setEditBannerImage] = useState("");
  const [category, setCategory] = useState({});
  const [description, setDescription] = useState("");
  const [topicSheetLink, setTopicSheetLink] = useState("");
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [showLoader, setShowLoader] = useState(false);
  const [creators, setCreators] = useState([]);
  const [creator, setCreator] = useState(null);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState("");
  const [showSlug, setShowSlug] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val.internalTeamAccessGlobalState
  );

  useEffect(() => {
    if (Object.keys(show_data || {})?.length > 0) {
      setTitle(show_data?.title);
      setShowSlug(show_data?.slug);
      setDescription(show_data?.description ?? "");
      setCreator(show_data?.creator);
      setCategory(show_data?.category);
      handleSelectCreator(show_data?.creator);
      setImage(show_data?.image);
      setEditBannerImage(show_data?.banner_image);
      setIsEdit(true);
      setTopicSheetLink(show_data?.topic_sheet_link);
    }
  }, [show_data]);

  useEffect(() => {
    let url = `${apiGateway}${CATEGORY_CMS_ALL}?page=1&default_only=true`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setCategories([...data.categories]);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Category failed to fetch",
        });
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let missingFields = [];
    if (!title) missingFields.push("Title");
    if (!creator?.id) missingFields.push("Creator");
    if (!category?.id) missingFields.push("Category");
    if (!isEdit && !thumbnail) missingFields.push("Thumbnail");
    if (!isEdit && !bannerImage) missingFields.push("Banner Image");
    if (!description) missingFields.push("Description");
    if (missingFields.length > 0) {
      setErrorMsg({
        type: "failed",
        error: `Please enter the following fields: ${missingFields.join(", ")}`,
      });
      return;
    }
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("category_id", category?.id);
    data.append("creator_id", creator?.id);
    data.append("image", thumbnail);
    data.append("banner_image", bannerImage);
    if (internalTeamAccessGlobalState && topicSheetLink)
      data.append("topic_sheet_link", topicSheetLink);
    setShowLoader(true);
    let url = `${apiGateway}${SHOW_CREATE_V1}`;
    if (isEdit) {
      data.append("slug", showSlug);
      url = `${apiGateway}${SHOW_UPDATE_V1}`;
    }
    try {
      const response = await axiosPrivate.post(url, data);
      if (response?.data) {
        setShowLoader(false);
        setErrorMsg({
          type: "success",
          error: isEdit
            ? "Show Updated Successfully"
            : "Show Created Successfully",
        });
      }
      toggleCreate(e, true);
    } catch (error) {
      setShowLoader(false);
      setErrorMsg({
        type: "failed",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleSearchCreator = async (e) => {
    const searchName = e.target.value;
    setSearchInput(searchName);
    if (searchName.length >= 3) {
      const url = `${apiGateway}${GET_CREATOR_LIST}?page=${1}&q=${searchName}`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (Object.keys(data).length !== 0 && status === 200)
            setCreators(data.users);
        })
        .catch(({ response }) => {
          setErrorMsg({
            type: "failed",
            error: response?.data
              ? response?.data?.error_message
              : "Failed to fetch creator",
          });
        });
    } else {
      setCreators([]);
      setCreator(null);
    }
  };

  const handleSelectCreator = (creator) => {
    setCreator(creator);
    setSearchInput(creator.name);
  };

  const handleBannerImageChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (aspectRatio === 800 / 480) {
          setBannerImage(file);
        } else {
          setErrorMsg({
            type: "failed",
            error: "Banner must have an aspect ratio of 800:480",
          });
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  // const validateShowTitles = async () => {
  //   const url = `${apiGateway}${VALIDATE_SHOW_TITLE}`;

  //   if (title == ''){
  //     setErrorMsg({
  //       type: "failed",
  //       error: "Please enter show title.",
  //     });
  //     return;
  //   }
  //   const data = new FormData();
  //   data.append("show_title", title);
  //   axiosPrivate.post(url, data)
  //   .then(async ({data, status}) => {
  //     if(status === 200){
  //       setTitleVerified(true);
  //     }else{
  //       setTitleVerified(false);
  //     }
  //   })
  //   .catch(({response}) => {
  //     setErrorMsg({
  //       type: "failed",
  //       error: response?.data ? response?.data?.error_message : "Something went wrong",
  //     });
  //     setTitleVerified(false);
  //   });
  // };

  return (
    <div>
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />
      <Container className="create-series">
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Label>
              Title <RequiredStar />
            </Form.Label>
            <div className="series-title-input mb-2">
              <Form.Control
                type="text"
                placeholder="Enter the title of shows"
                required
                name="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {/* <Button variant="outlined" disabled={title == '' || titleVerified} className="cta-update-series-cancel ml-2" onClick={validateShowTitles}>
                Validate
              </Button> */}
            </div>
            <div>
              <Form.Group>
                <Form.Label>
                  Creator <RequiredStar />
                </Form.Label>
                <div className="search-select">
                  <div className="dropdown">
                    <input
                      autoComplete="off"
                      value={searchInput}
                      name="searchInput"
                      placeholder="Enter Creator Name"
                      onChange={handleSearchCreator}
                    ></input>
                    <div className="dropdown-content">
                      {creators?.map((creator, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handleSelectCreator(creator)}
                          >
                            {creator.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>
                  Icon <RequiredStar />
                </Form.Label>
                <br />
                <div>
                  <FileUploader
                    accept=".png, .jpeg, .jpg"
                    displayText="Select Icon"
                    key={thumbnail?.name || ""}
                    displayIcon={<FontAwesomeIcon icon={faImage} />}
                    onChange={(e) => {
                      setThumbnail(e.target.files[0]);
                    }}
                  />

                  {thumbnail ? (
                    <div className="w-[100px] mx-auto text-center">
                      <img
                        src={URL.createObjectURL(thumbnail)}
                        alt="thumbnailImage"
                      />
                      <FontAwesomeIcon
                        className="mt-1 cursor-pointer"
                        icon={faTrashAlt}
                        onClick={() => setThumbnail(null)}
                      />
                    </div>
                  ) : (
                    image && (
                      <div className="w-[100px] mx-auto">
                        <img src={image} alt="thumbnailImage" />
                      </div>
                    )
                  )}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Banner Image <RequiredStar />
                </Form.Label>
                <br />
                <div>
                  <FileUploader
                    accept=".png, .jpeg, .jpg"
                    displayText="Select Banner Image (Make sure banner dimensions are 800x480)"
                    displayIcon={<FontAwesomeIcon icon={faImage} />}
                    key={bannerImage?.name || ""}
                    onChange={handleBannerImageChange}
                  />

                  {bannerImage ? (
                    <div className="w-[200px] mx-auto text-center">
                      <img src={URL.createObjectURL(bannerImage)} />
                      <FontAwesomeIcon
                        className="mt-1 cursor-pointer"
                        icon={faTrashAlt}
                        onClick={() => setBannerImage(null)}
                      />
                    </div>
                  ) : (
                    editBannerImage && (
                      <div className="w-[200px] mx-auto">
                        <img src={editBannerImage} alt="bannerImage" />
                      </div>
                    )
                  )}
                </div>
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  Select Category <RequiredStar />
                </Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={category.id}
                  onChange={(e) => {
                    let category = categories.filter(
                      (cat) => cat.id === parseInt(e.target.value)
                    );
                    setCategory(category[0]);
                  }}
                >
                  <option value={""}>None</option>
                  {categories?.map((cat) => (
                    <option value={cat?.id} key={cat.id}>
                      {cat?.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {internalTeamAccessGlobalState && (
                <Form.Group>
                  <Form.Label>Topic Sheet</Form.Label>
                  <Form.Control
                    as="input"
                    rows={1}
                    placeholder="Paste the sheet link"
                    name="topicSheet"
                    value={topicSheetLink}
                    onChange={(e) => setTopicSheetLink(e.target.value)}
                  />
                </Form.Group>
              )}

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  Description <RequiredStar />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the description of shows"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="center">
              <Button
                type="submit"
                disabled={showLoader}
                variant="outline-primary"
                active
                className="cta-update-series"
              >
                {!showLoader ? (isEdit ? "Update" : "Create") : "Saving...."}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default CreateShow;
