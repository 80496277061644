import {
  Box,
  Button,
  Card,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchExperimentsStartAsync,
  intializeExperiments,
  setAppliedFilter,
  setExperimentsFilter,
} from "../../redux/experiments/experiments.action";
import {
  filterKeywordToBackendKeywords,
  getDateString,
} from "../../utils/utils";
import NoImg from "../../assests/images/nodatafound.png";
import placeholder from "../../assests/images/placeholder1.png";
import "./experiments-list.styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SeriesExperiments from "../Series-Experiments/series-experiments.component";
import { Preview } from "@mui/icons-material";
import useZustandStore from "../../zustandStore/useZustandStore";
import FilterApplyCancelButtons from "../Common/FilterApplyCancelButtons";

const quickRowFilters = [
  {
    id: "Series CTR",
    key: "series-ctr",
    index: 0,
    show: true,
  },
  {
    id: "Series Remake",
    key: "series-remake",
    index: 1,
    show: true,
  },
  {
    id: "Series Default",
    key: "default-remake-series",
    index: 2,
    show: true,
  },
];

export default function ExperimentsList() {
  //dispatch
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  //redux selectors
  const series = useSelector((state) => state.experiments.series);
  const hasMoreSeries = useSelector((state) => state.experiments.hasMore);
  const isFetchingSeries = useSelector((state) => state.experiments.isFetching);
  const experimentsFilters = useSelector((state) => state.experiments.filters);
  const appliedFilters = useSelector(
    (state) => state.experiments.appliedFilters
  );
  const errorMessage = useSelector((state) => state.experiments.errorMessage);
  const searchedString = useSelector(
    (state) => state.experiments.searchedString
  );
  const [seriesData, setSeriesData] = useState({});
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "" });
  const pageData = useSelector((state) => state.experiments?.table_data);
  const configFilters = useSelector((state) => state?.config?.filters);
  const [showExperiments, setShowExperiments] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentActiveTab, setCurrentTab] = useState(0);
  const user = useSelector((state) => state.user.currentUser);
  const [currentActiveTabValue, setCurrentActiveTabValue] =
    useState("series-ctr");
  const isDefaultRemakeSeriesTab =
    currentActiveTabValue === "default-remake-series";
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const hasAccessToActions = internalTeamAccessGlobalState;
  const isViewer = globalRoles?.viewer;

  const fetchSeries = ({ searchedString = "", sortKey, apiRoute }) => {
    dispatch(
      fetchExperimentsStartAsync(
        axiosPrivate,
        searchedString,
        sortKey,
        pageNumber,
        setErrorMsg,
        apiRoute
      )
    );
  };

  useEffect(() => {
    fetchSeries({
      searchedString: "",
      sortKey: "",
      apiRoute: currentActiveTabValue,
    });
  }, [pageNumber]);

  const handleSelectFilter = (e, type) => {
    const filters = experimentsFilters;
    const filterType = e.target.name;
    const value = e.target.value;
    let filter = filters.get(filterType);
    if (type == "start_on" || type == "end_on") {
      filter = value?.length > 0 ? [value] : undefined;
    } else {
      let isChecked = e.target.checked;
      if (filter[0] === value) {
        isChecked = false;
      }
      if (isChecked) {
        filter = [value];
      } else {
        filter = [];
      }
    }
    filters.set(filterType, filter);
    dispatch(setExperimentsFilter(filters));
  };

  const applyFilter = () => {
    dispatch(intializeExperiments({ filters: experimentsFilters }));
    dispatch(setAppliedFilter());
    fetchSeries({
      searchedString: "",
      sortKey: "",
      apiRoute: currentActiveTabValue,
    });
    setShowFilter(false);
  };

  const getLabelFromFilterValue = (valueId, keyName) => {
    if (keyName === "communityFilter") {
      const backendKeywordForFilter = filterKeywordToBackendKeywords(keyName);
      const categoriesFilter =
        configFilters[backendKeywordForFilter.toString()];

      const nameOfVariable = categoriesFilter?.map((data) => {
        if (data?.id == valueId) {
          return data?.title;
        }
      });

      return nameOfVariable;
    } else {
      return valueId;
    }
  };

  const removeFromSelectedFilter = async (id) => {
    const filters = experimentsFilters;

    // Parse through selected filters and filter out the selected series filters
    for (const [key, value] of filters) {
      if (value.length > 0) {
        const newValue = value.filter((itemId) => itemId !== id);
        filters.set(key, newValue);
      }
    }
    dispatch(setExperimentsFilter(filters));

    // This const filter is linked to the checkbox
    applyFilter();
  };

  const chipsFromSelectedSeries = () => {
    const items = [];
    for (const [key, value] of appliedFilters) {
      if (key === "createdOnStartFilter" || key === "createdOnEndFilter") {
      } else if (value.length > 0) {
        var item = value?.map((id, i) => {
          // Remove the boolean and true values of the chip for promoted and premium series
          if (typeof id != "boolean" && id !== "true") {
            return (
              <Chip
                label={getLabelFromFilterValue(id, key)}
                className="m-2 p-1"
                key={i}
                variant="filled"
                color="success"
                value={id}
                onDelete={() => {
                  removeFromSelectedFilter(id);
                }}
              />
            );
          }
        });
        items.push(item);
        // return <div>{value}</div>;
      }
    }
    return items;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilter(!showFilter);
  };

  const handlePageChange = (e, value) => {
    setPageNumber(value);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTabs = (event, newValue) => {
    const value = quickRowFilters.filter((d) => d.index == newValue)[0]?.key;
    setCurrentActiveTabValue(value);
    setCurrentTab(newValue);
    fetchSeries({ searchedString: "", sortKey: "", apiRoute: value });
  };

  const handleDialogTitle = () => {
    if (currentActiveTabValue === "series-ctr") return "Series CTR";
    else if (currentActiveTabValue === "series-remake") return "Series Remake";
    return "Series Default";
  };

  return (
    <div className="experiments-list">
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: errorMsg?.type })
          }
        />
        <div className="page-header-series">
          <Paper elevation={0} className="search-paper mb-2">
            {/* Search box */}
            <div className="mt-2">
              <div className="flex">
                <div className="creator-search serieslist-search-col custom-searchbox">
                  <input
                    label="Search"
                    type="search"
                    // onChange={handleChange}
                    minLength="8"
                    // onKeyPress={handleInputKey}
                    // value={searchName}
                    placeholder="Enter experiments..."
                    className="text-[14px] pl-2 bg-white  w-100 focus:outline-none"
                  />
                  <FontAwesomeIcon
                    className="text-[16px] mr-2"
                    icon={faSearch}
                    color="black"
                    // onClick={handleSearch}
                  />
                </div>
                <div className="action-button-group">
                  <Button
                    size="small"
                    variant="contained"
                    style={{ paddingBottom: 2 }}
                    className="filters-button float-right custom-filter-button"
                    onClick={() => setShowFilter((prev) => !prev)}
                  >
                    <FontAwesomeIcon icon={faFilter} /> <span>FILTERS</span>
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <Drawer anchor="right" open={showFilter} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 350, overflow: "hidden" }} role="presentation">
            <div className="filters-series box-filter-drawer">
              <div className="categories-list-series">
                <h5 className="filter-header">Status</h5>
                <Form.Group className="filters-list-experiment form-checkbox-align">
                  {configFilters?.experimentStatus?.map((status) => {
                    return (
                      <Form.Check
                        key={status?.status}
                        type="radio"
                        name="experimentStatusFilter"
                        className="filter-box col-md-6 my-1 form-check-label"
                        onClick={handleSelectFilter}
                        value={status?.status}
                        label={status?.label}
                        id={status?.label}
                        checked={
                          experimentsFilters
                            ?.get("experimentStatusFilter")
                            .indexOf(status?.status?.toString()) > -1
                            ? true
                            : false
                        }
                      ></Form.Check>
                    );
                  })}
                </Form.Group>
                <Form.Group className="filters-list-experiment form-checkbox-align">
                  {configFilters?.start_on?.map((status) => {
                    return (
                      status?.status === "start_on" && (
                        <>
                          <Form.Label style={{ fontSize: 12, margin: 0 }}>
                            {status?.label}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder={status?.label}
                            name={status?.status}
                            id={status?.status}
                            className="filter-box col-md-6 my-1 form-check-label"
                            onChange={(e) =>
                              handleSelectFilter(e, status?.status)
                            }
                          />
                        </>
                      )
                    );
                  })}
                </Form.Group>
                <Form.Group className="filters-list-experiment form-checkbox-align">
                  {configFilters?.end_on?.map((status) => {
                    return (
                      status.status === "end_on" && (
                        <>
                          <Form.Label style={{ fontSize: 12, margin: 0 }}>
                            {status?.label}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder={status?.label}
                            name={status.status}
                            id={status.status}
                            className="filter-box col-md-6 my-1 form-check-label"
                            // value={endDate}
                            onChange={(e) =>
                              handleSelectFilter(e, status.status)
                            }
                          />
                        </>
                      )
                    );
                  })}
                </Form.Group>
              </div>
            </div>

            <FilterApplyCancelButtons
              handleApply={applyFilter}
              handleCancel={toggleDrawer(false)}
            />
          </Box>
        </Drawer>
      </div>

      <Tabs
        value={currentActiveTab}
        onChange={handleChangeTabs}
        aria-label="basic tabs example"
      >
        {quickRowFilters?.map(
          (data, i) =>
            data?.show === (internalTeamAccessGlobalState || isViewer) && (
              <Tab label={data?.id} {...a11yProps(i)} key={i} />
            )
        )}
      </Tabs>
      <div className="full-layout-scroll-enable">
        {<span>{chipsFromSelectedSeries()}</span>}
        <div className="">
          <div className="card-panel creator-table">
            <Card
              className="shadow-table"
              style={{ border: "1px solid var(--search_chip)" }}
            >
              {/* Table for displaying series data */}
              {hasMoreSeries && isFetchingSeries ? (
                <LinearProgress color="inherit" />
              ) : (
                <div style={{ height: 4, background: "white" }}>ggg</div>
              )}

              <Table hover responsive className="table-series">
                <thead style={{ position: "sticky", zIndex: 1000 }}>
                  <tr>
                    <th className="w-[100px]">Thumbnail</th>
                    <th className="title-experiment-row">Title</th>
                    <th>Id</th>
                    {!isDefaultRemakeSeriesTab && <th>Start</th>}
                    {!isDefaultRemakeSeriesTab && <th>End</th>}
                    {isDefaultRemakeSeriesTab && <th>Replace Date</th>}
                    <th>Status</th>
                    {hasAccessToActions && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {series?.length > 0 &&
                    series?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="series-thumbnail">
                            <div className="series-image">
                              <img
                                src={
                                  data.experiment.image
                                    ? data.experiment.image
                                    : data.original_image
                                    ? data.original_image
                                    : placeholder
                                }
                                alt="avatar"
                                className="table-rowserie-thumbnail series-avatar"
                              />
                            </div>
                          </td>
                          <td className="title-experiment-row">
                            <Link to={`/videos/${data.slug}`}>
                              {data?.experiment?.title
                                ? data?.experiment?.title
                                : data?.display_title}
                            </Link>
                          </td>
                          <td>{data?.id}</td>
                          {!isDefaultRemakeSeriesTab && (
                            <td>
                              {data?.experiment?.start_time
                                ? getDateString(
                                    new Date(data?.experiment?.start_time)
                                      .toISOString()
                                      .split("T")[0]
                                  )
                                : ""}
                            </td>
                          )}

                          {!isDefaultRemakeSeriesTab && (
                            <td>
                              {data?.experiment?.end_time
                                ? getDateString(
                                    new Date(data?.experiment?.end_time)
                                      .toISOString()
                                      .split("T")[0]
                                  )
                                : ""}
                            </td>
                          )}

                          {currentActiveTabValue ===
                            "default-remake-series" && (
                            <td>
                              {data?.experiment?.replace_time &&
                                getDateString(
                                  new Date(data?.experiment?.replace_time)
                                    .toISOString()
                                    .split("T")[0]
                                )}
                            </td>
                          )}
                          <td>
                            {
                              <Chip
                                label={data?.experiment?.status?.toUpperCase()}
                                className={data?.experiment?.status?.toLowerCase()}
                              />
                            }
                          </td>
                          {hasAccessToActions && (
                            <td>
                              <IconButton
                                onClick={() => {
                                  setSeriesData(data);
                                  setShowExperiments(true);
                                }}
                              >
                                <Preview />
                              </IconButton>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {!isFetchingSeries && series?.length == 0 && (
                <div className="img-nofound-wrapper">
                  <img src={NoImg} className="img-nofound" alt="No data img" />
                </div>
              )}
            </Card>
            <div className="w-fit mx-auto mt-1">
              <Pagination
                count={pageData?.n_pages}
                page={pageNumber}
                variant="outlined"
                onChange={(e, val) => handlePageChange(e, val)}
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </div>

      {seriesData && (
        <Modal show={showExperiments} centered>
          <Modal.Header closeButton onHide={() => setShowExperiments(false)}>
            <div className="modal-experiments-title">{handleDialogTitle()}</div>
          </Modal.Header>
          <Modal.Body>
            <SeriesExperiments
              setErrorMsg={setErrorMsg}
              setSeries={setSeriesData}
              series={seriesData}
              alreadyExist={true}
              close={() => setShowExperiments(false)}
              currentActiveTabValue={currentActiveTabValue}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
