import React from "react";
import { Box, Drawer } from "@mui/material";
import { Form, Row } from "react-bootstrap";
import { filtersState } from "../../utils/constants/Feedback";
import useZustandStore from "../../zustandStore/useZustandStore";
import FilterApplyCancelButtons from "../Common/FilterApplyCancelButtons";

const FilterDrawer = ({
  openFilters,
  selectedFilters,
  setOpenFilters,
  handleFilterChange,
  handleApplyFilters,
  handleClearAllFilters,
}) => {
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const isViewer = globalRoles?.viewer;
  const hasAccessToReportedFilter = internalTeamAccessGlobalState || isViewer;

  return (
    <div>
      {openFilters && (
        <Drawer
          anchor="right"
          open={openFilters}
          onClose={() => setOpenFilters(false)}
        >
          <Box
            sx={{ width: 350, overflow: "hidden", paddingLeft: 1 }}
            role="presentation"
          >
            <div className="filters-series box-filter-drawer pl-2">
              <div>
                <h5 className="filter-header px-0">Reaction</h5>
                <Form.Group
                  className="filters-list-series custom-drawer-list-series"
                  as={Row}
                >
                  {filtersState?.reaction?.map((item, index) => {
                    const filterValue = `${item.title}`;
                    return (
                      <Form.Check
                        key={item.id + `${index}`}
                        type="checkbox"
                        name="categoryFilter"
                        onChange={handleFilterChange}
                        value={filterValue}
                        label={item.displayTitle}
                        checked={selectedFilters?.includes(filterValue)}
                        className="form-col col-md-6 form-checkbox-align"
                      />
                    );
                  })}
                </Form.Group>
              </div>

              {hasAccessToReportedFilter && (
                <div>
                  <h5 className="filter-header px-0">Reported</h5>
                  <Form.Group
                    className="filters-list-series custom-drawer-list-series"
                    as={Row}
                  >
                    {filtersState?.reported?.map((item, index) => {
                      const filterValue = `${item.title}`;
                      return (
                        <Form.Check
                          key={item.id + `${index}`}
                          type="checkbox"
                          name="categoryFilter"
                          onChange={handleFilterChange}
                          value={filterValue}
                          label={item.displayTitle}
                          checked={selectedFilters?.includes(filterValue)}
                          className="form-col col-md-6 form-checkbox-align"
                        />
                      );
                    })}
                  </Form.Group>
                </div>
              )}
            </div>

            <FilterApplyCancelButtons
              handleApply={handleApplyFilters}
              handleCancel={() => {
                handleClearAllFilters();
                setOpenFilters(false);
              }}
            />
          </Box>
        </Drawer>
      )}
    </div>
  );
};

export default FilterDrawer;
