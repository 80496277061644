import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import "./alert-component.styles.css";
import { Snackbar } from "@mui/material";
export default function AlertComponent(props) {
  const [showAlertErrorMessage, setShowAlertErrorMessage] = useState(false);

  // ON STATE UPDATE
  useEffect(() => {
    if (props?.message) {
      setShowAlertErrorMessage(true);
    } else {
      setShowAlertErrorMessage(false);
    }
  }, [props]);

  const closeTheAlertDeleteTheMessage = () => {
    setShowAlertErrorMessage(false);
    if(props && props?.setAlertNotification){
      props?.setAlertNotification('');
    }
  };

  return (
    <div className="alert-messages-section"> 
      <Snackbar ClickAwayListenerProps={{ onClickAway: () => null }} open={showAlertErrorMessage} autoHideDuration={6000} onClose={closeTheAlertDeleteTheMessage}>
        <Alert
          variant="filled"
          onClose={() => {
            closeTheAlertDeleteTheMessage();
          }}
          severity={props.type === 'failed' ? 'error' : props.type?.length > 0 ? props.type : "info"}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
