import React from "react";
import './styles.css';

export default function Loader() {
  return (
    <div className="center">
    <div className="la-ball-clip-rotate">
      <div></div>
    </div>
    </div>
  );
}
