import React from "react";
import { handleDefaultProfile } from "../../utils/utils";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Verified from "../Common/Icons/Verified";

const ProfileDetails = ({ setOpenUpdateProfile }) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  return (
    <div>
      <div className="flex gap-x-3 mt-4 justify-between ">
        <div className="flex gap-x-3">
          <img
            src={
              currentUser?.avatar ||
              handleDefaultProfile({ userName: currentUser?.name })
            }
            alt={currentUser?.name || "Seekho User"}
            className="w-14 h-14 rounded-full !object-contain shadow-sm"
          />

          <div>
            <div className="flex items-center gap-x-1">
              <h6 className="text-[18px] font-medium">{currentUser?.name}</h6>
              <Verified />
            </div>
            <h6 className="!text-[14px] font-normal text-gray-500 tracking-wide mt-1">
              {currentUser?.status}
            </h6>
          </div>
        </div>

        <div>
          <FontAwesomeIcon
            icon={faPen}
            className="p-2 text-[32px] bg-baseBlueColor text-white rounded-full cursor-pointer"
            onClick={() => setOpenUpdateProfile(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
