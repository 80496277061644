export const SeriesActionTypes = {
  SET_CURRENT_VIDEO_PAGE_NUMBER: "SET_CURRENT_PAGE_NUMBER",
  SET_FILTERS: "SET_SERIES_FILTERS",
  SET_HAS_MORE: "SET_HAS_MORE_SERIES",
  FETCH_SERIES_FAILURE: "FETCH_SERIES_FAILURE",
  FETCH_SERIES_SUCCESS: "FETCH_SERIES_SUCCESS",
  FETCH_SERIES_START: "FETCH_SERIES_START",
  CHANGE_QUALITY_APPROVED_START: "CHANGE_QUALITY_APPROVED_START",
  CHANGE_QUALITY_APPROVED_FAILURE: "CHANGE_QUALITY_APPROVED_FAILURE",
  CHANGE_QUALITY_APPROVED_SUCCESS: "CHANGE_QUALITY_APPROVED_SUCCESS",
  CHANGE_PROMOTION_SCORE_START: "CHANGE_PROMOTION_SCORE_START",
  CHANGE_PROMOTION_SCORE_FAILURE: "CHANGE_PROMOTION_SCORE_FAILURE",
  CHANGE_PROMOTION_SCORE_SUCCESS: "CHANGE_PROMOTION_SCORE_SUCCESS",
  SERIES_INITIALIZE: "SERIES_INITIALIZE",
  CHANGE_A_SERIES_DETAILS: "CHANGE_A_SERIES_DETAILS",
  CREATE_SERIES: "CREATE_SERIES",
  ADD_SERIES: "ADD_SERIES",
  REMOVE_SERIES: "REMOVE_SERIES",
  UPDATE_SERIES: "UPDATE_SERIES",
  CHANGE_PREMIUM_SERIES_START: "CHANGE_PREMIUM_SERIES_START",
  CHANGE_PREMIUM_SERIES_FAILURE: "CHANGE_PREMIUM_SERIES_FAILURE",
  CHANGE_PREMIUM_SERIES_SUCCESS: "CHANGE_PREMIUM_SERIES_SUCCESS",
  PROMOTE_SERIES_START: "PROMOTE_SERIES_START",
  PROMOTE_SERIES_FAILURE: "PROMOTE_SERIES_FAILURE",
  PROMOTE_SERIES_SUCCESS: "PROMOTE_SERIES_SUCCESS",
  INITIALIZE_SERIES: "INITIALIZE_SERIES",

  CHANGE_SERIES_ORIGINAL_START: "CHANGE_SERIES_ORIGINAL_START",
  CHANGE_SERIES_ORIGINAL_FAILURE: "CHANGE_SERIES_ORIGINAL_FAILURE",
  CHANGE_SERIES_ORIGINAL_SUCCESS: "CHANGE_SERIES_ORIGINAL_SUCCESS",

  CHANGE_SERIES_STATUS_START: "CHANGE_SERIES_STATUS_START",
  CHANGE_SERIES_STATUS_FAILURE: "CHANGE_SERIES_STATUS_FAILURE",
  CHANGE_SERIES_STATUS_SUCCESS: "CHANGE_SERIES_STATUS_SUCCESS",

  REJECTION_REASON_START: "REJECTION_REASON_START",
  REJECTION_REASON_FAILURE: "REJECTION_REASON_FAILURE",
  REJECTION_REASON_SUCCESS: "REJECTION_REASON_SUCCESS",
  UPDATE_SELECTED_TAB: "UPDATE_SELECTED_TAB",
  UPDATE_SELECTED_TAB_START: "UPDATE_SELECTED_TAB_START",
  UPDATE_SELECTED_TAB_SUCCESS: "UPDATE_SELECTED_TAB_SUCCESS",

  SET_SELECTED_CREATOR_SERIES_FILTER: "SET_SELECTED_CREATOR_SERIES_FILTER",
};

export default SeriesActionTypes;
