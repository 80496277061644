import React from "react";

const CustomAnchor = ({
  displayText,
  href,
  className,
  openInNewTab = false,
}) => {
  return (
    <a
      href={href}
      className={`hover:text-black hover:no-underline ${className || ""}`}
      target={openInNewTab ? "_blank" : ""}
    >
      {displayText}
    </a>
  );
};

export default CustomAnchor;
