import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./add-experiment.styles.css";
import ImageEditor from "../ImageEditor/image-editor.component";
import Loader from "../Loader";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { MenuItem, Select } from "@mui/material";
import { GET_SERIES_LIST } from "../../utils/constants";

export default function AddExperiments({ series, close }) {
  //local states
  const axiosPrivate = useAxiosPrivate();
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("n");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState('x');
  const [creatorColor, setCreatorColor] = useState(
    series?.creator_color || "#000000"
  );
  const [errorMsg, setErrorMsg] = useState({error: "", type: 'success'});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [addTitleImage, setAddTitleImage] = useState(false);
  
  const getMonthString = (date) => {
    let month = new Date(date).getMonth() + 1;
    if (month <= 9) {
      return "0" + month;
    } else {
      return month + "";
    }
  };

  const getDateString = (date) => {
    let dateString = new Date(date).getDate();
    if (dateString <= 9) {
      return "0" + dateString;
    } else {
      return dateString + "";
    }
  };
  const getYearString = (date) => {
    let year = new Date(date).getFullYear().toString().slice(2, 4);
    if (year <= 9) {
      return "0" + year;
    } else {
      return year + "";
    }
  };

  const addExperiment = async () => {
    try {
      if (!startDate || !endDate) {
        setErrorMsg({
            type: "failed",
            error: "Start Date and End Date should not be empty"
        })
        return;
      }
      if (!title && !image) {
        setErrorMsg({
            type: "failed",
            error: "Please select at least Image or Title"
        })
        return;
      }

      const body = new FormData();
      const startDateString = `${getDateString(startDate)}/${getMonthString(
        startDate
      )}/${getYearString(startDate)}`;
      const endDateString = `${getDateString(endDate)}/${getMonthString(
        endDate
      )}/${getYearString(endDate)}`;

      body.append("title", title);
      body.append("image", image);
      body.append("start_date", startDateString); //+ "T00:00:00+00:00"
      body.append("end_date", endDateString);
      body.append("add_title_in_image ", addTitleImage);
      body.append("creator_color ", creatorColor);

      const url = `${apiGateway}/api/v1/cms/experiment/series-ctr/${series?.slug}/`;
      setIsLoading(true);
      axiosPrivate.post(url, body)
      .then(({data, status}) => {
          setIsLoading(false);
          if(status === 200){
            setErrorMsg({
              type: "success",
              error: 'Experiment added successfully'
            })
          }
      }).catch(({response}) => {
        setErrorMsg({
          type: "failed",
          error: response?.data?.error_message ? response?.data?.error_message : "Something went wrong"
        })
        setIsLoading(false);
      }).finally(() => {
        close();
      })
    } catch (err) {
      setErrorMsg({
        type: "failed",
        error: err?.message ? err?.message: "Something went wrong"
    })
    }
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSeriesChange = (event) => {
    setSelectedSeries(event.target.value)
  }

  return (
    <div className={`add-experiment ${isLoading ? "disabled" : ""}`}>
      <AlertComponent message={errorMsg?.error} type={errorMsg?.type} setAlertNotification={() => setErrorMsg({error: '', type: 'failed'})}/>
      <div>
        <div>
            <label>Select type of AB experiments</label>
            <Select
              size="small"
              value={type}
              style={{width: "100%"}}
              onChange={handleChange}
              placeholder="Select type of AB Experiments"
            >
              <MenuItem value={'n'}>
                <em>Select type of AB Experiments</em>
              </MenuItem>
              <MenuItem value={'title'}>Title AB Experiments</MenuItem>
              <MenuItem value={'thumb'}>Thumbnail AB Experiments</MenuItem>
              <MenuItem value={'title&thumb'}>Title & Thumbnail AB Experiments</MenuItem>
            </Select>
        </div>
        {type !== 'n' && (
          <div className="formgroup-modal-flex mt-3">
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="start_date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              min={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="end_date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              min={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </div>
        )}
        {(type === 'title' || type === 'title&thumb') && (
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              name="description"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        )}
        {(type === 'thumb' || type === 'title&thumb') && <>
          <Form.Group
          controlId="exampleForm.ControlInput1"
          className={`image-input`}
        >
          <label>Select Thumbnail</label><br/>
          <Button
            type="submit"
            variant="secondary"
            active
            onClick={(e) => {
              e.preventDefault();
              setShowImageEditor(true);
            }}
          >
            Select Thumbnail
          </Button>
          {image && (
            <FontAwesomeIcon
              className="icon"
              onClick={() => setImage(null)}
              icon={faTrashAlt}
            />
          )}
          </Form.Group>
          {image && (
              <div className="center ">
                <img
                  src={URL.createObjectURL(image)}
                  style={{ border: "1px solid lightgrey", borderRadius: 5 }}
                  width="99px"
                  alt="new_image"
                />
              </div>
          )}
        </>
        }
        {type === 'series' && <>
          <label>Select Series</label><br/>
          <Select
              size="small"
              value={selectedSeries}
              style={{width: "100%"}}
              onChange={handleSeriesChange}
              placeholder="Select type of AB Experiments"
            >
              <MenuItem value={'x'}>
                <em>Select series for AB</em>
              </MenuItem>
              <MenuItem value={'title'}>Title AB Experiments</MenuItem>
              
            </Select>
        </>
        }
      </div>
      {type !== 'n' && <div className="center post-btn">
        <button variant="success" onClick={addExperiment}>
          {!isLoading ? "Post" :  <Loader />}
        </button>
      </div>}
      <Modal className="image-editor-modal" show={showImageEditor}>
        <Modal.Header closeButton onHide={() => setShowImageEditor(false)}>
          <div className="modal-video-title">Select Thumbnail</div>
        </Modal.Header>
        <Modal.Body>
          <ImageEditor
            done={(file) => setImage(file)}
            close={() => setShowImageEditor(false)}
            image={image && URL.createObjectURL(image)}
            creatorColor={creatorColor}
            setCreatorColor={(creatorColor) => setCreatorColor(creatorColor)}
            seriesTitle={title}
            addTitleImage={addTitleImage}
            setAddTitleImage={setAddTitleImage}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
