import React from "react";

const FilterApplyCancelButtons = ({ handleApply, handleCancel }) => {
  return (
    <div className="flex gap-x-2 pl-1">
      <button
        onClick={handleApply}
        className="!px-6 py-1 bg-baseBlueColor !text-[15px] text-white rounded-lg"
      >
        Apply
      </button>
      <button
        onClick={handleCancel}
        className="!px-6 py-1 bg-gray-200 !text-[15px] text-black rounded-lg"
      >
        Clear
      </button>
    </div>
  );
};

export default FilterApplyCancelButtons;
