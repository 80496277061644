import React from "react";
import CustomModal from "../Common/CustomModal";
import { useSelector } from "react-redux";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { handleFormatDate } from "../../utils/utils";

const RejectionReasonModal = ({ openModal, handleCloseModal }) => {
  const reasonData = useSelector((state) => {
    return state.series.rejectionReasonData;
  });

  const renderRejectedReasonData = () => {
    if (reasonData != undefined) {
      if (reasonData.reasons.length > 0) {
        const finalReasons = reasonData.reasons?.map((d, i) => {
          return (
            <span key={i}>
              <ListItem>
                <ListItemText
                  primary={d.reason}
                  secondary={handleFormatDate({ dateInString: d.rejected_on })}
                ></ListItemText>
              </ListItem>
              <Divider className="divier-custom-color" component="li" />
            </span>
          );
        });
        const listToReturn = <List>{finalReasons}</List>;
        return listToReturn;
      } else {
        return <li>No logs for rejection reasons</li>;
      }
    }
    return <li>No logs for rejection reasons</li>;
  };

  return (
    <div>
      <CustomModal
        title="Rejection List"
        show={openModal}
        isButtonsRequired={false}
        onHide={handleCloseModal}
      >
        {renderRejectedReasonData()}
      </CustomModal>
    </div>
  );
};

export default RejectionReasonModal;
