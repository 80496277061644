import React from "react";
import { Form, Row } from "react-bootstrap";
import { Box, Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectConfigFilters } from "../../redux/config/config.selector";
import FilterApplyCancelButtons from "../Common/FilterApplyCancelButtons";

const FilterDrawer = ({
  openFilters,
  selectedFilters,
  setOpenFilters,
  handleSelectedFilter,
  handleClearAllFilters,
  handleApplyFilters,
}) => {
  const history = useHistory();
  const configFilters = useSelector(selectConfigFilters);
  return (
    <div>
      {openFilters && (
        <Drawer
          anchor="right"
          open={openFilters}
          onClose={() => setOpenFilters(false)}
        >
          <Box
            sx={{ width: 350, overflow: "hidden", paddingLeft: 1 }}
            role="presentation"
          >
            <div className="filters-series box-filter-drawer pl-2">
              <div className="categories-list-series">
                <h5 className="filter-header px-0">Categories</h5>
                <Form.Group
                  className="filters-list-series custom-drawer-list-series"
                  as={Row}
                >
                  {configFilters.categories?.map((item, index) => {
                    const filterValue = `${item.title}-${item.id}`;
                    return (
                      <Form.Check
                        key={item.id + `${index}`}
                        type="checkbox"
                        name="categoryFilter"
                        onChange={handleSelectedFilter}
                        value={filterValue}
                        label={item.title}
                        checked={selectedFilters?.includes(filterValue)}
                        className="form-col col-md-6 form-checkbox-align"
                      />
                    );
                  })}
                </Form.Group>
              </div>
            </div>
            <FilterApplyCancelButtons
              handleApply={handleApplyFilters}
              handleCancel={() => {
                handleClearAllFilters();
                setOpenFilters(false);
              }}
            />
          </Box>
        </Drawer>
      )}
    </div>
  );
};

export default FilterDrawer;
