import VideoActionTypes from "./video.types";

const INITIAL_STATE = {
  videos: [],
  currentPageNumber: 1,
  filters: new Map([
    ["categoryFilter", []],
    ["statusFilter", []],
    ["durationFilter", []],
    ["creatorFilter", []],
    ["involvesActivityFilter", []]
  ]),
  page_data: {
    n_content_units: 0,
    n_pages: 1,
    page: 1
  },
  hasMore: true,
  isFetching: false,
  isDeleting: false,
  errorMessage: undefined,
};

const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VideoActionTypes.VIDEOS_INITIALIZE:
      return INITIAL_STATE;
    case VideoActionTypes.FETCH_VIDEOS_START:
      return {
        ...state,
        isFetching: true,
      };
    case VideoActionTypes.SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    case VideoActionTypes.ADD_VIDEO:
      return {
        ...state,
        videos: [action.payload, ...state.videos]
      };
    case VideoActionTypes.FETCH_VIDEOS_SUCCESS: {
      const pageNumber = action.payload.pageNumber;
      const fetchedVideos = [...action.payload.videosFetched];

      if (pageNumber !== 0) {
        return {
          ...state,
          videos: [...fetchedVideos],
          hasMore: action.payload.hasMore,
          page_data: action.payload.table_data,
          currentPageNumber: pageNumber,
          isFetching: false,
          errorMessage: undefined,
        };
      }
    }

    case VideoActionTypes.FETCH_VIDEOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    case VideoActionTypes.SET_CURRENT_VIDEO_PAGE_NUMBER: {
      const pageNumber = action.payload;
      return {
        ...state,
        currentPageNumber: pageNumber,
      };
    }
    case VideoActionTypes.SET_FILTERS: {
      const filters = action.payload;
      return {
        ...state,
        filters,
      };
    }
    case VideoActionTypes.DELETE_SINGLE_VIDEO_START:
      return {
        ...state,
        isDeleting: true,
      };
    case VideoActionTypes.DELETE_SINGLE_VIDEO_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case VideoActionTypes.DELETE_SINGLE_VIDEO_SUCCESS: {
      const deletedSlug = action.payload;
      const newVideos = state.videos.filter(
        (video) => video.slug !== deletedSlug
      );

      return {
        ...state,
        videos: [...newVideos],
        isDeleting: false,
      };
    }

    case VideoActionTypes.DELETE_MULTIPLE_VIDEOS_START:
      return {
        ...state,
        isDeleting: true,
      };
    case VideoActionTypes.DELETE_MULTIPLE_VIDEOS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case VideoActionTypes.DELETE_MULTIPLE_VIDEOS_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload,
      };
    case VideoActionTypes.FETCH_FILTERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case VideoActionTypes.FETCH_FILTERS_SUCCESS: {
      const filters = action.payload.filtersFetched;
      return {
        ...state,
        categoryFilters: [...filters.categories],
        durationFilters: [...filters.duration],
        unitStatusFilters: [...filters.unit_status],
        isFetching: false,
      };
    }
    case VideoActionTypes.FETCH_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case VideoActionTypes.CLEAR_ALL:
      return {
        state: null,
      };
    case VideoActionTypes.UPDATE_SINGLE_VIDEO:{
        const videos = state.videos;
        const updatedVideo = action.payload
        const updatedVideos = []
        videos?.forEach((video) => {
          if (video?.id === updatedVideo?.content_unit?.id) {
            updatedVideos.push(updatedVideo?.content_unit)
          }
          else {
            updatedVideos.push(video)
          }
        })

        return {
          ...state,
          videos: updatedVideos
        }
      }
    default:
      return state;
  }
};

export default videoReducer;
