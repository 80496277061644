import React from "react";

const FeedbackListingSkeleton = () => {
  return (
    <div>
      <table className="min-w-full text-left text-[#333333] !bg-white">
        <thead className="rounded-md bg-gray-50">
          <tr>
            {Array(5)
              .fill("")
              .map((_, index) => (
                <th
                  key={index}
                  className={`px-4 py-3 !text-[13px] !font-medium !rounded-md ${
                    index === 0 ? "!min-w-[500px] lg:w-[60%]" : ""
                  }`}
                >
                  <div className="flex gap-x-1 items-center">
                    <div className="h-4 bg-gray-200 rounded w-[100px]"></div>
                  </div>
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {Array(10)
            .fill("")
            .map((_, index) => (
              <tr
                className="text-black !font-normal text-[12px] !border-b last:border-none"
                key={index}
              >
                {/* User profile and feedback */}
                <td className="px-4 py-3">
                  <div className="flex gap-x-3">
                    <div className="rounded-full bg-gray-200 w-[36px] h-[36px]"></div>
                    <div className="flex flex-col gap-y-2">
                      <div className="h-4 bg-gray-200 rounded w-[120px]"></div>
                      <div className="h-6 bg-gray-200 rounded w-[500px]"></div>
                    </div>
                  </div>
                </td>

                {/* Ratings / Like Dislike */}
                <td className="text-[16px] text-center px-4 py-3">
                  <div className="h-6 w-6 bg-gray-200 rounded-full mx-auto"></div>
                </td>

                {/* Series published date */}
                <td className="text-[13px] text-left text-gray-500 px-4 py-3 min-w-[110px]">
                  <div className="h-4 bg-gray-200 rounded w-[80px]"></div>
                </td>

                {/* Series thumbnail */}
                <td className="py-2">
                  <div className="w-[55px] h-[74px] bg-gray-200 rounded-sm mx-auto"></div>
                </td>

                {/* Series title and Id */}
                <td className="text-[13px] text-gray-500 px-4 py-3 !min-w-[300px]">
                  <div className="h-4 bg-gray-200 rounded w-[200px]"></div>
                  <div className="h-4 bg-gray-200 rounded w-[80px] mt-2"></div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackListingSkeleton;
