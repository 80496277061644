import React from "react";

const SeriesStatsSkeleton = () => {
  return (
    <div>
      <div className="!bg-white rounded-xl p-3 animate-pulse">
        {/* Image, Title, Date, Views & Ratings */}
        <div className="flex gap-x-3">
          <div className="w-[127px] h-[180px] bg-gray-300 rounded-md"></div>
          <div>
            <div className="w-[210px] h-4 bg-gray-300 rounded mb-2"></div>
            <div className="w-[180px] h-4 bg-gray-300 rounded mb-2"></div>
            <div className="text-[12px] text-gray-500 flex gap-x-1 mt-2">
              <div className="w-[40px] h-4 bg-gray-300 rounded"></div>
              <div className="w-[60px] h-4 bg-gray-300 rounded"></div>
            </div>
            <div className="w-[100px] h-6 bg-gray-300 rounded-full mt-3"></div>
          </div>
        </div>

        {/* Views, WatchTime & Completion rate */}
        <div className="text-[12px] mt-3">
          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Views</p>
            <div className="w-[50px] h-4 bg-gray-300 rounded"></div>
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Watch Time</p>
            <div className="w-[50px] h-4 bg-gray-300 rounded"></div>
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Completion Rate</p>
            <div className="w-[100px] h-4 bg-gray-300 rounded"></div>
          </div>
        </div>

        <hr className="mt-3" />

        {/* Fixed, Variable & TotalPayout */}
        <div className="text-[12px] mt-3">
          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Fixed</p>
            <div className="w-[50px] h-4 bg-gray-300 rounded"></div>
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Variable</p>
            <div className="w-[50px] h-4 bg-gray-300 rounded"></div>
          </div>

          <div className="flex justify-between mt-[2px]">
            <p className="text-gray-500">Total Payout</p>
            <div className="w-[80px] h-6 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesStatsSkeleton;
