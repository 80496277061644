import { apiGateway } from "../../utils/config";
import { GET_EXPERIMENTS_LIST } from "../../utils/constants";
import ExperimentTypes from "./experiments.types";

export const intializeExperiments = (data) => {
  //data contains the data which doesn't need to change
  return {
    type: ExperimentTypes.INITIALIZE_EXPERIMENTS,
    payload: data,
  };
};

export const setAppliedFilter = () => {
  return {
    type: ExperimentTypes.SET_APPLIED_EXPERIMENTS_FILTER,
  };
};

const fetchExperimentsStart = () => ({
  type: ExperimentTypes.FETCH_EXPERIMENTS_START,
});

const fetchExperimentsSuccess = (data) => ({
  type: ExperimentTypes.FETCH_EXPERIMENTS_SUCCESS,
  payload: data,
});

const fetchExperimentsFailure = (errMessage) => ({
  type: ExperimentTypes.FETCH_EXPERIMENTS_FAILURE,
  payload: errMessage,
});

export const fetchExperimentsStartAsync = (
  axiosPrivate,
  searchedString,
  sortKey,
  pageNumber,
  setErrorMsg,
  apiRoute
) => {
  return async (dispatch, getState) => {
    try {
      const { experiments } = getState();
      const { appliedFilters, sorting } = experiments;
      let isReverse = sorting?.isReverse;
      if (sorting.key === sortKey) {
        isReverse = !isReverse;
      } else {
        isReverse = false;
      }
      const experimentStatusArray = appliedFilters.get(
        "experimentStatusFilter"
      );
      const start_on = appliedFilters.get("start_on");
      const end_on = appliedFilters.get("end_on");
      let url = `${apiGateway}${GET_EXPERIMENTS_LIST}${apiRoute}/?page=${pageNumber}&lang=en`;
      if (sortKey) url += `&sorting_key=${sortKey}&reverse=${isReverse}`;
      if (searchedString) url += `&q=${searchedString}`;

      if (experimentStatusArray && experimentStatusArray.length !== 0) {
        for (let i = 0; i < experimentStatusArray.length; i++) {
          const statusValue = experimentStatusArray[i];
          url += `&status=${statusValue}`;
        }
      }
      if (start_on && start_on?.length !== 0) {
        const statusValue = start_on[0];
        url += `&start_on=${statusValue}`;
      }
      if (end_on && end_on?.length !== 0) {
        const statusValue = end_on[0];
        url += `&end_on=${statusValue}`;
      }

      dispatch(fetchExperimentsStart());
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (status === 200)
            dispatch(
              fetchExperimentsSuccess({
                hasMore: data?.has_more,
                series: data?.series,
                table_data: {
                  n_series: data.n_series,
                  n_pages: data.n_pages,
                  page: data.page,
                },
                pageNumber: data.page,
                searchedString,
                sorting: { key: sortKey, isReverse },
              })
            );
        })
        .catch(({ response }) => {
          dispatch(
            fetchExperimentsSuccess({
              hasMore: null,
              series: [],
              table_data: {
                n_series: 0,
                n_pages: 0,
                page: 0,
              },
              pageNumber: 0,
              searchedString,
              sorting: { key: sortKey, isReverse },
            })
          );
          setErrorMsg({
            error: response?.data
              ? response?.data?.error_message
              : "Unable to fetch data",
            type: "failed",
          });
        });
    } catch (err) {
      setErrorMsg({
        error: err?.message ? err?.message : "Unable to fetch data",
        type: "failed",
      });
    }
  };
};

export const setExperimentsFilter = (filters) => {
  const newFilters = new Map(filters);
  return {
    type: ExperimentTypes.SET_EXPERIMENTS_FILTERS,
    payload: newFilters,
  };
};
