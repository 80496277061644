import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  handleDefaultProfile,
  handleFormatDateTimeAgo,
  handleFormatDateWithSlash,
} from "../../utils/utils";
import { handleFormatViews } from "../../utils/constants/Dashboard";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";

const LatestVideos = ({ series }) => {
  const [expandedFeedback, setExpandedFeedback] = useState({});
  const history = useHistory();

  // Function to toggle the read more state for each feedback
  const handleToggleReadMore = ({ seriesId, index }) => {
    const key = `${seriesId}-${index}`; // Unique key based on series ID and feedback index
    setExpandedFeedback((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleRouteToVideo = ({ slug }) => {
    history.push(`/videos-detail/${slug}`);
  };

  return (
    <div>
      <h6 className="text-[18px] font-medium">Latest Videos</h6>
      {series?.length > 0 ? (
        <div className="flex gap-x-4 mt-2 flex-col lg:flex-row">
          {series?.map((item) => {
            const stats = item?.stats;
            return (
              <div
                key={item?.id}
                className="!relative !border !border-[#E7E7E7] !bg-white px-3 py-3 rounded-xl shadow-sm mb-4 lg:w-[34%]"
              >
                <div className="flex gap-x-3">
                  {/* Image */}
                  <img
                    src={item?.image}
                    alt={item?.title}
                    className="w-[90px] h-[128px] rounded cursor-pointer"
                    onClick={() => handleRouteToVideo({ slug: item?.slug })}
                  />

                  {/* Series details */}
                  <div className="w-full">
                    <div className="h-[75px]">
                      <div className="flex justify-between items-start">
                        <h6 className="text-[16px] font-medium max-w-[210px] h-[40px] line-clamp-2">
                          {item?.title}
                        </h6>

                        {stats?.status && (
                          <div
                            className={`text-[11px] capitalize rounded-full w-fit px-2 py-1 
                          ${handleSeriesStatusColor({
                            status: stats?.status,
                          })}`}
                          >
                            {stats?.status}
                          </div>
                        )}
                      </div>

                      <div className="text-[12px] text-gray-500 flex gap-x-1 mt-1">
                        <p>
                          {handleFormatDateWithSlash({
                            dateString: item?.approved_on,
                          })}
                        </p>{" "}
                        •<p>{item?.duration_m} mins</p>
                        {stats?.rating && (
                          <p>
                            •{" "}
                            <span className="!text-[#F4B513]">
                              ★ {stats?.rating}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="text-[12px] -mt-[8px]">
                      <div className="flex justify-between mt-[2px]">
                        <p className="text-gray-500">Views</p>
                        {stats?.n_views ? (
                          <p>{handleFormatViews({ views: stats?.n_views })}</p>
                        ) : (
                          "-"
                        )}
                      </div>

                      <div className="flex justify-between mt-[2px]">
                        <p className="text-gray-500">Completion Rate</p>
                        <p>
                          {stats?.completion_rate ? (
                            <span className="font-semibold">
                              {stats?.completion_rate}%
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="text-gray-500">
                            {stats?.target_completion_rate
                              ? `(${stats?.target_completion_rate}%)`
                              : "-"}
                          </span>
                        </p>
                      </div>

                      <div className="flex justify-between mt-[2px]">
                        <p className="text-gray-500">Watch Time</p>
                        {stats?.watch_hours ? (
                          <p>{stats?.watch_hours} hours</p>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Feedback */}
                <div className="mt-4 pb-5">
                  {item?.feedbacks?.map((elem, index) => {
                    const key = `${item?.id}-${index}`; // Unique key based on video ID and feedback index
                    return (
                      <div
                        key={key}
                        className="flex gap-x-3 !border-b !border-[#E7E7E7] py-2 mt-2 last:border-none"
                      >
                        <img
                          src={
                            elem?.profile?.avatar ||
                            handleDefaultProfile({
                              userName: elem?.profile?.name || "Seekho User",
                            })
                          }
                          alt={elem?.profile?.name}
                          className="rounded-full w-[31px] h-[31px]"
                        />
                        <div className="flex flex-col">
                          <div className="text-[12px] text-gray-500">
                            <p className="break-words">
                              {elem?.profile?.name || "Seekho User"} •{" "}
                              <span>
                                {handleFormatDateTimeAgo({
                                  date: elem?.updated_on,
                                })}
                              </span>
                            </p>
                          </div>

                          <p className="text-[12px] break-words text-[#333333] cursor-auto leading-[18px] tracking-wide">
                            {expandedFeedback[key]
                              ? elem?.review
                              : elem?.review?.slice(0, 100)}{" "}
                            {/* Display the "Read More" or "Read Less" button */}
                            {elem?.review?.length > 100 && (
                              <button
                                onClick={() =>
                                  handleToggleReadMore({
                                    seriesId: item?.id,
                                    index,
                                  })
                                }
                                className="text-baseBlueColor !cursor-pointer font-semibold !text-[10px]"
                              >
                                {expandedFeedback[key]
                                  ? "Read Less"
                                  : "...Read More"}
                              </button>
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="w-fit mx-auto mt-5 absolute bottom-4 right-0 left-0">
                  <button
                    className="text-[12px] text-baseBlueColor border px-4 py-1 rounded-[29px] w-fit mx-auto"
                    onClick={() => handleRouteToVideo({ slug: item?.slug })}
                  >
                    View all feedbacks
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="h-[calc(100vh-400px)] flex justify-center items-center">
          <NoFeedbackSvj
            className="w-[250px] mx-auto"
            displayText="No latest videos found"
            displayTextClass="mt-2 text-gray-400 text-[14px] text-center"
          />
        </div>
      )}
    </div>
  );
};

export default LatestVideos;
