import React from "react";
import Creator from "../../components/Creator/creator.component";

function CreatorPage() {
  return (
    <div className="mt-3">
      <Creator />
    </div>
  );
}

export default CreatorPage;
