export const VideoActionTypes = {
    SET_CURRENT_VIDEO_PAGE_NUMBER:'SET_CURRENT_PAGE_NUMBER',
    UPDATE_SINGLE_VIDEO:'UPDATE_SINGLE_VIDEO',
    ADD_VIDEO:'ADD_VIDEO',
    SET_FILTERS:'SET_FILTERS',
    SET_HAS_MORE:'SET_HAS_MORE_VIDEOS',
    FETCH_VIDEOS_FAILURE:'FETCH_VIDEOS_FAILURE',
    FETCH_VIDEOS_SUCCESS:'FETCH_VIDEOS_SUCCESS',
    FETCH_VIDEOS_START:'FETCH_VIDEOS_START',
    FETCH_FILTERS_FAILURE:'FETCH_FILTERS_FAILURE',
    FETCH_FILTERS_SUCCESS:'FETCH_FILTERS_SUCCESS',
    FETCH_FILTERS_START:'FETCH_FILTERS_START',
    DELETE_SINGLE_VIDEO_FAILURE:'DELETE_SINGLE_VIDEO_FAILURE',
    DELETE_SINGLE_VIDEO_SUCCESS:'DELETE_SINGLE_VIDEO_SUCCESS',
    DELETE_SINGLE_VIDEO_START:'DELETE_SINGLE_VIDEO_START',
    DELETE_MULTIPLE_VIDEOS_FAILURE:'DELETE_MULTIPLE_VIDEOS_FAILURE',
    DELETE_MULTIPLE_VIDEOS_SUCCESS:'DELETE_MULTIPLE_VIDEOS_SUCCESS',
    DELETE_MULTIPLE_VIDEOS_START:'DELETE_MULTIPLE_VIDEOS_START',
    CLEAR_ALL:'CLEAR_ALL',
    VIDEOS_INITIALIZE:'VIDEOS_INITIALIZE'
}

export default VideoActionTypes;