import React, { useState } from "react";
import { errorState } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { handleFormatDate } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  handleRenderTableHeadingWithSortIcons,
  reportedReviewState,
} from "../../utils/constants/Feedback";
import SeriesIdCopy from "../Common/SeriesIdCopy";
import AlertComponent from "../Alert-Messages/alert-component.component";
import FeedbackWithProfile from "../Common/FeedbackWithProfile";
import useZustandStore from "../../zustandStore/useZustandStore";
import VideoPlayOnSeriesClick from "../Common/VideoPlayOnSeriesClick";
import UnReportModal from "./UnReportModal";
import CustomAnchor from "../Common/CustomAnchor";

const UIListing = ({ data }) => {
  const [reportedReview, setReportedReview] = useState(reportedReviewState);
  const [message, setMessage] = useState(errorState);
  const [expandedFeedback, setExpandedFeedback] = useState({});
  const history = useHistory();
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const isViewer = globalRoles?.viewer;
  const hasViewToReportedTab = internalTeamAccessGlobalState || isViewer;
  const hasAccessToActions = internalTeamAccessGlobalState;

  // Function to toggle the read more state for each feedback
  const handleToggleReadMore = (index) => {
    setExpandedFeedback((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleUnReportReview = async () => {
    setMessage({ type: "success", error: reportedReview?.id });
  };

  return (
    <div>
      <table className="min-w-full text-left">
        <thead className="rounded-md bg-gray-100 !border-b">
          <tr>
            {handleRenderTableHeadingWithSortIcons({
              history,
              hasViewToReportedTab,
              hasAccessToActions,
            }).map(
              (item, index) =>
                item?.display && (
                  <th
                    className={` px-3 py-3 !text-[13px] text-gray-700 !font-medium whitespace-nowrap ${
                      index === 0 ? "!min-w-[500px] lg:w-[60%]" : ""
                    }`}
                    key={item?.id}
                  >
                    <div className="flex gap-x-1 items-center">
                      <span>{item?.headline}</span>
                      <button onClick={item?.onClick}>{item?.sortIcon}</button>
                    </div>
                  </th>
                )
            )}
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index) => {
            return (
              <tr
                className="text-black !font-normal text-[12px] !border-b last:border-none"
                key={`${item?.id}${index}`}
              >
                {/* User profile and feedback */}
                <td className="px-3 py-3">
                  <FeedbackWithProfile
                    index={index}
                    review={item?.review}
                    updatedOn={item?.updated_on}
                    avatar={item?.profile?.avatar}
                    userName={item?.profile?.name}
                    expandedFeedback={expandedFeedback}
                    handleToggleReadMore={() => handleToggleReadMore(index)}
                  />
                </td>

                {hasViewToReportedTab && (
                  <td
                    className={`px-3 py-3 text-[12px] text-center ${
                      item?.is_reported ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {item?.is_reported ? (
                      <button
                        className="flex items-center gap-x-1 mx-auto"
                        onClick={() => {
                          setReportedReview({
                            openModal: true,
                            reason: item?.reported_reason,
                            review: item?.review,
                            id: item?.id,
                          });
                        }}
                      >
                        Yes <FontAwesomeIcon icon={faEye} />
                      </button>
                    ) : (
                      "No"
                    )}
                  </td>
                )}

                {/* Ratings / Like Dislike */}
                <td className="text-[16px] text-center px-3 py-3">
                  {item?.feedback_type === "like" ? (
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className="text-green-500"
                    />
                  ) : item?.feedback_type === "dislike" ? (
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      className="text-red-500"
                    />
                  ) : (
                    "-"
                  )}
                </td>

                {/* Series published date */}
                <td className="text-[13px] text-left text-gray-500 px-3 py-3 min-w-[110px]">
                  {handleFormatDate({
                    dateInString: item?.series?.approved_on,
                  })}
                </td>

                {/* Series thumbnail */}
                <td className="py-2">
                  <VideoPlayOnSeriesClick
                    thumbnail={item?.series?.image}
                    title={item?.series?.title}
                    slug={item?.series?.slug}
                    thumbnailClassName="rounded-sm cursor-pointer"
                    thumbnailWrapperClassName="!w-[55px] !h-[74px] mx-auto rounded-sm"
                    setMessage={setMessage}
                    n_units={item?.series?.n_units}
                  />
                </td>

                {/* Series title and Id */}
                <td className="text-[13px] text-gray-500 px-4 py-3 !min-w-[300px] break-words">
                  <CustomAnchor
                    displayText={item?.series?.title}
                    href={`/#/videos-detail/${item?.series?.slug}`}
                  />
                  <div>
                    <SeriesIdCopy
                      seriesId={item?.series?.id}
                      setMessage={setMessage}
                      className="!border border-gray-500 px-3 py-[1px] text-gray-500 rounded-[24px] text-[11px] mt-1 hover:bg-baseFillColor"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Reported Review / UnReport Review Modal */}
      {reportedReview?.openModal && (
        <UnReportModal
          openModal={reportedReview?.openModal}
          review={reportedReview?.review}
          reportedReason={reportedReview?.reason}
          handleCloseModal={() => setReportedReview(reportedReviewState)}
          handleUnReportReview={handleUnReportReview}
        />
      )}

      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default UIListing;
