const handleShowTableHeadline = ({
  hasAccessToActions,
  hasAccessToCreatorTableField,
}) => {
  const showTableHeading = [
    { id: 1, title: "Thumbnail/Banner", display: true },
    { id: 2, title: "Title", display: true },
    { id: 3, title: "Show Id", display: true },
    { id: 4, title: "Creator", display: hasAccessToCreatorTableField },
    { id: 5, title: "Total Videos", display: true },
    { id: 6, title: "Category", display: true },
    { id: 7, title: "Status", display: true },
    { id: 8, title: "Actions", display: hasAccessToActions },
  ];

  return showTableHeading;
};

const showListingTabs = [
  {
    id: "ALL",
    key: "SHOWS",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "DRAFT",
    key: "DRAFT",
    value: "draft",
    index: 1,
    show: true,
  },
  {
    id: "LIVE",
    key: "LIVE",
    value: "live",
    index: 2,
    show: true,
  },
  {
    id: "DELETED",
    key: "DELETED",
    value: "deleted",
    index: 3,
    show: true,
  },
];

export { handleShowTableHeadline, showListingTabs };
