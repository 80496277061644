import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const AddCreator = ({ creatorDetails, setCreatorDetails }) => {
  const commonInputFields = [
    {
      id: "creator-name-input",
      type: "text",
      placeholder: "Creator Name",
      value: creatorDetails?.name,
      label: "Creator Name",
      isRequired: true,
      onChange: (e) => {
        setCreatorDetails({ ...creatorDetails, name: e.target.value });
      },
    },
    {
      id: "creator-number-input",
      type: "number",
      placeholder: "Creator's 10 digit Mobile Number ",
      value: creatorDetails?.mobileNumber,
      label: "Creator Mobile Number",
      isRequired: true,
      isNumberValid: creatorDetails?.mobileNumber?.length === 10,
      maxLength: "10",
      onChange: (e) => {
        if (e.target.value.length <= 10)
          setCreatorDetails({
            ...creatorDetails,
            mobileNumber: e.target.value,
          });
      },
    },
  ];
  return (
    <div>
      {commonInputFields.map((item) => {
        return (
          <div className="relative first:!mt-0 mt-4" key={item?.id}>
            <label
              htmlFor={item?.id}
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {item?.label}{" "}
              {item?.isRequired && <span className="text-red-500">*</span>}
            </label>
            <div className="relative">
              <input
                type={item?.type}
                id={item?.id}
                placeholder={item?.placeholder}
                value={item?.value}
                onChange={item?.onChange}
                maxLength={item?.maxLength}
                autoComplete="off"
                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 pr-8" // Add padding-right to make space for the icon
              />
              {item?.isNumberValid && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 text-[13px]"
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddCreator;
