import React from "react";
import CustomModal from "../Common/CustomModal";

const UnReportModal = ({
  openModal,
  review,
  reportedReason,
  handleCloseModal,
  handleUnReportReview,
}) => {
  return (
    <div>
      <CustomModal
        show={openModal}
        title="Reported Review"
        handleConfirmationBtnText="UnReport"
        onHide={handleCloseModal}
        handleConfirmation={handleUnReportReview}
        isButtonsRequired={false}
      >
        <div className="text-[15px]">
          <div className="text-gray-500 font-medium">Review</div>
          <div className="mt-1">{review}</div>
          <div className="text-gray-500 mt-3 font-medium">Reported Reason</div>
          <div className="mt-1">{reportedReason}</div>
        </div>
      </CustomModal>
    </div>
  );
};

export default UnReportModal;
