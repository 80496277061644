import React from "react";

const FileUploader = ({ displayIcon, displayText, accept, onChange }) => {
  return (
    <div className="flex items-center justify-center w-full">
      <label
        // for="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-[100px] border-2 border-[#a5b4fc] border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {displayIcon}
          <p className="mb-2 text-sm text-gray-500">
            <span className="font-semibold text-[10px] mt-1">
              {displayText}
            </span>
          </p>
        </div>
        <input
          // id="dropzone-file"
          type="file"
          className="hidden"
          accept={accept}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default FileUploader;
