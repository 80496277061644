import React from "react";
import CustomModal from "../Common/CustomModal";

const RejectSeriesModal = ({
  openRejectSeriesModal,
  setOpenRejectSeriesModal,
  handleRejectSeries,
}) => {
  return (
    <div>
      <CustomModal
        title="Reason For Rejection"
        show={openRejectSeriesModal?.open}
        onHide={() => setOpenRejectSeriesModal({ open: false })}
        handleConfirmationBtnText="Submit"
        handleConfirmation={handleRejectSeries}
      >
        <div>
          <textarea
            placeholder="Enter Reason for Rejection"
            rows={6}
            className="w-full border focus:outline-none p-2"
            onChange={(e) =>
              setOpenRejectSeriesModal({
                ...openRejectSeriesModal,
                reason: e.target.value,
              })
            }
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default RejectSeriesModal;
