import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, Modifier, SelectionState, convertFromHTML, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Bold, Color, Emoji, Italic, Strike, Underline } from "../../assests/data/EditorImg.data";
import draftToHtml from 'draftjs-to-html';
import { emojisLiterals } from "./Emojis";


export default function TextEditor({onChange, isEdit, reset,setReset, fontSize, prevData, data, type, placeholder, editorRef }) {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isTyping, setIsTyping] = useState(false);
    
    useEffect(() => {
      if(isEdit){
        setEditorState(EditorState.moveFocusToEnd(editorState));
      }
    }, []);

    useEffect(() => {
      if(!isTyping && data){
        const editorStateI = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data)
          )
        );
        setEditorState(editorStateI);
      }
      if(reset == true){
        const editorStateI = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(prevData)
          )
        );
        setEditorState(editorStateI);
        setReset(false);
      }
    },[data]);

    const trimContent = () => {
        const editorStateTemp = editorState;
        let currentContent = editorState.getCurrentContent();
        const firstBlock = currentContent.getBlockMap().first();
        const lastBlock = currentContent.getBlockMap().last();
        const firstBlockKey = firstBlock.getKey();
        const lastBlockKey = lastBlock.getKey();
        const firstAndLastBlockIsTheSame = firstBlockKey === lastBlockKey;
        
        const textStart = firstBlock.getText()
        const trimmedTextStart = textStart.trimLeft();
        const lengthOfTrimmedCharsStart = textStart.length - trimmedTextStart.length;
        
        let newSelection = new SelectionState({
          anchorKey: firstBlockKey,
          anchorOffset: 0,
          focusKey: firstBlockKey,
          focusOffset: lengthOfTrimmedCharsStart
        });
        
        currentContent = Modifier.replaceText(
          currentContent,
          newSelection,
          '',
        )
        
        let newEditorState = EditorState.push(
          editorStateTemp,
          currentContent,
        )
    
        let offset = 0;
        
        if (firstAndLastBlockIsTheSame) {
          offset = lengthOfTrimmedCharsStart
        }
    
        const textEnd = lastBlock.getText()
        const trimmedTextEnd = textEnd.trimRight();
        const lengthOfTrimmedCharsEnd = textEnd.length - trimmedTextEnd.length
    
        newSelection = new SelectionState({
          anchorKey: lastBlockKey,
          anchorOffset: trimmedTextEnd.length - offset,
          focusKey: lastBlockKey,
          focusOffset: textEnd.length - offset
        });
        
        currentContent = Modifier.replaceText(
          currentContent,
          newSelection,
          '',
        )
    
        newEditorState = EditorState.push(
          editorStateTemp,
          currentContent,
        )
        // return newEditorState;
        // onEditorStateChange(newEditorState);
        onChange(type, draftToHtml(convertToRaw(newEditorState?.getCurrentContent())));
    }
    const onEditorStateChange = (editor) => {
      setEditorState(editor);
      onChange(type, draftToHtml(convertToRaw(editorState?.getCurrentContent())));
    };

    return (
      <div onClick={() => setIsTyping(true)}>
        <Editor
            ref={editorRef}
            onBlur = {() => setTimeout(trimContent , 0)}
            toolbar={{
                options: ['inline', 'list', 'colorPicker', 'emoji'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                  bold: { icon: Bold, className: undefined },
                  italic: { icon: Italic, className: undefined },
                  underline: { icon: Underline, className: undefined },
                  strikethrough: { icon: Strike, className: undefined },
                },
                
                // fontSize: {
                //   icon: <span>😁</span>,
                //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                //   className: undefined,
                //   component: undefined,
                //   dropdownClassName: undefined,
                // },
                // fontFamily: {
                //   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                //   className: undefined,
                //   component: undefined,
                //   dropdownClassName: undefined,
                // },
                colorPicker: {
                  icon: Color,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: ['rgba(0,0,0, 0)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                    'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                    'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                    'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                    'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                    'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                },
                emoji: {
                  icon: Emoji,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  emojis: emojisLiterals,
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  options: ['unordered', 'ordered'],
                  dropdownClassName: undefined,
                },
            }}
            onPaste={(e)=>{
              var clipboardData = e?.clipboardData || window?.clipboardData;
              window?.pell?.exec('insertText', clipboardData.getData('Text'));
              return true;
            }}
            onkeydown={(event) => event.keyCode!=13}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            placeholder={placeholder}
            onEditorStateChange={onEditorStateChange}
        />
      </div>
    );
}